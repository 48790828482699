import * as React from 'react';
import { Typography, Paper, IconButton, InputBase, Box, Container, Pagination, CircularProgress, Accordion, MenuItem, AccordionSummary, AccordionDetails, Button, Grid, FormControl, InputLabel, OutlinedInput, InputAdornment, Select, FormHelperText } from '@mui/material';
import { connect } from "react-redux";
import InboxIcon from '@mui/icons-material/Inbox';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import SearchIcon from '@mui/icons-material/Search';
import { centered_flex_box, MainInput, MainInputLabel, main_button, sec_button, StyledInput } from '../../app/components/Styles';
import moment from 'moment'
import { EventCard, RequestCard, TicketCard } from "../../app/components"
import { BrowserView, isMobile, MobileView } from 'react-device-detect';
import { getTicketRequests } from "../../app/store/actions/userActions";


export const Requests = ({ requests, getTicketRequests, isLoading }) => {

    const [action, setAction] = React.useState(false)

    const initialState = {
        status: "Any",
        search: "",
        page: 1,
        limit: 10
    }

    React.useEffect(() => {
        getTicketRequests(initialState)
    }, [action])

    const [formData, setFormData] = React.useState(initialState)

    const handleClearFilter = (event) => {
        setFormData(initialState)
        getTicketRequests(initialState)
    }

    const { status, search, page } = formData

    const [drawerOpen, setDrawerOpen] = React.useState(false)

    const handlePageChange = (event, value) => {
        setFormData({ ...formData, page: value })
        getTicketRequests({ ...formData, page: value })
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value })
    }

    const handleSearchFilter = (event) => {
        getTicketRequests(formData)
    }

    if (isLoading) {
        return (
            <Box sx={{ ...centered_flex_box, minHeight: "100vh" }}>
                <CircularProgress sx={{ color: "var(--secColor)" }} />
            </Box>
        )
    }

    return (
        <Container component="main" maxWidth="xl">
            <BrowserView>
                <Grid container sx={{ justifyContent: "space-between" }}>
                    <Grid item>
                        <FormControl sx={{ minWidth: "100%", mt: 1 }}>
                            <MainInputLabel id="type-label" title="Status" />
                            <Select
                                margin="normal"
                                fullWidth
                                autoFocus
                                labelId='type-label'
                                id="status"
                                label="Status"
                                name="status"
                                defaultValue="Any"
                                onChange={handleChange}
                            >
                                <MenuItem value={"Accepted"}>Accepted</MenuItem>
                                <MenuItem value={"Rejected"}>Rejected</MenuItem>
                                <MenuItem value={"Unseen"}>Pending</MenuItem>
                                <MenuItem value={"Purchased"}>Purchased</MenuItem>
                                <MenuItem value={"Any"}>Any</MenuItem>
                            </Select>
                        </FormControl>

                        <Button sx={{ ...main_button, margin: 1 }} onClick={handleSearchFilter}>Apply Filters</Button>
                        <Button sx={{ ...main_button, margin: 1 }} onClick={handleClearFilter}>Clear Filters</Button>
                    </Grid>
                    <Grid item>
                        <FormControl variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password">Search for Event Requests</InputLabel>
                            <OutlinedInput
                                id="search"
                                name="search"
                                autoComplete="search"
                                value={search}
                                onChange={handleChange}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={handleSearchFilter}
                                            edge="end"
                                        >
                                            <SearchIcon sx={{ color: "var(--secPurple)" }} />
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label="Search for Event Requests"
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </BrowserView>
            <MobileView>
                <Box sx={centered_flex_box}>
                    <Paper
                        sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: "80%" }}
                    >
                        <IconButton sx={{ p: '10px' }} aria-label="menu" type="button" onClick={() => setDrawerOpen(!drawerOpen)}>
                            <FilterAltIcon color='white' />
                        </IconButton>
                        <InputBase
                            sx={{ ml: 1, flex: 1 }}
                            placeholder="Search For Event Requests"
                            name="search"
                            id="search"
                            className='mobile-search'
                            value={search}
                            onChange={handleChange}
                        />
                        <IconButton sx={{ p: '10px' }} aria-label="search" onClick={handleSearchFilter}>
                            <SearchIcon color='white' />
                        </IconButton>
                    </Paper>
                </Box>
                {drawerOpen && (
                    <Grid container direction="row" display="flex" justifyContent="space-evenly" alignItems="center">
                        <Grid item width="80%" display="flex-row" alignItems="center">
                            <Accordion expanded={drawerOpen}>
                                <AccordionSummary
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography fontWeight="bold">Filters</Typography>
                                </AccordionSummary>

                                <AccordionDetails>

                                    <FormControl sx={{ minWidth: "100%", mt: 1 }}>
                                        <MainInputLabel id="type-label" title="Status" />
                                        <Select
                                            margin="normal"
                                            fullWidth
                                            autoFocus
                                            labelId='type-label'
                                            id="status"
                                            label="Status"
                                            name="status"
                                            defaultValue="Any"
                                            onChange={handleChange}
                                        >
                                            <MenuItem value={"Accepted"}>Accepted</MenuItem>
                                            <MenuItem value={"Rejected"}>Rejected</MenuItem>
                                            <MenuItem value={"Unseen"}>Pending</MenuItem>
                                            <MenuItem value={"Purchased"}>Purchased</MenuItem>
                                            <MenuItem value={"Any"}>Any</MenuItem>
                                        </Select>
                                    </FormControl>

                                    <Button sx={{ ...sec_button, margin: 1 }} onClick={handleSearchFilter}>Apply Filters</Button>
                                    <Button sx={{ ...sec_button, margin: 1 }} onClick={handleClearFilter}>Clear Filters</Button>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                    </Grid>
                )}
            </MobileView>
            <hr />
            <Box>
                {!requests?.isLoading ? (
                    <Grid container sx={{ ...centered_flex_box, flexDirection: "row" }} spacing={isMobile ? 2 : 3}>
                        {requests?.results?.docs?.map((request) => {
                            return (
                                <Grid item xs={isMobile ? 12 : 6} sx={centered_flex_box}>
                                    <RequestCard request={request} action={action} setAction={setAction} />
                                </Grid>
                            )
                        })}
                        {requests?.results?.docs?.length === 0 && (
                            <Grid item sx={{ ...centered_flex_box, flexDirection: "column", mt: 2 }}>
                                <InboxIcon fontSize="large" />
                                <Typography fontSize={40}>No results</Typography>
                            </Grid>
                        )}
                    </Grid>
                ) : (
                    <Box sx={centered_flex_box}>
                        <CircularProgress sx={{ color: "var(--secColor)" }} />
                    </Box>
                )}
                <Box sx={{ ...centered_flex_box, mx: 1, p: 1 }}>
                    <Pagination count={requests?.results?.pages || 1} page={page} onChange={handlePageChange} />
                </Box>
            </Box>
        </Container>
    );
}

const mapStateToProps = (state) => ({
    requests: state?.records,
    isLoading: state?.records.isLoading
});

const mapDispatchToProps = { getTicketRequests };

export default connect(mapStateToProps, mapDispatchToProps)(Requests);