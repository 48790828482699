import { combineReducers } from "redux";
import authReducer from "./authReducer.js";
import usherReducer from "./usherReducer";
import eventReducer from "./eventReducer";
import waitingReducer from "./waitingReducer";
import getReducer from "./getReducer";

export default combineReducers({
    auth: authReducer,
    usher: usherReducer,
    events: eventReducer,
    wait: waitingReducer,
    records: getReducer,
});