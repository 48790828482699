import * as React from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import InfoIcon from "@mui/icons-material/Info";
import {
  Typography,
  Box,
  Container,
  TextField,
  CssBaseline,
  Button,
  Avatar,
  Select,
  MenuItem,
  InputLabel,
  FormHelperText,
  FormControl,
  CircularProgress,
  Chip,
  Tooltip,
  Card,
} from "@mui/material";
import { connect } from "react-redux";
import {
  centered_flex_box,
  MainInput,
  MainInputLabel,
  MainTextArea,
  main_button,
  reject_button,
  sec_button,
  StyledInput,
} from "../../app/components/Styles";
import { useNavigate, useParams } from "react-router-dom";
import { sendMessage } from "../../app/store/actions/commonActions";
import qrscanimg from "../../app/components/QR-Code.png";
import { isMobile } from "react-device-detect";
import { BiTimer } from "react-icons/bi";
import { GiClick } from "react-icons/gi";
import { TbArrowBigDownLineFilled } from "react-icons/tb";
import ReactGA from "react-ga4";

export const About = () => {
  const navigate = useNavigate();
  React.useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/about", title: "About" });
  }, [])

  return (
    <Box
      sx={{
        marginTop: -5,
        paddingBottom: 8,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          ...centered_flex_box,
          ...(isMobile ? { width: "100%" } : { width: "70%" }),
          mt: 2,
          flexDirection: "column",
          p: 5,
        }}
      >
        <Box
          sx={{ ...centered_flex_box, mt: 2, flexDirection: "column", p: 5 }}
        >
          <Typography
            fontSize={30}
            fontWeight="bold"
            textAlign="center"
            color={"var(--primaryPurple)"}
          >
            OUR VALUES
          </Typography>
          <hr
            style={{
              width: "50%",
              opacity: "100%",
              "border-top": "solid var(--primaryPurple) 3px",
            }}
          />
        </Box>
        <Grid container spacing={isMobile ? 2 : 4}>
          <Grid
            item
            xs={isMobile ? 12 : 4}
            sx={{ ...centered_flex_box, flexDirection: "column" }}
          >
            <BiTimer size={200} />
            <Typography fontSize={30}>Speed & Efficiency</Typography>
          </Grid>
          <Grid
            item
            xs={isMobile ? 12 : 4}
            sx={{ ...centered_flex_box, flexDirection: "column" }}
          >
            <GiClick size={200} />
            <Typography fontSize={30}>Convenience</Typography>
          </Grid>
          <Grid
            item
            xs={isMobile ? 12 : 4}
            sx={{ ...centered_flex_box, flexDirection: "column" }}
          >
            <TbArrowBigDownLineFilled size={200} />
            <Typography fontSize={30}>Waste Reduction</Typography>
          </Grid>
        </Grid>
        <Box
          sx={{ ...centered_flex_box, mt: 2, flexDirection: "column", p: 5 }}
        >
          <Typography
            fontSize={30}
            fontWeight="bold"
            textAlign="center"
            color={"var(--primaryPurple)"}
          >
            ABOUT US
          </Typography>
          <hr
            style={{
              width: "50%",
              opacity: "100%",
              "border-top": "solid var(--primaryPurple) 3px",
            }}
          />
        </Box>
        <Typography sx={{ fontSize: "22px", textAlign: "center" }}>
          TICKit is an innovative online ticketing solution whose aim is to
          revolutionize the way events are managed by leveraging our fast,
          efficient, and easy-to-use barcode technology, in order to deliver a
          seamless experience for event organizers and attendees, all while
          avoiding the unnecessary environmental waste of generic paper tickets.
        </Typography>
        <hr />
        <Typography sx={{ fontSize: "22px", textAlign: "center" }}>
          With barcodes as the core technology, we digitize the entire ticketing
          process, and we eliminate the hassle of manual ticket handling, long
          queues, and potential human errors.
        </Typography>
        <hr />
        <Typography sx={{ fontSize: "22px", textAlign: "center" }}>
          Attendees can easily purchase tickets online, instantly receive them
          via email, and conveniently scan their barcodes at the event entrance
          for swift and hassle-free access.
        </Typography>

        <Box sx={{ ...centered_flex_box, my: 2 }}>
          <Button sx={main_button} onClick={() => navigate("/events")}>
            {" "}
            Purchase Now{" "}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(About);
