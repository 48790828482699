import { WAIT, WAIT_SUCCESS, WAIT_FAIL, FETCH_RECORDS, FETCH_RECORDS_SUCCESS, FETCH_RECORDS_FAIL, CART, CART_SUCCESS, CART_FAIL } from "./types";
import { delRequest, getRequest, postRequest, putRequest } from "../../../core/network";
import endpoints from "../../../constants/endPoints.json";
import { notification } from "antd";
import download from "downloadjs";

export const getTickets = (data) => (dispatch, useState) => {
    dispatch({ type: FETCH_RECORDS });
    const token = useState()?.auth?.token

    getRequest(data, undefined, token, endpoints.user.tickets.get)
        .then((response) => {
            const { data } = response
            return dispatch({
                type: FETCH_RECORDS_SUCCESS,
                payload: data
            });
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message })
            console.log(err);
            return dispatch({
                type: FETCH_RECORDS_FAIL,
            });
        });
};

export const downloadTicket = (data) => (dispatch, useState) => {
    dispatch({ type: WAIT });
    const token = useState()?.auth?.token

    getRequest({ ...data, responseType: "arraybuffer" }, undefined, token, endpoints.user.tickets.download)
        .then((response) => {
            window.ReactNativeWebView?.postMessage(JSON.stringify({endpoint: endpoints.user.tickets.download, data, token, filename: response.headers["filename"]}), '*', [data, token, endpoints.user.tickets.download])
            download(response.data, response.headers["filename"], "application/pdf")
            return dispatch({
                type: WAIT_SUCCESS
            });
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message })
            console.log(err);
            return dispatch({
                type: WAIT_FAIL,
            });
        });
};

export const submitEventRequest = (data, navigate) => (dispatch, useState) => {
    dispatch({ type: WAIT });
    const token = useState()?.auth?.token

    postRequest(data, undefined, undefined, token, endpoints.user.eventReq)
        .then((response) => {
            notification.success({ message: "Event Requested Successfully, expect a call from us soon" })
            navigate && navigate(-1)
            return dispatch({
                type: WAIT_SUCCESS
            });
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message })
            console.log(err);
            return dispatch({
                type: WAIT_FAIL,
            });
        });
};

export const getEventRequests = (data) => (dispatch, useState) => {
    dispatch({ type: FETCH_RECORDS });
    const token = useState()?.auth?.token

    getRequest(data, undefined, token, endpoints.user.eventReq)
        .then((response) => {
            const { data } = response
            return dispatch({
                type: FETCH_RECORDS_SUCCESS,
                payload: data
            });
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message })
            console.log(err);
            return dispatch({
                type: FETCH_RECORDS_FAIL,
            });
        });
};

export const requestTicket = (data, navigate) => (dispatch, useState) => {
    dispatch({ type: WAIT });
    const token = useState()?.auth?.token

    postRequest(data, undefined, undefined, token, endpoints.user.requests.root)
        .then((response) => {
            notification.success({ message: "Ticket Requested Successfully", style: { marginTop: "10vh" } })
            navigate && navigate("/User/requests")
            return dispatch({
                type: WAIT_SUCCESS
            });
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: { marginTop: "10vh" } })
            console.log(err);
            return dispatch({
                type: WAIT_FAIL,
            });
        });
};

export const payTicketRequest = (data) => (dispatch, useState) => {
    dispatch({ type: WAIT });
    const token = useState()?.auth?.token

    putRequest(data, undefined, undefined, token, endpoints.user.requests.pay)
        .then((response) => {
            notification.success({ message: "Ticket Requested Updated Successfully", style: { marginTop: "10vh" } })
            return dispatch({
                type: WAIT_SUCCESS
            });
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: { marginTop: "10vh" } })
            console.log(err);
            return dispatch({
                type: WAIT_FAIL,
            });
        });
};

export const getTicketRequests = (data) => (dispatch, useState) => {
    dispatch({ type: FETCH_RECORDS });
    const token = useState()?.auth?.token

    getRequest(data, undefined, token, endpoints.user.requests.root)
        .then((response) => {
            const { data } = response
            return dispatch({
                type: FETCH_RECORDS_SUCCESS,
                payload: data
            });
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: { marginTop: "10vh" } })
            console.log(err);
            return dispatch({
                type: FETCH_RECORDS_FAIL,
            });
        });
};

export const getCart = () => (dispatch, useState) => {
    dispatch({ type: CART });
    const token = useState()?.auth?.token

    getRequest(undefined, undefined, token, endpoints.user.cart.get)
        .then((response) => {
            const { data } = response
            return dispatch({
                type: CART_SUCCESS,
                payload: data
            });
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: { marginTop: "10vh" } })
            console.log(err);
            return dispatch({
                type: CART_FAIL,
            });
        });
};

export const addToCart = (data, navigate) => (dispatch, useState) => {
    dispatch({ type: CART });
    const token = useState()?.auth?.token

    postRequest(data, undefined, undefined, token, endpoints.user.cart.add)
        .then((response) => {
            const { data } = response
            notification.success({ message: "Items added to cart", style: { marginTop: "10vh" } })
            navigate && navigate("/User/cart")
            return dispatch({
                type: CART_SUCCESS,
                payload: data
            });
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: { marginTop: "10vh" } })
            console.log(err);
            return dispatch({
                type: CART_FAIL,
            });
        });
};

export const removeFromCart = (data) => (dispatch, useState) => {
    dispatch({ type: CART });
    const token = useState()?.auth?.token

    putRequest(data, undefined, undefined, token, endpoints.user.cart.remove)
        .then((response) => {
            const { data } = response
            notification.success({ message: "Item removed from cart", style: { marginTop: "10vh" } })
            return dispatch({
                type: CART_SUCCESS,
                payload: data
            });
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: { marginTop: "10vh" } })
            console.log(err);
            return dispatch({
                type: CART_FAIL,
            });
        });
};

export const clearCart = () => (dispatch, useState) => {
    dispatch({ type: CART });
    const token = useState()?.auth?.token

    getRequest(undefined, undefined, token, endpoints.user.cart.clear)
        .then((response) => {
            const { data } = response
            notification.success({ message: "Cart cleared", style: { marginTop: "10vh" } })
            return dispatch({
                type: CART_SUCCESS,
                payload: data
            });
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: { marginTop: "10vh" } })
            console.log(err);
            return dispatch({
                type: CART_FAIL,
            });
        });
};

export const checkout = (data) => (dispatch, useState) => {
    dispatch({ type: WAIT });
    const token = useState()?.auth?.token

    postRequest(data, undefined, undefined, token, endpoints.user.checkout)
        .then((response) => {
            const { data } = response
            const { redirect } = data;
            dispatch({
                type: WAIT_SUCCESS
            });
            window.location.href = redirect
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: { marginTop: "10vh" } })
            console.log(err);
            return dispatch({
                type: WAIT_FAIL,
            });
        });
};

export const checkoutTransfer = (data) => (dispatch, useState) => {
    dispatch({ type: WAIT });
    const token = useState()?.auth?.token

    postRequest(data, undefined, undefined, token, endpoints.user.checkoutTransfer)
        .then((response) => {
            const { data } = response
            notification.success({ message: data?.message, style: { marginTop: "10vh" } })
            dispatch({
                type: WAIT_SUCCESS
            });
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: { marginTop: "10vh" } })
            console.log(err);
            return dispatch({
                type: WAIT_FAIL,
            });
        });
};

export const checkTransaction = (data, navigate) => (dispatch, useState) => {
    dispatch({ type: FETCH_RECORDS });
    const token = useState()?.auth?.token

    postRequest(data, undefined, undefined, token, endpoints.user.checkTransaction)
        .then((response) => {
            const { data } = response;
            dispatch({
                type: FETCH_RECORDS_SUCCESS,
                payload: data,
            });
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: { marginTop: "15vh" } })
            console.log(err);
            return dispatch({
                type: FETCH_RECORDS_FAIL,
            });
        });
};