import * as React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import { Typography, Box, Container, TextField, CssBaseline, Button, Avatar, Select, MenuItem, FormHelperText, InputLabel, CircularProgress, FormControl, InputAdornment, IconButton, Card, CardContent, CardActionArea } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { connect } from "react-redux";
import { getWallet } from '../../app/store/actions/authActions';
import { Navigate, NavLink, Route, Routes } from "react-router-dom";
import { centered_flex_box, SecInput, MainInputLabel, MainPassword, main_button, StyledInput } from '../../app/components/Styles';

export const EditProfile = ({ getWallet, wallet, token, isLoading }) => {

    React.useEffect(() => {
        getWallet();
    }, [])

    if (isLoading) {
        return (
            <Box sx={{ ...centered_flex_box }}>
                <CircularProgress sx={{ color: "var(--mainWhite)" }} />
            </Box>
        )
    }

    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    minHeight: "100%"
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'var(--secColor)' }}>
                    <AccountBalanceWalletIcon />
                </Avatar>
                <Typography component="h1" variant="h5" fontWeight="bold">
                    WALLET
                </Typography>
                <Box sx={{ mt: 2 }}>
                    <Card sx={{ backgroundColor: "var(--appBg) !important", p:3, minWidth:"80%" }}>
                        <CardContent>
                            <Typography fontSize={20} color="var(--secColor)" fontWeight={"bold"}>
                                Account Balance
                            </Typography>
                            <Typography fontSize={20} color="var(--secColor)">
                                {wallet?.balance} EGP
                            </Typography>
                        </CardContent>
                        <CardActionArea sx={{...centered_flex_box}}>
                            <Button disabled size='small' sx={{...main_button, mr:1}}>
                                Deposit
                            </Button>
                            <Button disabled size='small' sx={{...main_button, ml:1}}>
                                Withdraw
                            </Button>
                        </CardActionArea>
                    </Card>
                </Box>
            </Box>
        </Container>
    );
}

const mapStateToProps = (state) => ({
    wallet: state?.auth?.wallet,
    isLoading: state?.auth?.wallet?.isLoading,
    token: state?.auth?.token
});

const mapDispatchToProps = { getWallet };

export default connect(mapStateToProps, mapDispatchToProps)(EditProfile);