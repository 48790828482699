import * as React from 'react';
import { Typography, Paper, IconButton, InputBase, Box, Container, Pagination, CircularProgress, Accordion, MenuItem, AccordionSummary, AccordionDetails, Button, Grid, FormControl, InputLabel, OutlinedInput, InputAdornment, Select, FormHelperText } from '@mui/material';
import { connect } from "react-redux";
import InboxIcon from '@mui/icons-material/Inbox';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import SearchIcon from '@mui/icons-material/Search';
import { centered_flex_box, MainInput, MainInputLabel, main_button, sec_button, StyledInput } from '../../app/components/Styles';
import moment from 'moment'
import { EventCard, TicketCard } from "../../app/components"
import { BrowserView, isMobile, MobileView } from 'react-device-detect';
import { getTickets } from "../../app/store/actions/userActions";


export const Tickets = ({ tickets, getTickets }) => {

    const initialState = {
        validated: "any",
        fulfilled: "any",
        search: "",
        page: 1,
        limit: 10
    }

    React.useEffect(() => {
        getTickets(initialState)
    }, [])

    const [formData, setFormData] = React.useState(initialState)

    const handleClearFilter = (event) => {
        setFormData(initialState)
        getTickets(initialState)
    }

    const { minDate, maxDate, search, page } = formData

    const [drawerOpen, setDrawerOpen] = React.useState(false)

    const handlePageChange = (event, value) => {
        setFormData({ ...formData, page: value })
        getTickets({ ...formData, page: value })
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value })
    }

    const handleSearchFilter = (event) => {
        getTickets(formData)
    }

    return (
        <Container component="main" maxWidth="xl">
            <BrowserView>
                <Grid container sx={{ justifyContent: "space-between" }}>
                    <Grid item>
                        <FormControl sx={{ minWidth: "100%", mt: 1 }}>
                            <MainInputLabel id="type-label" title="Validated" />
                            <Select
                                margin="normal"
                                autoFocus
                                labelId='type-label'
                                id="validated"
                                label="Validated"
                                name="validated"
                                defaultValue="any"
                                onChange={handleChange}
                            >
                                <MenuItem value={true}>Validated</MenuItem>
                                <MenuItem value={false}>Unvalidated</MenuItem>
                                <MenuItem value={"any"}>Any</MenuItem>
                            </Select>
                        </FormControl>

                        <FormControl sx={{ minWidth: "100%", mt: 1 }}>
                            <MainInputLabel id="type-label" title="Payment Complete" />
                            <Select
                                margin="normal"
                                autoFocus
                                labelId='type-label'
                                id="fulfilled"
                                label="Payment Complete"
                                name="fulfilled"
                                defaultValue="any"
                                onChange={handleChange}
                            >
                                <MenuItem value={true}>Yes</MenuItem>
                                <MenuItem value={false}>No</MenuItem>
                                <MenuItem value={"any"}>Any</MenuItem>
                            </Select>
                        </FormControl>

                        <Button sx={{ ...main_button, margin: 1 }} onClick={handleSearchFilter}>Apply Filters</Button>
                        <Button sx={{ ...main_button, margin: 1 }} onClick={handleClearFilter}>Clear Filters</Button>
                    </Grid>
                    <Grid item>
                        <FormControl variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password">Search for event ticket</InputLabel>
                            <OutlinedInput
                                id="search"
                                name="search"
                                autoComplete="search"
                                value={search}
                                onChange={handleChange}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={handleSearchFilter}
                                            edge="end"
                                        >
                                            <SearchIcon sx={{color: "var(--secPurple)"}}/>
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label="Search for event ticket"
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </BrowserView>
            <MobileView>
                <Box sx={centered_flex_box}>
                    <Paper
                        sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: "80%" }}
                    >
                        <IconButton sx={{ p: '10px' }} aria-label="menu" type="button" onClick={() => setDrawerOpen(!drawerOpen)}>
                            <FilterAltIcon color='white' />
                        </IconButton>
                        <InputBase
                            sx={{ ml: 1, flex: 1 }}
                            placeholder="Search For Events"
                            name="search"
                            id="search"
                            className='mobile-search'
                            value={search}
                            onChange={handleChange}
                        />
                        <IconButton sx={{ p: '10px' }} aria-label="search" onClick={handleSearchFilter}>
                            <SearchIcon color='white'/>
                        </IconButton>
                    </Paper>
                </Box>
                {drawerOpen && (
                    <Grid container direction="row" display="flex" justifyContent="space-evenly" alignItems="center">
                        <Grid item width="80%" display="flex-row" alignItems="center">
                            <Accordion expanded={drawerOpen}>
                                <AccordionSummary
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography fontWeight="bold">Filters</Typography>
                                </AccordionSummary>

                                <AccordionDetails>

                                    <FormControl sx={{ minWidth: "100%", mt: 1 }}>
                                        <MainInputLabel id="type-label" title="Validated" />
                                        <Select
                                            margin="normal"
                                            fullWidth
                                            autoFocus
                                            labelId='type-label'
                                            id="validated"
                                            label="Validated"
                                            name="validated"
                                            defaultValue="any"
                                            onChange={handleChange}
                                        >
                                            <MenuItem value={true}>Validated</MenuItem>
                                            <MenuItem value={false}>Unvalidated</MenuItem>
                                            <MenuItem value={"any"}>Any</MenuItem>
                                        </Select>
                                    </FormControl>

                                    <FormControl sx={{ minWidth: "100%", mt: 1 }}>
                                        <MainInputLabel id="type-label" title="Payment Complete" />
                                        <Select
                                            margin="normal"
                                            fullWidth
                                            autoFocus
                                            labelId='type-label'
                                            id="fulfilled"
                                            label="fulfilled"
                                            name="fulfilled"
                                            defaultValue="any"
                                            onChange={handleChange}
                                        >
                                            <MenuItem value={true}>Yes</MenuItem>
                                            <MenuItem value={false}>No</MenuItem>
                                            <MenuItem value={"any"}>Any</MenuItem>
                                        </Select>
                                    </FormControl>

                                    <Button sx={{ ...sec_button, margin: 1 }} onClick={handleSearchFilter}>Apply Filters</Button>
                                    <Button sx={{ ...sec_button, margin: 1 }} onClick={handleClearFilter}>Clear Filters</Button>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                    </Grid>
                )}
            </MobileView>
            <hr />
            <Box>
                {!tickets?.isLoading ? (
                    <Grid container sx={{ ...centered_flex_box, flexDirection: "row" }} spacing={isMobile ? 2 : 3}>
                        {tickets?.results?.docs?.map((ticket) => {
                            return (
                                <Grid item xs={isMobile ? 12 : 6} sx={centered_flex_box}>
                                    <TicketCard ticket={ticket} />
                                </Grid>
                            )
                        })}
                        {tickets?.results?.docs?.length === 0 && (
                            <Grid item sx={{ ...centered_flex_box, flexDirection: "column", mt: 2 }}>
                                <InboxIcon fontSize="large" />
                                <Typography fontSize={40}>No results</Typography>
                            </Grid>
                        )}
                    </Grid>
                ) : (
                    <Box sx={centered_flex_box}>
                        <CircularProgress sx={{ color: "var(--secColor)" }} />
                    </Box>
                )}
                <Box sx={{ ...centered_flex_box, mx: 1, p: 1 }}>
                    <Pagination count={tickets?.results?.pages || 1} page={page} onChange={handlePageChange} />
                </Box>
            </Box>
        </Container>
    );
}

const mapStateToProps = (state) => ({
    tickets: state?.records
});

const mapDispatchToProps = { getTickets };

export default connect(mapStateToProps, mapDispatchToProps)(Tickets);