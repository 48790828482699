import * as React from 'react';
import { Typography, Box, Skeleton, Rating, Button, Chip, Grid, Avatar, Paper, IconButton } from '@mui/material';
import { connect } from "react-redux";
import VisibilityIcon from '@mui/icons-material/Visibility';
import QuizIcon from '@mui/icons-material/Quiz';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import { useNavigate } from 'react-router-dom';
import { Card } from '@mui/material';
import { card_style, centered_flex_box, main_button } from './Styles';
import moment from "moment"
import { isMobile } from 'react-device-detect';
import DownloadIcon from '@mui/icons-material/Download';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import QRCode from "react-qr-code";
import { downloadTicket } from '../store/actions/userActions';

function TicketCard({ ticket, downloadTicket }) {

    const navigate = useNavigate();
    const [showQr, setShowQr] = React.useState(false);

    return (
        <Box className={`ticket-style ${showQr ? "ticket-bottom-style" : ""}`} sx={{ minWidth: isMobile ? "100%" : "80%", maxWidth: isMobile ? "100%" : "80%" }}>
            <Grid container>
                <Grid item xs={9}>
                    <Typography sx={{ fontSize: 26, fontWeight: "bold" }}>
                        {ticket.event.title}
                    </Typography>
                </Grid>
                <Grid item direction="row" alignItems="center" display="flex" justifyContent="flex-end" xs={3}>
                    <Chip sx={{ color: "var(--mainWhite)", borderColor: "var(--mainWhite)" }} label={ticket.fulfilled ? ( ticket.validated ? "Entered" : "Can Enter" ):"Can't Enter"} variant="outlined" />
                </Grid>
            </Grid>
            <Box mt={2}>
                <Grid container>
                    <Grid item direction="column" justifyContent="center" display="flex" xs={6}>
                        <Typography sx={{ fontSize: 15, fontWeight: "bold" }}>
                            Ticket Holder: {ticket.userInfo?.name}
                        </Typography>
                        <Typography sx={{ fontSize: 15, fontWeight: "bold" }}>
                            Category: {ticket.category}
                        </Typography>
                        {ticket.fulfilled && <Typography sx={{ fontSize: 15 }}>
                            Ticket Nr: {ticket._id}
                        </Typography>}
                    </Grid>
                    {ticket.fulfilled && <Grid item direction="row" alignItems="center" display="flex" justifyContent="flex-end" xs={6}>
                        <IconButton color='white' onClick={() => setShowQr(!showQr)}><QrCode2Icon /></IconButton>
                        <IconButton color='white' onClick={() => downloadTicket({ ticketId: ticket._id })}><DownloadIcon /></IconButton>
                    </Grid>}
                </Grid>
            </Box>
            {showQr && (
                <Box mt={1}>
                    <hr style={{ "border-top": "dashed 5px" }} />
                    <Box sx={centered_flex_box}>
                        <QRCode
                            style={{ height: "auto", maxWidth: "70%", width: "70%" }}
                            fgColor="var(--mainWhite)"
                            bgColor="var(--primaryPurple)"
                            value={ticket._id}
                        />
                    </Box>
                </Box>
            )}
        </Box>
    )
}

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = { downloadTicket };

export default connect(mapStateToProps, mapDispatchToProps)(TicketCard);