import { EVENT, EVENT_SUCCESS, EVENT_FAIL, WAIT, WAIT_SUCCESS, WAIT_FAIL } from "./types";
import { delRequest, getRequest, postRequest, putRequest } from "../../../core/network";
import endpoints from "../../../constants/endPoints.json";
import { notification } from "antd";

export const createEvent = (data, navigate) => (dispatch, useState) => {
    dispatch({ type: EVENT });
    const token = useState().auth?.token
    const role = token.split(" ")[0]
    var end;

    switch (role) {
        case "Admin": end = endpoints.admin.createEvent; break;
        case "Super": end = endpoints.super.createEvent; break;
        default: break;
    }

    postRequest(data, undefined, undefined, token, end)
        .then((response) => {
            const { data } = response;
            notification.success({ message: "Event Created Successfully", style: {marginTop: "10vh"} })
            navigate && navigate(-1);
            return dispatch({
                type: EVENT_SUCCESS,
                payload: data,
            });
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: {marginTop: "10vh"} })
            console.log(err);
            return dispatch({
                type: EVENT_FAIL,
            });
        });
};

export const editEvent = (data, navigate) => (dispatch, useState) => {
    dispatch({ type: WAIT });
    const token = useState().auth?.token
    const role = token.split(" ")[0]
    var end;

    switch (role) {
        case "Admin": end = endpoints.admin.editEvent; break;
        case "Super": end = endpoints.super.editEvent; break;
        default: break;
    }

    putRequest(data, undefined, undefined, token, end)
        .then((response) => {
            const { data } = response;
            notification.success({ message: "Event Edited Successfully", style: {marginTop: "10vh"} })
            navigate && navigate(-1);
            return dispatch({
                type: WAIT_SUCCESS,
            });
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: {marginTop: "10vh"} })
            console.log(err);
            return dispatch({
                type: WAIT_FAIL,
            });
        });
};

export const getLiveEvent = (data) => (dispatch, useState) => {
    dispatch({ type: EVENT });
    const token = useState()?.auth?.token
    var end;

    switch (token.split(" ")[0]) {
        case "Admin": end = endpoints.admin.liveEvent; break;
        case "Super": end = endpoints.super.liveEvent; break;
        default: break;
    }

    getRequest(data, undefined, token, end)
        .then((response) => {
            const {data} = response
            return dispatch({
                type: EVENT_SUCCESS,
                payload: data
            });
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: {marginTop: "10vh"} })
            console.log(err);
            return dispatch({
                type: EVENT_FAIL,
            });
        });
};

export const deleteEvent = (data, navigate) => (dispatch, useState) => {
    dispatch({ type: WAIT });
    const token = useState().auth?.token

    delRequest(data, undefined, token, endpoints.super.deleteEvent)
        .then((response) => {
            const { data } = response;
            notification.success({ message: "Event Deleted Successfully", style: {marginTop: "10vh"} })
            navigate && navigate(-1);
            return dispatch({
                type: WAIT_SUCCESS,
            });
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: {marginTop: "10vh"} })
            console.log(err);
            return dispatch({
                type: WAIT_FAIL,
            });
        });
};

export const searchEvents = (data) => (dispatch, useState) => {
    dispatch({ type: EVENT });
    const token = useState().auth?.token
    const role = token?.split(" ")[0]
    var end;

    switch (role) {
        case "Admin": end = endpoints.admin.searchEvent; break;
        case "Super": end = endpoints.super.searchEvent; break;
        case "User": end = endpoints.user.searchEvent; break;
        case "Partner": end = endpoints.partner.searchEvent; break;
        default: end = endpoints.guest.searchEvent; break;;
    }

    getRequest(data, undefined, token, end)
        .then((response) => {
            const { data } = response;
            return dispatch({
                type: EVENT_SUCCESS,
                payload: data,
            });
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: {marginTop: "10vh"} })
            console.log(err);
            return dispatch({
                type: EVENT_FAIL,
            });
        });
};

export const getEvent = (data) => (dispatch, useState) => {
    dispatch({ type: EVENT });

    getRequest(data, undefined, undefined, endpoints.common.getEvent)
        .then((response) => {
            const { data } = response;
            return dispatch({
                type: EVENT_SUCCESS,
                payload: data,
            });
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: {marginTop: "10vh"} })
            console.log(err);
            return dispatch({
                type: EVENT_FAIL,
            });
        });
};

export const getFeaturedEvents = (data) => (dispatch, useState) => {
    dispatch({ type: EVENT });

    getRequest(undefined, undefined, undefined, endpoints.common.featuredEvent)
        .then((response) => {
            const { data } = response;
            return dispatch({
                type: EVENT_SUCCESS,
                payload: data,
            });
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: {marginTop: "10vh"} })
            console.log(err);
            return dispatch({
                type: EVENT_FAIL,
            });
        });
};