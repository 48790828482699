import * as React from 'react';
import { Typography, Box, Container, TextField, CssBaseline, Button, Avatar, Select, MenuItem, InputLabel, FormHelperText, FormControl, CircularProgress, Chip } from '@mui/material';
import { connect } from "react-redux";
import { useNavigate, useParams } from 'react-router-dom';
import ConstructionIcon from '@mui/icons-material/Construction';
import { centered_flex_box } from '../../app/components/Styles';

export const ComingSoon = ({ }) => {

    const navigate = useNavigate()
    const eventId = useParams().id

    return (
        <Container component="main">
            <Box sx={{...centered_flex_box, flexDirection: "column"}}>
                <ConstructionIcon sx={{fontSize: "300px"}} />
                <Typography fontSize={40}>Page Under Construction</Typography>
            </Box>
        </Container>
    );
}

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ComingSoon);