import React, { useState } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import { notification } from "antd";
import { Typography, Box, Container, TextField, CssBaseline, Button, Avatar, Select, MenuItem, FormHelperText, InputLabel, CircularProgress, FormControl, Card, IconButton } from '@mui/material';
import { connect } from "react-redux";
import { verifyTicket, validateTicket } from '../../app/store/actions/usherActions';
import QrReader from "modern-react-qr-reader"
import FlipCameraAndroidIcon from '@mui/icons-material/FlipCameraAndroid';
import ringer from "../../app/components/beep.mp3";
import { card_style, centered_flex_box, MainInput, MainInputLabel, main_button, StyledInput } from '../../app/components/Styles';

export const Scanner = ({ auth, ticket, isLoading, verifyTicket, validateTicket, verify }) => {

    const Beep = new Audio(ringer);
    const ticketHolder = ticket?.user || ticket?.userInfo;
    const ticketInfo = [];
    const [camera, setCamera] = useState("environment")

    function handleCameraFlip() {
        if(camera === "environment") setCamera("user")
        else setCamera("environment")
    }

    function handleScan(data) {
        if (data) {
            Beep.play()
            validateTicket({ ticketId: data })
        }
    }

    function ticketData() {
        Object.keys(ticket?.userInfo).forEach(function (key) {
            if (key !== "name") ticketInfo.push({ title: key, value: ticket?.userInfo[key] })
        })
    }

    if (isLoading) {
        return (
            <Box sx={{ ...centered_flex_box, minHeight: "100vh" }}>
                <CircularProgress sx={{ color: "var(--secColor)" }} />
            </Box>
        )
    }

    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    paddingTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                {!verify && <><QrReader
                    onScan={handleScan}
                    style={{
                        height: 240,
                        width: "100%",
                    }}
                    facingMode={camera}
                />
                {/* <IconButton onClick={() => handleCameraFlip}><FlipCameraAndroidIcon/></IconButton> */}
                </>}
                {verify && (<Card sx={card_style}>
                    <Typography>Ticket Holder Name: {ticketHolder.name}</Typography>
                    {ticketData()}
                    {ticketInfo?.map((elem) => {
                        return <Typography>{elem.title}: {elem.value}</Typography>
                    })}
                    <Button sx={main_button} onClick={() => verifyTicket({ ticketId: ticket._id })}>Information is correct</Button>
                </Card>)}
            </Box>
        </Container>
    );
}

const mapStateToProps = (state) => ({
    auth: state?.auth,
    isLoading: state?.usher?.isLoading,
    verify: state?.usher?.verify,
    ticket: state?.usher?.ticket
});

const mapDispatchToProps = { verifyTicket, validateTicket };

export default connect(mapStateToProps, mapDispatchToProps)(Scanner);