import * as React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import { Typography, Box, Container, TextField, CssBaseline, Button, Avatar, Select, MenuItem, InputLabel, FormHelperText, FormControl, CircularProgress, Chip, Tooltip, Card, Accordion, AccordionSummary, AccordionDetails, CardHeader, IconButton, CardContent } from '@mui/material';
import { connect } from "react-redux";
import { centered_flex_box, MainInput, MainInputLabel, MainTextArea, main_button, reject_button, sec_button, StyledInput, left_flex_box } from '../../app/components/Styles';
import { useNavigate, useParams } from 'react-router-dom';
import FAQCard from '../../app/components/FAQCard';

export const FAQs = () => {

    const navigate = useNavigate();
    const [expanded, setExpanded] = React.useState({});

    const handleChange = (panel) => {
        const isExpanded = expanded[panel]
        setExpanded({ ...expanded, [panel]: !isExpanded });
    };

    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    marginTop: -5,
                    paddingBottom: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }
                }
            >
                <Box sx={{ ...centered_flex_box, mt: 2, flexDirection: "column", p: 5 }}>
                    <Typography fontSize={30} fontWeight="bold" textAlign="center" color={"var(--primaryPurple)"}>FAQs</Typography>
                    <hr style={{ width: "50%", opacity: "100%", "border-top": "solid var(--primaryPurple) 3px" }} />
                </Box>
                <Box sx={{ width: "90vw", my: 1, ...centered_flex_box, flexDirection: "column" }}>
                    <FAQCard question={"How does the barcode ticketing system work?"} answer={"Our barcode ticketing system operates by generating unique barcodes for each ticket purchased online via our website or mobile app. This barcode is sent to the attendee via email and is scanned at the event entrance using a barcode scanner or a mobile device equipped with a scanning app, granting quick and easy access to attendees."} />
                    <FAQCard question={"How do I purchase tickets using this system?"} answer={"Purchasing tickets is a simple process. Visit our website or mobile app, browse the available events, select the desired event, and follow the prompts to complete your ticket purchase. Once the transaction is complete, your barcode ticket will be sent to you via email."} buttonData={{navigate: "/events", text: "Browse Events"}} />
                    <FAQCard question={"Are barcode tickets secure?"} answer={"Barcode tickets provide enhanced security compared to traditional paper tickets. Each barcode is unique and easily verifiable, reducing the risk of counterfeit tickets. This ensures a safe and secure event experience for all attendees."} />
                    <FAQCard question={"How can I use my smartphone to access barcode tickets?"} answer={"Our ticketing system primarily relies on the digitization of tickets, and therefore on purchasing the ticket online, an email is sent to the attendee, who is then able to access and display the barcode ticket by simply presenting the ticket barcode included in the email. In addition, you can access all your tickets easily on the website."} buttonData={{navigate: "/User/tickets", text: "View Tickets"}} />
                    <FAQCard question={"What if I don't have a smartphone or prefer a physical ticket?"} answer={"While our ticketing system encourages paper-free tickets, in the case of the attendee requiring a physical ticket, the attendee is expected to print their barcode ticket at home and present it at the event entrance for scanning."} />
                    <FAQCard question={"I accidentally deleted/lost my barcode ticket. What do I do?"} answer={"Don't worry! In case you accidentally delete or lose your barcode ticket email, you can reach out to our customer support team with your purchase details. They will assist you in reissuing the ticket or providing alternative solutions to ensure your entry to the event."} buttonData={{navigate: "/contact", text: "Contact Support"}} />
                    <FAQCard question={"Do I need an internet connection to access my barcode ticket?"} answer={"While an internet connection is typically required to initially download and access your barcode ticket, some events may offer offline ticket access options. Please check the event details or contact our customer support for further information."} buttonData={{navigate: "/contact", text: "Contact Support"}} />
                </Box>
            </Box>
        </Container >
    );
}


const mapStateToProps = (state) => ({
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(FAQs);