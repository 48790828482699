import * as React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Typography, Box, Container, TextField, CssBaseline, Button, Avatar, Select, MenuItem, FormHelperText, InputLabel, CircularProgress, FormControl, InputAdornment, IconButton } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { connect } from "react-redux";
import { resetPassword } from '../../app/store/actions/authActions';
import { Navigate, NavLink, Route, Routes, useParams } from "react-router-dom";
import { centered_flex_box, MainInput, MainInputLabel, MainPassword, main_button, StyledInput, SecInput } from '../../app/components/Styles';

export const ResetPassword = ({ resetPassword, user, isLoading }) => {

    const {token} = useParams()
    const [error, setError] = React.useState(null)

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        if (data.get("password") !== data.get("password-confirm")) {
            setError("Passwords Do Not Match")
        } else {
            var details = {
                password: data.get('password'),
                token
            }
            resetPassword(details)
        }
    };

    if (isLoading) {
        return (
            <Box sx={{ ...centered_flex_box }}>
                <CircularProgress sx={{ color: "var(--secColor)" }} />
            </Box>
        )
    }

    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    minHeight: "100%"
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'var(--secColor)' }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Reset Password
                </Typography>
                <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
                <MainPassword
                        margin="normal"
                        sx={{ my: 1 }}
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                    />
                    <MainPassword
                        margin="normal"
                        sx={{ my: 1 }}
                        required
                        fullWidth
                        name="password-confirm"
                        label="Confirm Password"
                        type="password"
                        id="password-confirm"
                        error={error}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Change Password
                    </Button>
                </Box>
            </Box>
        </Container>
    );
}

const mapStateToProps = (state) => ({
    user: state?.auth?.user,
    isLoading: state?.wait?.isLoading
});

const mapDispatchToProps = { resetPassword };

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);