import { WAIT, WAIT_SUCCESS, WAIT_FAIL, FETCH_RECORDS, FETCH_RECORDS_SUCCESS, FETCH_RECORDS_FAIL, EVENT, EVENT_SUCCESS, EVENT_FAIL } from "./types";
import { delRequest, getRequest, postRequest, putRequest } from "../../../core/network";
import endpoints from "../../../constants/endPoints.json";
import { notification } from "antd";
import download from "downloadjs";

export const generateTickets = (data, close) => (dispatch, useState) => {
    dispatch({ type: WAIT });
    const token = useState()?.auth?.token
    var end;

    switch (token.split(" ")[0]) {
        case "Admin": end = endpoints.admin.generateTickets; break;
        case "Super": end = endpoints.super.generateTickets; break;
        default: break;
    }

    postRequest(data, { responseType: "arraybuffer" }, undefined, token, end)
        .then((response) => {
            download(response.data, response.headers["filename"], "application/zip")
            close && close()
            return dispatch({
                type: WAIT_SUCCESS
            });
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message || "Something Went Wrong :(", style: {marginTop: "10vh"} })
            console.log(err);
            return dispatch({
                type: WAIT_FAIL,
            });
        });
};

export const resendTicket = (data, close) => (dispatch, useState) => {
    dispatch({ type: WAIT });
    const token = useState()?.auth?.token
    var end;

    getRequest(data, undefined, token, endpoints.super.resendTicket)
        .then((response) => {
            notification.success({ message: response?.data?.message, style: {marginTop: "10vh"} })
            return dispatch({
                type: WAIT_SUCCESS
            });
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message || "Something Went Wrong :(", style: {marginTop: "10vh"} })
            console.log(err);
            return dispatch({
                type: WAIT_FAIL,
            });
        });
};

export const deleteTicket = (data, close) => (dispatch, useState) => {
    dispatch({ type: WAIT });
    const token = useState()?.auth?.token

    delRequest(data, undefined, token, endpoints.super.deleteTicket)
        .then((response) => {
            notification.success({ message: response?.data?.message, style: {marginTop: "10vh"} })
            return dispatch({
                type: WAIT_SUCCESS
            });
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message || "Something Went Wrong :(", style: {marginTop: "10vh"} })
            console.log(err);
            return dispatch({
                type: WAIT_FAIL,
            });
        });
};

export const generateTicketsQRs = (data, close) => (dispatch, useState) => {
    dispatch({ type: WAIT });
    const token = useState()?.auth?.token
    var end;

    switch (token.split(" ")[0]) {
        case "Admin": end = endpoints.admin.generateTicketsQRs; break;
        case "Super": end = endpoints.super.generateTicketsQRs; break;
        default: break;
    }

    postRequest(data, { responseType: "arraybuffer" }, undefined, token, end)
        .then((response) => {
            download(response.data, response.headers["filename"], "application/pdf")
            close && close()
            return dispatch({
                type: WAIT_SUCCESS
            });
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message || "Something Went Wrong :(", style: {marginTop: "10vh"} })
            console.log(err);
            return dispatch({
                type: WAIT_FAIL,
            });
        });
};

export const generateInvitations = (data, close) => (dispatch, useState) => {
    dispatch({ type: WAIT });
    const token = useState()?.auth?.token
    var end;

    switch (token.split(" ")[0]) {
        case "Admin": end = endpoints.admin.generateInvitations; break;
        case "Super": end = endpoints.super.generateInvitations; break;
        default: break;
    }

    postRequest(data, undefined, undefined, token, end)
        .then((response) => {
            close && close()
            return dispatch({
                type: WAIT_SUCCESS
            });
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message || "Something Went Wrong :(", style: {marginTop: "10vh"} })
            console.log(err);
            return dispatch({
                type: WAIT_FAIL,
            });
        });
};

export const generateRequestDetails = (data, close) => (dispatch, useState) => {
    dispatch({ type: WAIT });
    const token = useState()?.auth?.token
    var end;

    switch (token.split(" ")[0]) {
        case "Admin": end = endpoints.admin.generateRequestDetails; break;
        case "Super": end = endpoints.super.generateRequestDetails; break;
        case "Partner": end = endpoints.partner.generateRequestDetails; break;
        default: break;
    }

    getRequest({...data, responseType: "arraybuffer" }, undefined, token, end)
        .then((response) => {
            download(response.data, response.headers["filename"], "text/csv")
            close && close()
            return dispatch({
                type: WAIT_SUCCESS
            });
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message || "Something Went Wrong :(", style: {marginTop: "10vh"} })
            console.log(err);
            return dispatch({
                type: WAIT_FAIL,
            });
        });
};

export const getUshers = (data) => (dispatch, useState) => {
    dispatch({ type: FETCH_RECORDS });
    const token = useState()?.auth?.token
    var end;

    switch (token.split(" ")[0]) {
        case "Admin": end = endpoints.admin.viewUshers; break;
        case "Super": end = endpoints.super.viewUshers; break;
        case "Partner": end = endpoints.partner.viewUshers; break;
        default: break;
    }

    getRequest(data, undefined, token, end)
        .then((response) => {
            const { data } = response;
            return dispatch({
                type: FETCH_RECORDS_SUCCESS,
                payload: data
            });
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: {marginTop: "10vh"} })
            console.log(err);
            return dispatch({
                type: FETCH_RECORDS_FAIL,
            });
        });
};

export const getPartners = (data) => (dispatch, useState) => {
    dispatch({ type: FETCH_RECORDS });
    const token = useState()?.auth?.token

    getRequest(data, undefined, token, endpoints.super.listPartners)
        .then((response) => {
            const { data } = response;
            return dispatch({
                type: FETCH_RECORDS_SUCCESS,
                payload: data
            });
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: {marginTop: "10vh"} })
            console.log(err);
            return dispatch({
                type: FETCH_RECORDS_FAIL,
            });
        });
};

export const createUsher = (data, close) => (dispatch, useState) => {
    dispatch({ type: WAIT });
    const token = useState()?.auth?.token

    postRequest(data, undefined, undefined, token, endpoints.super.createUsher)
        .then((response) => {
            close && close()
            return dispatch({
                type: WAIT_SUCCESS
            });
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: {marginTop: "10vh"} })
            console.log(err);
            return dispatch({
                type: WAIT_FAIL,
            });
        });
};

export const createPartner = (data, navigate) => (dispatch, useState) => {
    dispatch({ type: WAIT });
    const token = useState()?.auth?.token

    postRequest(data, undefined, undefined, token, endpoints.super.createPartner)
        .then((response) => {
            navigate && navigate(-1)
            return dispatch({
                type: WAIT_SUCCESS
            });
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: {marginTop: "10vh"} })
            console.log(err);
            return dispatch({
                type: WAIT_FAIL,
            });
        });
};

export const editPartner = (data, navigate) => (dispatch, useState) => {
    dispatch({ type: WAIT });
    const token = useState()?.auth?.token

    putRequest(data, undefined, undefined, token, endpoints.super.editPartner)
        .then((response) => {
            const {data} = response
            notification.success({ message: "Partner Edited", style: {marginTop: "10vh"} })
            navigate && navigate(-1)
            return dispatch({
                type: WAIT_SUCCESS
            });
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: {marginTop: "10vh"} })
            console.log(err);
            return dispatch({
                type: WAIT_FAIL,
            });
        });
};

export const deletePartner = (data) => (dispatch, useState) => {
    dispatch({ type: WAIT });
    const token = useState()?.auth?.token

    delRequest(data, undefined, token, endpoints.super.deletePartner)
        .then((response) => {
            const {data} = response
            return dispatch({
                type: WAIT_SUCCESS
            });
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: {marginTop: "10vh"} })
            console.log(err);
            return dispatch({
                type: WAIT_FAIL,
            });
        });
};

export const addFeaturedEvent = (data) => (dispatch, useState) => {
    dispatch({ type: EVENT });
    const token = useState()?.auth?.token

    postRequest(data, undefined, undefined, token, endpoints.super.featuredEvent)
        .then((response) => {
            const {data} = response
            let events = useState()?.events?.results
            events.docs = events?.docs?.map((event) => {
                if (event._id === data._id) {
                    return data
                } else {
                    return {...event}
                }
            })
            return dispatch({
                type: EVENT_SUCCESS,
                payload: {...events}
            });
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: {marginTop: "10vh"} })
            console.log(err);
            return dispatch({
                type: EVENT_FAIL,
            });
        });
};

export const removeFeaturedEvent = (data) => (dispatch, useState) => {
    dispatch({ type: EVENT });
    const token = useState()?.auth?.token

    delRequest(data, undefined, token, endpoints.super.featuredEvent)
        .then((response) => {
            const {data} = response
            let events = useState()?.events?.results
            events.docs = events?.docs?.map((event) => {
                if (event._id === data._id) {
                    return data
                } else {
                    return {...event}
                }
            })
            return dispatch({
                type: EVENT_SUCCESS,
                payload: {...events}
            });
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: {marginTop: "10vh"} })
            console.log(err);
            return dispatch({
                type: EVENT_FAIL,
            });
        });
};

export const getTicketRequestsAdmin = (data) => (dispatch, useState) => {
    dispatch({ type: FETCH_RECORDS });
    const token = useState()?.auth?.token
    var end;

    switch (token.split(" ")[0]) {
        case "Admin": end = endpoints.admin.requests.root; break;
        case "Super": end = endpoints.super.requests.root; break;
        case "Partner": end = endpoints.partner.requests.root; break;
        default: break;
    }

    getRequest(data, undefined, token, end)
        .then((response) => {
            const {data} = response
            return dispatch({
                type: FETCH_RECORDS_SUCCESS,
                payload: data
            });
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: {marginTop: "10vh"} })
            console.log(err);
            return dispatch({
                type: FETCH_RECORDS_FAIL,
            });
        });
};

export const getEventTickets = (data) => (dispatch, useState) => {
    dispatch({ type: FETCH_RECORDS });
    const token = useState()?.auth?.token
    var end;

    switch (token.split(" ")[0]) {
        case "Admin": end = endpoints.admin.eventTickets; break;
        case "Super": end = endpoints.super.eventTickets; break;
        case "Partner": end = endpoints.partner.eventTickets; break;
        default: break;
    }

    getRequest(data, undefined, token, end)
        .then((response) => {
            const {data} = response
            return dispatch({
                type: FETCH_RECORDS_SUCCESS,
                payload: data
            });
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: {marginTop: "10vh"} })
            console.log(err);
            return dispatch({
                type: FETCH_RECORDS_FAIL,
            });
        });
};

export const deleteUshers = (data) => (dispatch, useState) => {
    dispatch({ type: WAIT });
    const token = useState()?.auth?.token

    delRequest(data, undefined, token, endpoints.super.deleteUsher)
        .then((response) => {
            const {data} = response
            return dispatch({
                type: WAIT_SUCCESS
            });
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: {marginTop: "10vh"} })
            console.log(err);
            return dispatch({
                type: WAIT_FAIL,
            });
        });
};

const requestsInitialState = {
    status: "Unseen",
    search: "",
    page: 1,
    limit: 10
}

export const acceptTicketRequest = (data) => (dispatch, useState) => {
    dispatch({ type: WAIT });
    const token = useState()?.auth?.token
    var end;

    switch (token.split(" ")[0]) {
        case "Admin": end = endpoints.admin.requests.accept; break;
        case "Super": end = endpoints.super.requests.accept; break;
        case "Partner": end = endpoints.partner.requests.accept; break;
        default: break;
    }

    postRequest(data, undefined, undefined, token, end)
        .then((response) => {
            const {data} = response
            return dispatch({
                type: WAIT_SUCCESS
            });
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: {marginTop: "10vh"} })
            console.log(err);
            return dispatch({
                type: WAIT_FAIL,
            });
        });
};

export const rejectTicketRequest = (data) => (dispatch, useState) => {
    dispatch({ type: WAIT });
    const token = useState()?.auth?.token
    var end;

    switch (token.split(" ")[0]) {
        case "Admin": end = endpoints.admin.requests.reject; break;
        case "Super": end = endpoints.super.requests.reject; break;
        case "Partner": end = endpoints.partner.requests.reject; break;
        default: break;
    }

    postRequest(data, undefined, undefined, token, end)
        .then((response) => {
            const {data} = response
            return dispatch({
                type: WAIT_SUCCESS
            });
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: {marginTop: "10vh"} })
            console.log(err);
            return dispatch({
                type: WAIT_FAIL,
            });
        });
};

export const fulfillTicketRequest = (data) => (dispatch, useState) => {
    dispatch({ type: WAIT });
    const token = useState()?.auth?.token
    var end;

    switch (token.split(" ")[0]) {
        case "Admin": end = endpoints.admin.requests.fulfill; break;
        case "Super": end = endpoints.super.requests.fulfill; break;
        case "Partner": end = endpoints.partner.requests.fulfill; break;
        default: break;
    }

    postRequest(data, undefined, undefined, token, end)
        .then((response) => {
            const {data} = response
            return dispatch({
                type: WAIT_SUCCESS
            });
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: {marginTop: "10vh"} })
            console.log(err);
            return dispatch({
                type: WAIT_FAIL,
            });
        });
};

export const getOrders = (data) => (dispatch, useState) => {
    dispatch({ type: FETCH_RECORDS });
    const token = useState()?.auth?.token
    var end;

    switch (token.split(" ")[0]) {
        case "Admin": end = endpoints.admin.orders; break;
        case "Super": end = endpoints.super.orders; break;
        case "Partner": end = endpoints.partner.orders; break;
        default: break;
    }

    getRequest(data, undefined, token, end)
        .then((response) => {
            const {data} = response
            return dispatch({
                type: FETCH_RECORDS_SUCCESS,
                payload: data
            });
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: {marginTop: "10vh"} })
            console.log(err);
            return dispatch({
                type: FETCH_RECORDS_FAIL,
            });
        });
};

export const fulfillTransfer = (data) => (dispatch, useState) => {
    dispatch({ type: WAIT });
    const token = useState()?.auth?.token
    var end;

    switch (token.split(" ")[0]) {
        case "Admin": end = endpoints.admin.transfer; break;
        case "Super": end = endpoints.super.transfer; break;
        case "Partner": end = endpoints.partner.transfer; break;
        default: break;
    }

    postRequest(data, undefined, undefined, token, end)
        .then((response) => {
            const {data} = response
            return dispatch({
                type: WAIT_SUCCESS
            });
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: {marginTop: "10vh"} })
            console.log(err);
            return dispatch({
                type: WAIT_FAIL,
            });
        });
};