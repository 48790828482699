import * as React from 'react';
import { Typography, Box, Container, TextField, CssBaseline, Button, Avatar, Select, MenuItem, InputLabel, FormHelperText, FormControl, CircularProgress, Chip } from '@mui/material';
import { connect } from "react-redux";
import { useNavigate, useParams } from 'react-router-dom';
import ConstructionIcon from '@mui/icons-material/Construction';
import { centered_flex_box } from '../../app/components/Styles';
import ReactGA from "react-ga4";

export const Careers = ({ }) => {

    const navigate = useNavigate()
    React.useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: "/careers", title: "Careers" });
    }, [])

    return (
        <Container component="main">
            <Box sx={{ ...centered_flex_box, flexDirection: "column" }}>
                <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSfZlXZIRrlMlLCEDHAjvo0PWolrT1WdYKGtnWozE6N6FHKfSw/viewform?embedded=true" width="640" height="1116" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
            </Box>
        </Container>
    );
}

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Careers);