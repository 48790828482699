import * as React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Typography, Box, Container, TextField, CssBaseline, Button, Avatar, Select, MenuItem, FormHelperText, InputLabel, CircularProgress, FormControl, InputAdornment, IconButton } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { connect } from "react-redux";
import { changePassword } from '../../app/store/actions/authActions';
import { Navigate, NavLink, Route, Routes } from "react-router-dom";
import { centered_flex_box, MainInput, MainInputLabel, MainPassword, main_button, StyledInput, SecInput, sec_button, TerInput } from '../../app/components/Styles';

export const ChangePassword = ({ changePassword, user, token, isLoading }) => {

    React.useEffect(() => {
        console.log(window.location.pathname)
    }, [])

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        var details = {
            currPassword: data.get('old-password'),
            password: data.get('new-password')
        }
        changePassword(details)
    };

    if (isLoading) {
        return (
            <Box sx={{ ...centered_flex_box }}>
                <CircularProgress sx={{ color: "var(--secColor)" }} />
            </Box>
        )
    }

    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    minHeight: "100%"
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'var(--secColor)' }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Change Password
                </Typography>

                {window.location.pathname === "/Super/ChangePassword" ? (
                    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
                        <MainInput
                            margin="normal"
                            required
                            fullWidth
                            name="old-password"
                            label="Current Password"
                            id="old-password"
                            autoComplete="password"
                            sx={{ my: 1 }}
                        />
                        <MainInput
                            margin="normal"
                            required
                            fullWidth
                            name="new-password"
                            label="New Password"
                            id="new-password"
                            sx={{ my: 1 }}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2, ...main_button }}
                        >
                            Change Password
                        </Button>
                    </Box>

                ) : (
                    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
                        <TerInput
                            margin="normal"
                            required
                            fullWidth
                            name="old-password"
                            label="Current Password"
                            id="old-password"
                            autoComplete="password"
                            sx={{ my: 1 }}
                        />
                        <TerInput
                            margin="normal"
                            required
                            fullWidth
                            name="new-password"
                            label="New Password"
                            id="new-password"
                            sx={{ my: 1 }}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2, ...sec_button }}
                        >
                            Change Password
                        </Button>
                    </Box>
                    )}
            </Box>
        </Container>
    );
}

const mapStateToProps = (state) => ({
    user: state?.auth?.user,
    isLoading: state?.auth?.isLoading,
    token: state?.auth?.token
});

const mapDispatchToProps = { changePassword };

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);