import * as React from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import InfoIcon from "@mui/icons-material/Info";
import {
  Typography,
  Box,
  Container,
  TextField,
  CssBaseline,
  Button,
  Avatar,
  Select,
  MenuItem,
  InputLabel,
  FormHelperText,
  FormControl,
  CircularProgress,
  Chip,
  Tooltip,
  Card,
} from "@mui/material";
import { connect } from "react-redux";
import {
  centered_flex_box,
  MainInput,
  MainInputLabel,
  MainTextArea,
  main_button,
  reject_button,
  sec_button,
  StyledInput,
} from "../../app/components/Styles";
import { useNavigate, useParams } from "react-router-dom";
import { rejectInvitation } from "../../app/store/actions/commonActions";
import ReactGA from "react-ga4";

export const RejectInvitation = ({ isLoading, isError, rejectInvitation }) => {
  const navigate = useNavigate();
  const { id } = useParams();

  React.useEffect(() => {
    rejectInvitation({
      invitationId: id
    })
  }, [])

  if (isLoading) {
    return (
      <Box sx={{ ...centered_flex_box, flexDirection: "column" }}>
        <CircularProgress sx={{ color: "var(--secColor)" }} />
        <Typography>Rejecting Invitation...</Typography>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        marginTop: -5,
        paddingBottom: 8,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {
        isError ? <>
          <Box sx={{ ...centered_flex_box, mt: 2, flexDirection: "column", p: 5 }}>
            <Typography fontSize={30} fontWeight="bold" textAlign="center" color={"var(--primaryPurple)"}>Something Went Wrong</Typography>
            <hr style={{ width: "50%", opacity: "100%", "border-top": "solid var(--primaryPurple) 3px" }} />
          </Box>
        </> : <>
          <Box sx={{ ...centered_flex_box, mt: 2, flexDirection: "column", p: 5 }}>
            <Typography fontSize={30} fontWeight="bold" textAlign="center" color={"var(--primaryPurple)"}>Invitation Rejected!</Typography>
            <hr style={{ width: "50%", opacity: "100%", "border-top": "solid var(--primaryPurple) 3px" }} />
          </Box>

          <Typography fontSize={25} textAlign="center" color={"var(--primaryPurple)"}>We are sad to see you go</Typography>
          <Button onClick={() => navigate("/")} sx={{ mt: 1, ...main_button }}>See More Events</Button>
        </>
      }
    </Box>
  );
};

const mapStateToProps = (state) => ({
  isLoading: state?.wait?.isLoading,
  isError: state?.wait?.isError
});

const mapDispatchToProps = { rejectInvitation };

export default connect(mapStateToProps, mapDispatchToProps)(RejectInvitation);
