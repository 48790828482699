import React from "react";
import { connect } from "react-redux";
import { Routes, Route } from "react-router-dom";
import { About, AdminHome, Careers, Cart, ChangePassword, CheckTransaction, ComingSoon, Contact, CreateEvent, CreatePartner, EditEvent, EditPartner, EventDashboard, EventTickets, FAQs, ForgotPassword, Home, LiveEvent, OrderRequests, PartnerEvents, PartnerLogin, PartnerProfile, Policy, Profile, RefundPolicy, RequestEvent, Requests, ResetPassword, Scanner, SearchEvents, SearchPartners, SignUp, SingleEvent, SuperLogin, Terms, TicketRequests, Tickets, UserLogin, UsherLogin, VerifyAccount } from "./Pages";
import ProtectedRoute from "./ProtectedRoute";
import { AcceptInvitation, DownloadTicket, RejectInvitation } from "./Pages/InvitationPages";

export const Router = () => {
  return (
    <Routes>
      <Route path="/events" exact element={<SearchEvents />} />
      <Route path="/events/request" exact element={<RequestEvent />} />
      <Route path="/" exact element={<Home />} />
      <Route path="/register" exact element={<SignUp />} />
      <Route path="/forgot-password" exact element={<ForgotPassword />} />
      <Route path="/reset-password/:token" exact element={<ResetPassword />} />
      <Route path="/Login" exact element={<UserLogin />} />
      <Route path="/verify/:token" exact element={<VerifyAccount />} />
      <Route path="/Super/login" exact element={<SuperLogin />} />
      <Route path="/Usher/login" exact element={<UsherLogin />} />
      <Route path="/Partner/login" exact element={<PartnerLogin />} />
      <Route path="/event/:id" exact element={<SingleEvent />} />
      <Route path="/about" exact element={<About />} />
      <Route path="/contact" exact element={<Contact />} />
      <Route path="/faqs" exact element={<FAQs />} />
      <Route path="/careers" exact element={<Careers />} />
      <Route path="/terms" exact element={<Terms />} />
      <Route path="/policy" exact element={<Policy />} />
      <Route path="/refund-policy" exact element={<RefundPolicy />} />
      <Route path="/invitation/accept/:id" exact element={<AcceptInvitation />} />
      <Route path="/invitation/reject/:id" exact element={<RejectInvitation />} />
      <Route path="/download/:id" exact element={<DownloadTicket />} />
      <Route path="/transaction/response" exact element={<CheckTransaction />} />
      <Route exact element={<ProtectedRoute allowed={["Super", "Admin"]} />}>
        <Route path="/Super" exact element={<AdminHome />} />
        <Route path="/Admin" exact element={<AdminHome />} />
        <Route path="/events/create" exact element={<CreateEvent />} />
        <Route path="/event/edit/:id" exact element={<EditEvent />} />
        <Route path="/Admins/TicketRequests" exact element={<TicketRequests />} />
      </Route>
      <Route exact element={<ProtectedRoute allowed={["Partner"]} />}>
        <Route path="/Partner" exact element={<PartnerEvents />} />
        <Route path="/Partner/profile" exact element={<PartnerProfile />} />
        <Route path="/Partner/ChangePassword" exact element={<ChangePassword />} />
        <Route path="/Partner/events" exact element={<PartnerEvents />} />
      </Route>
      <Route exact element={<ProtectedRoute allowed={["Partner", "Super", "Admin"]} />}>
        <Route path="/event/live/:id" exact element={<LiveEvent />} />
        <Route path="/event/tickets/:id" exact element={<EventTickets />} />
        <Route path="/event/requests/:id" exact element={<TicketRequests />} />
        <Route path="/event/orders/requests/:id" exact element={<OrderRequests />} />
        <Route path="/event/dashboard/:id" exact element={<EventDashboard />} />
      </Route>
      <Route exact element={<ProtectedRoute allowed={["Super"]} />}>
        <Route path="/Super/ChangePassword" exact element={<ChangePassword />} />
        <Route path="/Super/create" exact element={<ComingSoon />} />
        <Route path="/Super/Admin/create" exact element={<ComingSoon />} />
        <Route path="/Super/Partner/create" exact element={<CreatePartner />} />
        <Route path="/Super/Partner/edit/:id" exact element={<EditPartner />} />
        <Route path="/Super/Partner/search" exact element={<SearchPartners />} />
      </Route>
      <Route exact element={<ProtectedRoute allowed={["Admin"]} />}>
        <Route path="/Admin/ChangePassword" exact element={<ChangePassword />} />
      </Route>
      <Route exact element={<ProtectedRoute allowed={["User"]} />}>
        <Route path="/User" exact element={<Home />} />
        <Route path="/User/profile" exact element={<Profile />} />
        <Route path="/User/requests" exact element={<Requests />} />
        <Route path="/User/tickets" exact element={<Tickets />} />
        <Route path="/User/cart" exact element={<Cart />} />
        <Route path="/User/ChangePassword" exact element={<ChangePassword />} />
      </Route>
      <Route exact element={<ProtectedRoute allowed={["Usher"]} />}>
        <Route path="/Usher" exact element={<Scanner />} />
      </Route>
    </Routes>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Router);
