import * as React from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import DownloadIcon from "@mui/icons-material/Download";
import UploadIcon from "@mui/icons-material/Upload";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import {
  Typography,
  Box,
  Container,
  TextField,
  CssBaseline,
  Button,
  Avatar,
  Select,
  MenuItem,
  InputLabel,
  FormHelperText,
  FormControl,
  CircularProgress,
  Chip,
  Tooltip,
} from "@mui/material";
import { connect } from "react-redux";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { uploadFile } from "react-s3";
import {
  centered_flex_box,
  MainInput,
  MainInputLabel,
  MainTextArea,
  main_button,
  reject_button,
  sec_button,
  StyledInput,
} from "../../app/components/Styles";
import { useNavigate, useParams } from "react-router-dom";
import ReactFileReader from "react-file-reader";
import { createEvent } from "../../app/store/actions/eventActions";
import { config } from "../../app/config";
import moment from "moment";

export const CreateEvent = ({ createEvent, isLoading }) => {
  window.Buffer = window.Buffer || require("buffer").Buffer;

  const navigate = useNavigate();
  const [file, setFile] = React.useState(null);
  const id = useParams().id;

  const [extraInfo, setExtraInfo] = React.useState(0);
  const [categories, setCategories] = React.useState(1);
  const [image, setImage] = React.useState(null);
  const [coverImage, setCoverImage] = React.useState(null);
  const [sponsorsImage, setSponsorsImage] = React.useState(null);
  const [attachment, setAttachment] = React.useState(null);
  const [chart, setChart] = React.useState(null);
  const [paymentMethod, setPaymentMethod] = React.useState("None");
  const [corporate, setCorporate] = React.useState(false);
  const [hasAttachment, setHasAttachment] = React.useState(false);

  const handlePaymentMethod = (event) => {
    setPaymentMethod(event.target.value);
  };

  const handleAdd = (event) => {
    setExtraInfo(extraInfo + 1);
  };

  const handleRemove = (event) => {
    if (extraInfo > 0) {
      setExtraInfo(extraInfo - 1);
    }
  };

  const handleAddCat = (event) => {
    setCategories(categories + 1);
  };

  const handleRemoveCat = (event) => {
    if (categories > 1) {
      setCategories(categories - 1);
    }
  };

  const handleAddImage = (files) => {
    let file = files[0];
    console.log(file);
    setImage(file);
  };

  const handleDeleteImage = () => {
    setImage(null);
  };

  const handleAddCoverImage = (files) => {
    let file = files[0];
    console.log(file);
    setCoverImage(file);
  };

  const handleDeleteCoverImage = () => {
    setCoverImage(null);
  };

  const handleAddSponsorsImage = (files) => {
    let file = files[0];
    console.log(file);
    setSponsorsImage(file);
  };

  const handleDeleteSponsorsImage = () => {
    setSponsorsImage(null);
  };

  const handleAddAttachment = (files) => {
    let file = files[0];
    console.log(file);
    setAttachment(file);
  };

  const handleDeleteAttachment = () => {
    setAttachment(null);
  };

  const handleAddChart = (files) => {
    let file = files[0];
    console.log(file);
    setChart(file);
  };

  const handleDeleteChart = () => {
    setChart(null);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    try {
      var imageData;
      var coverImageData;
      var sponsorsImageData;
      var chartData;
      var attachmentData;
      var categoriesList = [];
      var moreInfo = [];
      isLoading = true;
      if (image) {
        imageData = await uploadFile(image, { ...config, dirName: "images" });
      }
      if (coverImage) {
        coverImageData = await uploadFile(coverImage, {
          ...config,
          dirName: "images",
        });
      }
      if (sponsorsImage) {
        sponsorsImageData = await uploadFile(sponsorsImage, {
          ...config,
          dirName: "images",
        });
      }
      if (attachment) {
        attachmentData = await uploadFile(attachment, {
          ...config,
          dirName: "images",
        });
      }
      if (chart) {
        chartData = await uploadFile(chart, { ...config, dirName: "charts" });
      }
      isLoading = false
      for (let i = 0; i < extraInfo; i++) {
        moreInfo.push(data.get(`req${i}`));
      }
      for (let i = 0; i < categories; i++) {
        categoriesList.push({
          category: data.get(`cat${i}`),
          price: parseInt(data.get(`cat${i}Price`)),
          amount: parseInt(data.get(`cat${i}Tickets`)),
          sold: 0,
          available: true,
        });
      }

      const creation = {
        image: imageData?.location,
        coverImage: coverImageData?.location,
        sponsorsImage: sponsorsImageData?.location,
        title: data.get("title"),
        description: data.get("description"),
        terms: data.get("terms"),
        artist: data.get("artist"),
        date: moment(data.get("date")),
        location: data.get("location"),
        seatChart: chartData?.location,
        categories: categoriesList,
        deadline: data.get("deadline")
          ? moment(data.get("deadline"))
          : undefined,
        public: data.get("public") === "on",
        moreInfo: moreInfo,
        needsVerification: data.get("needsVerification") === "on",
        byRequest: data.get("byRequest") === "on",
        gatesOpenAt: moment(data.get("gatesOpenAt")),
        locationURL: data.get("locationURL"),
        organizer: data.get("organizer"),
        message: data.get("message"),
        corporate: corporate,
        hasAttachment: hasAttachment,
        attachmentTitle: data.get("attachmentTitle"),
        attachment: attachmentData?.location,
        paymentMethod,
        paymentAddress:
          paymentMethod === "Cash"
            ? data.get("paymentAddress").split(",")
            : data.get("paymentAddress"),
      };

      createEvent({ creation: creation }, navigate);
    } catch (err) {
      console.log(err);
    }
  };

  const generateRequirements = () => {
    var items = [];
    for (let i = 0; i < extraInfo; i++) {
      items.push(
        <Box>
          <MainInput
            margin="normal"
            required
            fullWidth
            id={`req${i}`}
            label="Requirement"
            name={`req${i}`}
          />
        </Box>
      );
    }
    if (extraInfo > 0) {
      items.push(<Button onClick={handleRemove}>Remove</Button>);
    }
    return items;
  };

  const generateCategories = () => {
    var items = [];
    for (let i = 0; i < categories; i++) {
      items.push(
        <Grid container>
          <Grid item xs={4}>
            <MainInput
              margin="normal"
              required
              id={`cat${i}`}
              label="Category"
              name={`cat${i}`}
            />
          </Grid>
          <Grid item xs={4}>
            <MainInput
              margin="normal"
              required
              type="number"
              id={`cat${i}Price`}
              label="Price"
              name={`cat${i}Price`}
            />
          </Grid>
          <Grid item xs={4}>
            <MainInput
              margin="normal"
              required
              type="number"
              id={`cat${i}Tickets`}
              label="Number of Tickets"
              name={`cat${i}Tickets`}
            />
          </Grid>
        </Grid>
      );
    }
    if (categories > 1) {
      items.push(
        <Button sx={{ ...reject_button, mr: 2 }} onClick={handleRemoveCat}>
          Remove
        </Button>
      );
    }
    return items;
  };

  if (isLoading) {
    return (
      <Box sx={{ ...centered_flex_box, minHeight: "100vh" }}>
        <CircularProgress sx={{ color: "var(--secColor)" }} />
      </Box>
    );
  }

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: -5,
          paddingBottom: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            bgcolor: "var(--terColor)",
            color: "var(--appBg)",
            width: "100vw",
            padding: 5,
          }}
        >
          <Box sx={{ ...centered_flex_box, flexDirection: "column" }}>
            <Avatar sx={{ m: 1, bgcolor: "var(--secColor)" }}>
              <AddCircleOutlineIcon />
            </Avatar>
            <Typography component="h1" variant="h5" fontWeight="bold">
              Create Event
            </Typography>
          </Box>
        </Box>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
          <ReactFileReader
            multipleFiles={false}
            handleFiles={handleAddImage}
            fileTypes={[".jpeg", ".jpg", ".png", ".svg"]}
          >
            <Button fullWidth sx={{ my: 1, ...main_button }}>
              <UploadIcon /> Upload Event Image
            </Button>
          </ReactFileReader>
          {image && (
            <Chip
              label={image.name}
              onDelete={handleDeleteImage}
              sx={{ color: "var(--appBg)", bgcolor: "var(--secColor)" }}
            />
          )}
          <ReactFileReader
            multipleFiles={false}
            handleFiles={handleAddCoverImage}
            fileTypes={[".jpeg", ".jpg", ".png", ".svg"]}
          >
            <Button fullWidth sx={{ my: 1, ...main_button }}>
              <UploadIcon /> Upload Cover Image
            </Button>
          </ReactFileReader>
          {coverImage && (
            <Chip
              label={coverImage.name}
              onDelete={handleDeleteCoverImage}
              sx={{ color: "var(--appBg)", bgcolor: "var(--secColor)" }}
            />
          )}
          <ReactFileReader
            multipleFiles={false}
            handleFiles={handleAddSponsorsImage}
            fileTypes={[".jpeg", ".jpg", ".png", ".svg"]}
          >
            <Button fullWidth sx={{ my: 1, ...main_button }}>
              <UploadIcon /> Upload Sponsors Image
            </Button>
          </ReactFileReader>
          {sponsorsImage && (
            <Chip
              label={sponsorsImage.name}
              onDelete={handleDeleteSponsorsImage}
              sx={{ color: "var(--appBg)", bgcolor: "var(--secColor)" }}
            />
          )}
          <MainInput
            margin="normal"
            required
            fullWidth
            id="title"
            label="Event Title"
            name="title"
          />
          <MainInput
            margin="normal"
            required
            fullWidth
            multiline
            minRows={5}
            name="description"
            label="Event Description"
            id="description"
          />
          <MainInput
            margin="normal"
            fullWidth
            id="artist"
            label="Event Artist"
            name="artist"
          />
          <MainInput
            margin="normal"
            type="datetime-local"
            required
            fullWidth
            id="date"
            label="Event Date"
            name="date"
            focused
          />
          <MainInput
            margin="normal"
            type="datetime-local"
            fullWidth
            id="deadline"
            label="Purchase Deadline"
            name="deadline"
            focused
          />
          <MainInput
            margin="normal"
            type="datetime-local"
            required
            fullWidth
            id="gatesOpenAt"
            label="Gates Open At"
            name="gatesOpenAt"
            focused
          />
          <MainInput
            margin="normal"
            required
            fullWidth
            id="location"
            label="Event Location"
            name="location"
          />
          <MainInput
            margin="normal"
            fullWidth
            type="url"
            id="locationURL"
            label="Google Maps URL"
            name="locationURL"
          />
          <hr />
          {generateRequirements()}
          <Tooltip
            title={`Additional data entry requirements when purchasing tickets; \n ex: Instagram Link`}
          >
            <Button sx={{ my: 1, ...main_button }} onClick={handleAdd}>
              Add Data Requirement
            </Button>
          </Tooltip>
          <hr />
          <ReactFileReader
            multipleFiles={false}
            handleFiles={handleAddChart}
            fileTypes={[".jpeg", ".jpg", ".png", ".svg"]}
          >
            <Button fullWidth sx={{ my: 1, ...main_button }}>
              <UploadIcon /> Upload Seating Chart
            </Button>
          </ReactFileReader>
          {chart && <Chip label={chart.name} onDelete={handleDeleteChart} />}
          {generateCategories()}
          <Button sx={{ my: 1, ...main_button }} onClick={handleAddCat}>
            Add Category
          </Button>
          <hr />
          <MainInput
            margin="normal"
            required
            multiline
            minRows={5}
            fullWidth
            name="terms"
            label="Event Terms and Conditions"
            id="terms"
          />
          <MainInput
            margin="normal"
            autoFocus
            fullWidth
            select
            labelId="num-label"
            id={`paymentMethod`}
            label="Payment Method"
            defaultValue="None"
            name={`paymentMethod`}
            onChange={handlePaymentMethod}
          >
            <MenuItem value="Cash">Cash</MenuItem>
            <MenuItem value="InstaPay">InstaPay</MenuItem>
            <MenuItem value="FawryPay">FawryPay</MenuItem>
            <MenuItem value="Paymob">Paymob</MenuItem>
            <MenuItem value="None">None</MenuItem>
          </MainInput>
          {(paymentMethod === "InstaPay" || paymentMethod === "Cash") && (
            <MainInput
              margin="normal"
              required
              fullWidth
              type="text"
              id="paymentAddress"
              label="Payment Details (Instapay Address / Phone Numbers)"
              name="paymentAddress"
            />
          )}
          <FormControlLabel
            control={<Checkbox name="public" id="public" />}
            label="Public Event"
          />
          <br />
          <Tooltip title="Check if this event is a corporate event for invitation flow">
            <FormControlLabel
              control={
                <Checkbox
                  value={corporate}
                  onChange={() => setCorporate(!corporate)}
                  name="corporate"
                  id="corporate"
                />
              }
              label="Corporate Event"
            />
          </Tooltip>
          <br />
          <Tooltip title="Users cannot buy tickets directly and have to be accepted first">
            <FormControlLabel
              control={
                <Checkbox
                  name="byRequest"
                  id="byRequest"
                  defaultChecked={
                    paymentMethod === "Cash" || paymentMethod === "InstaPay"
                  }
                />
              }
              label="Requires acceptance from admin"
            />
          </Tooltip>
          <br />
          <Tooltip title="Check if this event needs ushers to verify ticket holders name or id before entry">
            <FormControlLabel
              control={
                <Checkbox name="needsVerification" id="needsVerification" />
              }
              label="Needs verification on gate"
            />
          </Tooltip>
          <br />
          <hr />
          {corporate && (
            <>
              <MainInput
                margin="normal"
                fullWidth
                id="organizer"
                label="Organized By"
                name="organizer"
              />
              <MainInput
                margin="normal"
                fullWidth
                required
                multiline
                minRows={5}
                inputProps={{
                  maxlength: 512,
                }}
                name="message"
                label="Whatsapp Message (512 Characters Max)"
                id="message"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    value={hasAttachment}
                    onChange={() => setHasAttachment(!hasAttachment)}
                    name="hasAttachment"
                    id="hasAttachment"
                  />
                }
                label="Attachment Message"
              />
              <hr />
            </>
          )}
          {hasAttachment && (
            <>
              <ReactFileReader
                multipleFiles={false}
                handleFiles={handleAddAttachment}
                fileTypes={[".jpeg", ".jpg", ".png", ".svg"]}
              >
                <Button fullWidth sx={{ my: 1, ...main_button }}>
                  <UploadIcon /> Upload Attachment Image
                </Button>
              </ReactFileReader>
              {attachment && (
                <Chip
                  label={attachment.name}
                  onDelete={handleDeleteAttachment}
                  sx={{ color: "var(--appBg)", bgcolor: "var(--secColor)" }}
                />
              )}
              <MainInput
                margin="normal"
                fullWidth
                id="attachmentTitle"
                label="Attachment Title"
                name="attachmentTitle"
              />
              <hr />
            </>
          )}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2, ...main_button }}
          >
            Create
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  user: state?.auth?.user,
  token: state?.auth?.token,
  isLoading: state?.events?.isLoading,
});

const mapDispatchToProps = { createEvent };

export default connect(mapStateToProps, mapDispatchToProps)(CreateEvent);
