import { GRAB_TICKET, GRAB_TICKET_SUCCESS, GRAB_TICKET_FAIL } from "./types";
import { getRequest, postRequest, putRequest } from "../../../core/network";
import endpoints from "../../../constants/endPoints.json";
import { notification } from "antd";

export const validateTicket = (data) => (dispatch, useState) => {
    dispatch({ type: GRAB_TICKET });
    const token = useState().auth?.token
    
    postRequest(data, undefined, undefined, token, endpoints.usher.checkIn)
        .then((response) => {
            var verify = false;
            if (response.data.message === "Needs Verification") {
                notification.info({ message: "Please verify ticket holder information", style: {marginTop: "10vh"} })
                verify = true
            } else {
                notification.success({ message: "Ticket Validated", style: {marginTop: "10vh"} })
            }
            const { data } = response;
            return dispatch({
                type: GRAB_TICKET_SUCCESS,
                payload: data.ticket,
                needsVerification: verify
            });
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: {marginTop: "10vh"} })
            console.log(err);
            return dispatch({
                type: GRAB_TICKET_FAIL,
            });
        });
};

export const verifyTicket = (data) => (dispatch, useState) => {
    dispatch({ type: GRAB_TICKET });
    const token = useState().auth?.token
    
    postRequest(data, undefined, undefined, token, endpoints.usher.verify)
        .then((response) => {
            notification.success({ message: "Ticket Validated", style: {marginTop: "10vh"} })
            const { data } = response;
            return dispatch({
                type: GRAB_TICKET_SUCCESS,
                payload: data.ticket,
                needsVerification: false
            });
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: {marginTop: "10vh"} })
            console.log(err);
            return dispatch({
                type: GRAB_TICKET_FAIL,
            });
        });
};