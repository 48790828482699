import * as React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import DeleteIcon from '@mui/icons-material/Delete';
import { Typography, Box, Container, TextField, CssBaseline, Button, Avatar, Select, MenuItem, InputLabel, FormHelperText, FormControl, CircularProgress, Chip, Card, IconButton } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { connect } from "react-redux";
import { deleteUshers, generateTickets } from '../../app/store/actions/adminActions';
import { centered_flex_box, left_flex_box, right_flex_box, MainInput, MainInputLabel, MainPassword, main_button, reject_button, StyledInput, ter_button } from '../../app/components/Styles';
import { Form, useNavigate, useParams } from 'react-router-dom';
import ReactFileReader from 'react-file-reader';
import download from 'downloadjs';
import { notification } from 'antd';
import { deleteEvent, getEvent } from '../../app/store/actions/eventActions';
import { AddUsherModal, GenerateInvitationModal, GenerateTicketModal } from './components';
import LocationOn from '@mui/icons-material/LocationOn';
import AddShoppingCart from '@mui/icons-material/AddShoppingCart';
import moment from 'moment';
import Directions from '@mui/icons-material/Directions';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, PieChart, Pie } from 'recharts';
import { getUshers } from '../../app/store/actions/adminActions';
import { isMobile } from 'react-device-detect';
import ActionDialog from '../../app/components/ActionDialog';

export const EventDashboard = ({ role, getEvent, getUshers, wait, event, ushers, isLoading, deleteEvent, deleteUshers }) => {

    const navigate = useNavigate()
    const id = useParams().id
    const [action, setAction] = React.useState(false)
    const [deleteModal, setDeleteModal] = React.useState(false)
    const COLORS = ['var(--secColor)', 'var(--secColor2)', 'var(--terColor)', 'var(--terColor2)'];

    function timeout(delay) {
        return new Promise(res => setTimeout(res, delay));
    }

    const [modals, setModals] = React.useState({
        generateTickets: false,
        generateInvitations: false,
        edit: false,
        addUsher: false,
    })

    const handleClose = (modalName) => {
        setModals({ ...modals, [modalName]: false })
    }

    const handleOpen = (modalName) => {
        setModals({ ...modals, [modalName]: true })
    }

    React.useEffect(() => {
        if (isMobile) {
            notification.info({ message: "Actions in this page may not render correctly on mobile phones", style: { marginTop: "10vh" } })
        }
        getEvent({ eventId: id });
        getUshers({ eventId: id, page: 1, limit: 100 });
    }, [action])



    const handleDeleteUsher = async (data) => {
        deleteUshers(data)
        await timeout(500)
        setAction(!action)
    }

    if (isLoading) {
        return (
            <Box sx={{ ...centered_flex_box, minHeight: "100vh" }}>
                <CircularProgress sx={{ color: "var(--secColor)" }} />
            </Box>
        )
    }

    return (
        <Box
            sx={{
                alignItems: 'center',
                marginX: 6,
                paddingX: 6
            }}
        >
            <GenerateTicketModal action={action} setAction={setAction} open={modals.generateTickets} handleClose={() => handleClose("generateTickets")} event={event} />
            <GenerateInvitationModal action={action} setAction={setAction} open={modals.generateInvitations} handleClose={() => handleClose("generateInvitations")} event={event} />
            <AddUsherModal action={action} setAction={setAction} open={modals.addUsher} handleClose={() => handleClose("addUsher")} event={event} />
            <Grid container direction="row" spacing={3}>
                <Grid item xs={isMobile ? 12 : 4} sx={{ ...left_flex_box, direction: "column" }}>
                    <Grid container spacing={2} direction="column">
                        <Grid item>
                            <Typography sx={{ color: "var(--terColor)" }}><LocationOn />{event?.location}</Typography>
                            <Typography fontSize={40} fontWeight="bold">{event?.title}</Typography>
                            <Typography>{moment(event?.date).format('DD/MM/yyyy @ HH:mm')}</Typography>
                            <br />
                            <Typography>{event?.description}</Typography>
                        </Grid>
                        <Grid item>
                            <Box sx={{ ...centered_flex_box, flexDirection: "row" }}>
                                {role === "Super" && event?.corporate && <>
                                    <Button sx={{ ...ter_button, mx: 1 }} onClick={() => handleOpen("generateInvitations")}>Generate Invitations</Button>
                                </>}
                                {role === "Super" && <>
                                    <Button sx={{ ...ter_button, mx: 1 }} onClick={() => handleOpen("generateTickets")}>Generate Tickets</Button>
                                    <Button sx={{ ...ter_button, mx: 1 }} onClick={() => navigate(`/event/edit/${id}`)}>Edit Event</Button>
                                </>}
                                <Button sx={{ ...ter_button, mx: 1 }} onClick={() => navigate(`/event/tickets/${id}`)}>View Tickets</Button>
                                {event.paymentMethod === "InstaPay" && <Button sx={{ ...ter_button, mx: 1 }} onClick={() => navigate(`/event/orders/requests/${id}`)}>View Order Requests</Button> }
                                {event.byRequest && <Button sx={{ ...ter_button, mx: 1 }} onClick={() => navigate(`/event/requests/${id}`)}>View Requests</Button>}
                                <Button disabled={moment().isBefore(event?.gatesOpenAt)} sx={{ ...ter_button, mx: 1 }} onClick={() => navigate(`/event/live/${id}`)}>View Live Statistics</Button>
                                {role === "Super" && <Button sx={{ ...reject_button, mx: 1 }} onClick={() => setDeleteModal(true)}>Delete Event</Button>}
                                <ActionDialog open={deleteModal} handleAccept={() => deleteEvent({ eventId: id }, navigate)} handleClose={() => setDeleteModal(false)} title={`Are you sure you want to delete ${event?.title}?`} content={"Deleting this event will delete all issued tickets and issue refunds to users as well as deleting all ushers and any trace of this event"} />
                            </Box>
                        </Grid>
                        <hr />
                        <Grid item>
                            <Box sx={{ direction: "column" }}>
                                <Typography align='center' fontSize={20} fontWeight="bold">Event Usher Gates</Typography>
                                <Typography>Accounts for ushers to use. Give one account to each gate leader for all gate ushers to log in with</Typography>
                                <Box sx={{ ...centered_flex_box, direction: "column" }}>
                                    {ushers?.length > 0 && role === "Super" && <Button sx={{ ...reject_button, mx: 1 }} onClick={() => handleDeleteUsher({ eventId: id })}>Delete All Ushers</Button>}
                                </Box>
                                {ushers?.map((usher) => {
                                    return (<Card sx={{ p: 2, m: 1 }}>
                                        <Grid container>
                                            <Grid item xs={9}>
                                                <Typography sx={{ fontSize: 26, fontWeight: "bold" }}>
                                                    Username: {usher.username}
                                                </Typography>
                                            </Grid>
                                            <Grid item direction="row" alignItems="center" display="flex" justifyContent="flex-end" xs={3}>
                                                <IconButton onClick={() => handleDeleteUsher({ usherId: usher._id })}><DeleteIcon htmlColor='#ff3333' /></IconButton>
                                            </Grid>
                                        </Grid>
                                        <Typography my={1}>Assigned Gate: {usher.gate}</Typography>
                                        <Typography my={1}>Tickets Processed: {usher.ticketsProcessed}</Typography>
                                        <MainPassword
                                            margin="normal"
                                            fullWidth
                                            name="password"
                                            label="Password"
                                            id="password"
                                            readOnly
                                            value={usher.password}
                                            sx={{ my: 1 }}
                                        />
                                    </Card>)
                                })}
                                {role === "Super" && <Button sx={{ ...ter_button, mx: 1 }} onClick={() => handleOpen("addUsher")}>Add Usher Gate</Button>}
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={isMobile ? 12 : 8} sx={{ ...right_flex_box, direction: "column" }}>
                    {event?.corporate ? (
                        <Box>
                            {event?.categories.map((category) => {
                                return (
                                    <>
                                        <Typography textAlign="center" fontSize={20}>{category.category}</Typography>
                                        <PieChart width={300} height={300}>
                                            <Pie
                                                dataKey="value"
                                                isAnimationActive={false}
                                                data={[{ name: "Remaining Amount", value: category.amount }, { name: "Pending", value: category.sent }, { name: "Accepted", value: category.sold }]}
                                                cx="50%"
                                                cy="50%"
                                                innerRadius={60}
                                                outerRadius={80}
                                                label
                                                fill="var(--secColor)"
                                                paddingAngle={5}
                                            >
                                                {[{ name: "Remaining Amount", value: category.amount }, { name: "Pending", value: category.sent }, { name: "Accepted", value: category.sold }].map((entry, index) => (
                                                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                                ))}
                                            </Pie>
                                            <Tooltip />
                                        </PieChart>
                                    </>
                                )
                            })}
                        </Box>
                    ) : (
                        <Box>
                            <Typography fontWeight="bold">Statistics</Typography><br />
                            <Typography align='center'>Ticket Sales</Typography>
                            <BarChart
                                width={isMobile ? 300 : 500}
                                height={isMobile ? 200 : 300}
                                data={event?.categories?.map((cat) => { return { name: cat.category, "Tickets Remaining": cat.amount, "Tickets Sold": cat.sold } })}
                                margin={{
                                    top: 5,
                                    right: 30,
                                    left: 20,
                                    bottom: 5,
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Bar dataKey="Tickets Remaining" fill="var(--secColor)" />
                                <Bar dataKey="Tickets Sold" fill="var(--terColor)" />
                            </BarChart>
                            <Typography align='center'>Income</Typography>
                            <BarChart
                                width={isMobile ? 300 : 500}
                                height={isMobile ? 200 : 300}
                                data={event?.categories?.map((cat) => { return { name: cat.category, "Sales": cat.sold * cat.price } })}
                                margin={{
                                    top: 5,
                                    right: 30,
                                    left: 20,
                                    bottom: 5,
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Bar dataKey="Sales" fill="var(--secColor)" />
                            </BarChart>
                        </Box>
                    )}
                </Grid>
            </Grid>
        </Box>
    );
}

const mapStateToProps = (state) => ({
    wait: state?.wait,
    event: state?.events?.results,
    ushers: state?.records?.results?.docs,
    isLoading: state?.events?.isLoading,
    role: state?.auth?.token?.split(" ")[0]
});

const mapDispatchToProps = { getEvent, getUshers, deleteEvent, deleteUshers };

export default connect(mapStateToProps, mapDispatchToProps)(EventDashboard);