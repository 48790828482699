import * as React from 'react';
import { Typography, Box, Container, TextField, CssBaseline, Button, Avatar, Select, MenuItem, InputLabel, FormHelperText, FormControl, CircularProgress, Chip, Card, CardContent, Skeleton, Grid, Dialog } from '@mui/material';
import { connect } from "react-redux";
import { useNavigate, useParams } from 'react-router-dom';
import ConstructionIcon from '@mui/icons-material/Construction';
import { MainInput, SecInput, centered_flex_box, left_flex_box, main_button, right_flex_box, sec_button } from '../../app/components/Styles';
import DeleteIcon from '@mui/icons-material/Delete';
import InboxIcon from '@mui/icons-material/Inbox';
import PaymentIcon from "@mui/icons-material/Payment";
import { getCart, clearCart, removeFromCart, checkout } from '../../app/store/actions/userActions';
import { Image } from 'antd';
import { isMobile } from "react-device-detect"
import ActionDialog from '../../app/components/ActionDialog';
import MuiPhoneNumber from 'material-ui-phone-number';
export const Cart = ({ getCart, clearCart, removeFromCart, cart, isLoading, checkout }) => {

    const navigate = useNavigate()
    const [clearDialog, setClearDialog] = React.useState(false)
    const [checkoutDialog, setCheckoutDialog] = React.useState(false)
    const [paymentMethod, setPaymentMethod] = React.useState("Card")
    const convenienceFees = ((cart?.totalPrice * 0.0275 + 5) * 1.14).toFixed(2)

    React.useEffect(() => {
        getCart({})
    }, [])

    const handleClearCart = () => {
        clearCart({})
        setClearDialog(false)
    }

    const handleCheckout = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        checkout({ paymentMethod, mobile: data.get('mobile') })
        setCheckoutDialog(false);
    }

    return (
        <Container component="main">
            <ActionDialog open={clearDialog} handleClose={() => setClearDialog(false)} handleAccept={handleClearCart} title={"Clear Cart"} content={"Are you sure you want to clear all items from cart?"} />
            <Dialog open={checkoutDialog} onClose={() => setCheckoutDialog(false)}>
                <Box sx={{ ...centered_flex_box, padding: 2 }}>
                    <Box component="form" onSubmit={handleCheckout}>

                        <SecInput
                            margin="normal"
                            required
                            fullWidth
                            id="paymentMethod"
                            label="Payment Method"
                            name="paymentMethod"
                            autoFocus
                            select
                            value={paymentMethod}
                            onChange={({ target }) => setPaymentMethod(target.value)}
                        >
                            <MenuItem value={"Card"}>
                                <Typography>Online Card</Typography>
                            </MenuItem>
                            <MenuItem value={"Wallet"}>
                                <Typography>Mobile Wallet</Typography>
                            </MenuItem>
                        </SecInput>

                        {paymentMethod === "Wallet" && <MuiPhoneNumber
                            sx={{
                                "& label": {
                                    color: "var(--appBg) !important",
                                },
                                "& label.Mui-focused": {
                                    color: "var(--appBg) !important",
                                },
                                "& .MuiOutlinedInput-root": {
                                    color: "var(--appBg) !important",
                                    "& fieldset": {
                                        border: "1px solid var(--secPurple)",
                                    },
                                    "&.Mui-focused fieldset": {
                                        borderColor: "var(--appBg)",
                                    },

                                    borderRadius: 0.5,
                                },
                                my: 1
                            }}
                            margin="normal"
                            fullWidth
                            required
                            id="mobile"
                            label="Mobile"
                            name="mobile"
                            variant='outlined'
                            defaultCountry={'eg'}
                            preferredCountries={['eg']}
                            countryCodeEditable={false}
                            inputProps={{
                                minLength: 13
                            }}
                            disableAreaCodes
                        />
                        }
                        <hr />
                        <Typography fontSize={isMobile ? 18 : 25}>Convenience Fees: {convenienceFees} EGP</Typography>
                        <Typography fontWeight="bold" fontSize={isMobile ? 18 : 25}>Total: {cart?.totalPrice + parseFloat(convenienceFees)} EGP</Typography>
                        <hr />
                        <Button type='submit' sx={{ ...sec_button }} fullWidth>Continue</Button>
                    </Box>
                </Box>
            </Dialog>
            <Card sx={{ background: "white !important", color: "var(--secColor) !important" }}>
                <CardContent>
                    <Grid container sx={{ mb: 2 }}>
                        <Grid item xs={6}>
                            <Typography fontWeight="bold" fontSize={isMobile ? 18 : 25}>My Cart</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography onClick={() => setClearDialog(true)} fontWeight="bold" textAlign="right" sx={{ color: "red", textDecorationLine: "underline", cursor: "pointer" }} fontSize={isMobile ? 18 : 25}>Remove All</Typography>
                        </Grid>
                    </Grid>
                    {isLoading ? <>
                        <Skeleton variant="rectangle" sx={{ my: 2 }} height={50} />
                        <Skeleton variant="rectangle" sx={{ my: 2 }} height={50} />
                        <Skeleton variant="rectangle" sx={{ my: 2 }} height={50} />
                    </> : <>
                        {cart?.items?.length > 0 && cart?.items?.map((item) => {
                            return (
                                <Card key={item._id} sx={{ my: 2 }}>
                                    <CardContent>
                                        <Grid container>
                                            <Grid item xs={6} sx={{ display: "flex", alignItems: "center", flexDirection: "row", cursor: "pointer" }} onClick={() => navigate(`/event/${item.itemId}`)} >
                                                <Image src={item.event.image || "https://tickit-media-bucket.s3.eu-west-3.amazonaws.com/images/Template.png"} width={isMobile ? 50 : 100} height={isMobile ? 50 : 100} />
                                                <Box sx={{ ...left_flex_box, flexDirection: "column", ml: 2 }}>
                                                    <Typography textAlign="left" fontWeight="bold" fontSize={20}>{item.event.title}</Typography>
                                                    <Typography textAlign="left" fontWeight="light" fontSize={15}>Category: {item.category}</Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={6} sx={{ ...right_flex_box, flexDirection: "column" }} >
                                                <Typography >{item.price} EGP</Typography>
                                                <Typography >{item.quantity} ticket(s)</Typography>
                                                <Button sx={{ ...sec_button }} size='small' startIcon={<DeleteIcon />} onClick={() => removeFromCart({ itemId: item._id })}>Remove</Button>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            )
                        })}
                        {cart?.items?.length === 0 && <>
                            <Box sx={{ ...centered_flex_box, flexDirection: "column", mt: 2 }}>
                                <InboxIcon fontSize="large" />
                                <Typography fontSize={40}>Cart Is Empty</Typography>
                            </Box>
                        </>}
                    </>}
                    <Grid container spacing={1} sx={{ mt: 2, ...centered_flex_box }}>
                        <Grid item xs={12}>
                            <Typography fontWeight="bold" fontSize={isMobile ? 18 : 25}>Subtotal: {cart?.totalPrice} EGP</Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ ...right_flex_box }}>
                            <Button sx={{ ...main_button }} startIcon={<PaymentIcon />} disabled={cart?.totalPrice === 0} onClick={() => setCheckoutDialog(true)}>Buy Now</Button>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Container>
    );
}

const mapStateToProps = (state) => ({
    cart: state?.auth?.cart,
    isLoading: state?.auth?.cart?.isLoading || state?.wait?.isLoading
});

const mapDispatchToProps = { getCart, clearCart, removeFromCart, checkout };

export default connect(mapStateToProps, mapDispatchToProps)(Cart);