import * as React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Typography, Box, Container, TextField, CssBaseline, Button, Avatar, Select, MenuItem, FormHelperText, InputLabel, CircularProgress, FormControl, InputAdornment, IconButton } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { connect } from "react-redux";
import { editProfile } from '../../app/store/actions/authActions';
import { Navigate, NavLink, Route, Routes } from "react-router-dom";
import { centered_flex_box, SecInput, MainInputLabel, MainPassword, main_button, StyledInput, TerInput, sec_button } from '../../app/components/Styles';
import MuiPhoneNumber from 'material-ui-phone-number';

export const EditProfile = ({ editProfile, user, token, isLoading }) => {

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        var details = {
            email: data.get('email'),
            phoneNumber: data.get('phoneNumber'),
            newsletter: data.get('newsletter')
        }
        editProfile(details)
    };

    if (isLoading) {
        return (
            <Box sx={{ ...centered_flex_box }}>
                <CircularProgress sx={{ color: "var(--mainWhite)" }} />
            </Box>
        )
    }

    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    minHeight: "100%"
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'var(--secColor)' }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Edit Profile
                </Typography>
                <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
                    <TerInput
                        margin="normal"
                        fullWidth
                        value={user?.name}
                        readOnly
                        label="Full Name"
                        sx={{ my: 1 }}
                    />
                    <TerInput
                        margin="normal"
                        fullWidth
                        type="email"
                        defaultValue={user?.email}
                        name="email"
                        label="Email"
                        id="email"
                        sx={{ my: 1 }}
                    />
                    <MuiPhoneNumber
                        sx={{
                            "& label": {
                                color: "var(--appBg) !important",
                            },
                            "& label.Mui-focused": {
                                color: "var(--appBg) !important",
                            },
                            "& .MuiOutlinedInput-root": {
                                color: "var(--appBg) !important",
                                "& fieldset": {
                                    border: "1px solid var(--secPurple)",
                                },
                                "&.Mui-focused fieldset": {
                                    borderColor: "var(--appBg)",
                                },

                                borderRadius: 0.5,
                            },
                            my:1
                        }}
                        margin="normal"
                        fullWidth
                        id="phoneNumber"
                        label="Phone Number"
                        name="phoneNumber"
                        variant='outlined'
                        defaultCountry={'eg'}
                        preferredCountries={['eg', 'us']}
                        value={user?.phoneNumber}
                        countryCodeEditable={false}
                        inputProps={{
                            minLength: 13
                        }}
                        disableAreaCodes
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2, ...sec_button }}
                    >
                        Edit
                    </Button>
                </Box>
            </Box>
        </Container>
    );
}

const mapStateToProps = (state) => ({
    user: state?.auth?.user,
    isLoading: state?.auth?.isLoading,
    token: state?.auth?.token
});

const mapDispatchToProps = { editProfile };

export default connect(mapStateToProps, mapDispatchToProps)(EditProfile);