import * as React from 'react';
import { Typography, Box, Skeleton, Rating, Button, Chip, Grid, Avatar, Paper, IconButton } from '@mui/material';
import { connect } from "react-redux";
import VisibilityIcon from '@mui/icons-material/Visibility';
import QuizIcon from '@mui/icons-material/Quiz';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import { NavLink, useNavigate } from 'react-router-dom';
import { Card } from '@mui/material';
import { MainInput, SecInput, TerInput, card_style, centered_flex_box, left_flex_box, main_button, sec_button } from './Styles';
import moment from "moment"
import { isMobile } from 'react-device-detect';
import DownloadIcon from '@mui/icons-material/Download';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import QRCode from "react-qr-code";
import PaymentIcon from '@mui/icons-material/Payment';
import { downloadTicket, payTicketRequest } from '../store/actions/userActions';
import { acceptTicketRequest, fulfillTicketRequest, rejectTicketRequest } from '../store/actions/adminActions';
import ReactFileReader from 'react-file-reader';
import UploadIcon from '@mui/icons-material/Upload';
import { uploadFile } from 'react-s3';
import { config } from "../../app/config";
const { v4: uuidv4 } = require('uuid');

function RequestCard({ request, downloadTicket, token, acceptTicketRequest, rejectTicketRequest, fulfillTicketRequest, payTicketRequest, action, setAction }) {
    window.Buffer = window.Buffer || require("buffer").Buffer;
    const role = token.split(" ")[0]
    const navigate = useNavigate();
    const [showQr, setShowQr] = React.useState(false);
    const [showReceipt, setShowReceipt] = React.useState(false);
    const [showDetails, setShowDetails] = React.useState(false);
    const [showUploadReceipt, setShowUploadReceipt] = React.useState(false);
    const [showCashMethod, setShowCashMethod] = React.useState(false);
    const [receipt, setReceipt] = React.useState(null)

    function timeout(delay) {
        return new Promise(res => setTimeout(res, delay));
    }

    const handleAddReceipt = files => {
        let file = files[0]
        console.log(file)
        setReceipt(file)
    }

    const handleDeleteReceipt = () => {
        setReceipt(null);
    }

    const handlePayForRequest = async (ev) => {
        ev.preventDefault();
        const data = new FormData(ev.currentTarget);
        let imageData;
        if (receipt) {
            imageData = await uploadFile(receipt, { ...config, dirName: "receipts" })
        }
        payTicketRequest({ requestId: request._id, receipt: imageData?.location, instapayUser: data.get("instapayUser") })
        setAction(!action)
    }

    const handleAccept = async () => {
        acceptTicketRequest({ requestId: request._id })
        await timeout(500)
        setAction(!action)
    }

    const handleReject = async () => {
        rejectTicketRequest({ requestId: request._id })
        await timeout(500)
        setAction(!action)
    }

    const handleFulfill = async () => {
        fulfillTicketRequest({ requestId: request._id, ticketId: request.ticket._id })
        await timeout(500)
        setAction(!action)
    }

    return (
        <Box className={`ticket-style ${showQr || showReceipt || showUploadReceipt ? "ticket-bottom-style" : ""}`} sx={{ minWidth: isMobile ? "100%" : "80%", maxWidth: isMobile ? "100%" : "80%" }}>
            <Grid container>
                <Grid item xs={9}>
                    <Typography sx={{ fontSize: 26, fontWeight: "bold" }}>
                        {request.event.title}
                    </Typography>
                </Grid>
                <Grid item direction="row" alignItems="center" display="flex" justifyContent="flex-end" xs={3}>
                    <Chip sx={{ color: "var(--mainWhite)", borderColor: "var(--mainWhite)" }} label={request.status} variant="outlined" />
                </Grid>
            </Grid>
            <Box mt={2}>
                <Grid container>
                    <Grid item direction="column" justifyContent="center" display="flex" xs={6}>
                        <Typography sx={{ fontSize: 15, fontWeight: "bold" }}>
                            Ticket Holder: {request.userInfo?.name}
                        </Typography>
                        <Typography sx={{ fontSize: 15, fontWeight: "bold" }}>
                            Category: {request.category}
                        </Typography>
                        {(role && role === "User" && (request.status === "Accepted" || request.status === "Unseen")) &&
                            <Typography sx={{ fontWeight: "bold", fontSize: 22, mt: 1 }}>
                                Price: {request.price} EGP
                            </Typography>
                        }
                        {role && role === "User" && !request.receipt && request.status === "Accepted" && moment().isBefore(request.deadline) && <>
                            <Typography sx={{ fontWeight: "bold", fontSize: 22, mt: 1, color: "red" }}>
                                Request expires {moment(request.deadline).fromNow()}
                            </Typography>
                        </>}
                        {role && role === "User" && !request.receipt && request.status === "Accepted" && moment().isAfter(request.deadline) && <>
                            <Typography sx={{ fontWeight: "bold", fontSize: 22, mt: 1, color: "red" }}>
                                Request expired
                            </Typography>
                        </>}
                    </Grid>
                    <Grid item direction="row" alignItems="center" display="flex" justifyContent="flex-end" xs={6}>
                        {role && role === "User" && request.ticket && request.ticket.fulfilled && <>
                            <IconButton color='white' onClick={() => setShowQr(!showQr)}><QrCode2Icon /></IconButton>
                            <IconButton color='white' onClick={() => downloadTicket({ ticketId: request.ticket._id })}><DownloadIcon /></IconButton>
                        </>}
                        {role && role === "User" && !request.receipt && request.status === "Accepted" && moment().isBefore(request.deadline) && request.event.paymentMethod === "InstaPay" && <>
                            <IconButton color='white' onClick={() => setShowUploadReceipt(!showUploadReceipt)}><PaymentIcon /></IconButton>
                        </>}
                        {role && role === "User" && !request.receipt && request.status === "Accepted" && moment().isBefore(request.deadline) && request.event.paymentMethod === "Cash" && <>
                            <IconButton color='white' onClick={() => setShowCashMethod(!showCashMethod)}><PaymentIcon /></IconButton>
                        </>}
                    </Grid>
                </Grid>
            </Box>
            {role && (role === "Super" || role === "Admin") &&
                <Box mt={1}>
                    <hr />
                    <Box sx={{ ...centered_flex_box, justifyContent: "space-evenly" }}>
                        {request.status === "Unseen" && <Button onClick={handleAccept} sx={main_button}>Accept</Button>}
                        {request.status === "Unseen" && <Button onClick={handleReject} sx={main_button}>Reject</Button>}
                        {request.event.paymentMethod === "InstaPay" && request.receipt && <Button onClick={() => setShowReceipt(!showReceipt)} sx={main_button}>View Receipt</Button>}
                        <Button onClick={() => setShowDetails(!showDetails)} sx={main_button}>{showDetails ? "Hide" : "View"} Details</Button>
                        {request.status === "Accepted" && request.event.paymentMethod === "Cash" && <Button onClick={handleFulfill} sx={{ ...main_button, mt: 1 }}>Validate Ticket</Button>}
                        {/* {request.status === "Accepted" && (request.event.paymentMethod === "Cash" || request.event.paymentMethod === "InstaPay") && <Button onClick={handleFulfill} sx={{ ...main_button, mt: 1 }}>Extend deadline by 24 hours</Button>} */}
                    </Box>
                </Box>
            }
            {showUploadReceipt && (
                <Box mt={1}>
                    <hr style={{ "border-top": "dashed 5px" }} />
                    <Box component="form" onSubmit={handlePayForRequest} sx={{ ...centered_flex_box, flexDirection: "column" }}>
                        <Typography fontWeight="bold">Pay To:</Typography>
                        {request.event.paymentAddress.map((add) => {
                            return <Typography>{add}</Typography>
                        })}
                        <ReactFileReader multipleFiles={false} handleFiles={handleAddReceipt} fileTypes={[".jpeg", ".jpg", ".png", ".svg"]}>
                            <Button fullWidth sx={{ my: 1, ...sec_button }}><UploadIcon />Upload Receipt</Button>
                        </ReactFileReader>
                        {receipt && <Chip label={receipt.name} onDelete={handleDeleteReceipt} sx={{ color: "var(--secColor)", bgcolor: "var(--appBg)", mb: 1 }} />}
                        <TerInput
                            margin="normal"
                            required
                            fullWidth
                            id="instapayUser"
                            label="Payment Username"
                            name="instapayUser"
                            autoFocus
                        />
                        {request.status === "Accepted" && !request.receipt && request.event.paymentMethod === "InstaPay" && <Button type='submit' sx={sec_button}>Send Payment</Button>}
                    </Box>
                </Box>
            )}
            {showCashMethod && (
                <Box mt={1}>
                    <hr style={{ "border-top": "dashed 5px" }} />
                    <Box component="form" onSubmit={handlePayForRequest} sx={{ ...centered_flex_box, flexDirection: "column" }}>
                        <Typography fontSize={25} fontWeight="bold">Call Any of these numbers to pay in cash</Typography>
                        {request.event.paymentAddress.map((payment) => {
                            return (<Typography fontSize={20}>{payment}</Typography>)
                        })}
                    </Box>
                </Box>
            )}
            {showReceipt && (
                <Box mt={1}>
                    <hr style={{ "border-top": "dashed 5px" }} />
                    <Box sx={{ ...centered_flex_box, flexDirection: "column" }}>
                        <Typography>InstaPay Username: {request.instapayUser}</Typography>
                        <img width={"300px"} src={request.receipt} alt="Receipt" />
                        {request.status === "Accepted" && request.receipt && request.event.paymentMethod === "InstaPay" && <Button onClick={handleFulfill} sx={{ ...main_button, mt: 1 }}>Validate Ticket</Button>}
                    </Box>
                </Box>
            )}
            {showDetails && (
                <Box mt={1}>
                    <hr style={{ "border-top": "dashed 5px" }} />
                    <Box sx={{ ...left_flex_box, flexDirection: "column" }}>
                        {
                            request.userInfo && Object.keys(request.userInfo).map((data) => {
                                return (
                                    <Typography>{data === "phoneNumber" ? "Phone Number" : data}: {data.includes("Link") ? <NavLink className="a2" target="_blank" to={request.userInfo[data]}>{request.userInfo[data]}</NavLink> : request.userInfo[data]}</Typography>
                                )
                            })
                        }
                    </Box>
                </Box>
            )}
            {showQr && (
                <Box mt={1}>
                    <hr style={{ "border-top": "dashed 5px" }} />
                    <Box sx={centered_flex_box}>
                        <QRCode
                            style={{ height: "auto", maxWidth: "70%", width: "70%" }}
                            fgColor="var(--mainWhite)"
                            bgColor="var(--primaryPurple)"
                            value={request.ticket._id}
                        />
                    </Box>
                </Box>
            )}
        </Box>
    )
}

const mapStateToProps = (state) => ({
    token: state?.auth?.token
});

const mapDispatchToProps = { downloadTicket, acceptTicketRequest, rejectTicketRequest, fulfillTicketRequest, payTicketRequest };

export default connect(mapStateToProps, mapDispatchToProps)(RequestCard);