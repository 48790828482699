import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import HomeIcon from '@mui/icons-material/Home';
import EventIcon from '@mui/icons-material/Event';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import LogoutIcon from '@mui/icons-material/Logout';
import LoginIcon from '@mui/icons-material/Login';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import InfoIcon from '@mui/icons-material/Info';
import ContactsIcon from '@mui/icons-material/Contacts';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import DraftsIcon from '@mui/icons-material/Drafts';
import BoltIcon from '@mui/icons-material/Bolt';
import EnhancedEncryptionIcon from '@mui/icons-material/EnhancedEncryption';
import { connect } from "react-redux";
import { logout } from '../store/actions/authActions';
import { useLocation, useNavigate } from 'react-router-dom';
import { Avatar, Button, Drawer, Tooltip } from '@mui/material';
import { centered_flex_box, left_flex_box, right_flex_box } from './Styles';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { UserLogin } from "../../Pages";
import StarsIcon from '@mui/icons-material/Stars';
import LoginModal from './LoginModal';
import Logo from "./tickit-white-transparent.png"
import { Image } from 'antd';

function MenuAppBar({ auth, logout }) {
    const navigate = useNavigate()
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [loginOpen, setLoginOpen] = React.useState(false);

    const token = auth.token || '';
    const header = token.split(' ')
    const role = header[0]
    const location = useLocation();

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        setAnchorEl(null);
        logout();
    };

    const handleHome = () => {
        setAnchorEl(null);
        navigate("/");
    };

    const handleProfile = () => {
        setAnchorEl(null);
        let path = "/" + role + "/profile";
        navigate(path);
    };

    const route = (path) => {
        setAnchorEl(null);
        navigate(path);
    }

    const menuItems = <>
        {role !== "Usher" && (<>
            {role === "Partner" ? <MenuItem onClick={() => route(`/Partner/events`)}><EventIcon /><Typography marginX={2}>Search Events</Typography></MenuItem> : <MenuItem onClick={() => route(`/events`)}><EventIcon /><Typography marginX={2}>Search Events</Typography></MenuItem>}
            {role === "Super" && <MenuItem onClick={() => route(`/Super/Partner/search`)}><PersonSearchIcon /><Typography marginX={2}>Search Partners</Typography></MenuItem>}
        </>)}
        {role === "User" && (<>
            <MenuItem onClick={() => route(`/${role}/tickets`)}><LocalActivityIcon /><Typography marginX={2}>My Tickets</Typography></MenuItem>
            <MenuItem onClick={() => route(`/${role}/requests`)}><DraftsIcon /><Typography marginX={2}>My Requests</Typography></MenuItem>
            {isMobile && <MenuItem onClick={() => route(`/${role}/cart`)}><ShoppingCartIcon /><Typography marginX={2}>Cart</Typography></MenuItem>}
        </>)}
        {(role === "Super" || role === "Admin") && (<>
            <hr />
            <MenuItem onClick={() => route(`/Admins/TicketRequests`)}><DraftsIcon /><Typography marginX={2}>View Ticket Requests</Typography></MenuItem>
            <MenuItem onClick={() => route(`/events/create`)}><AddCircleOutlineIcon /><Typography marginX={2}>Create Event</Typography></MenuItem>
        </>)}
        {(role === "Super") && (<>
            <MenuItem onClick={() => route(`/Super/create`)}><StarsIcon /><Typography marginX={2}>Create Super Admin</Typography></MenuItem>
            <MenuItem onClick={() => route(`/Super/Admin/create`)}><AdminPanelSettingsIcon /><Typography marginX={2}>Create Admin</Typography></MenuItem>
            <MenuItem onClick={() => route(`/Super/Partner/create`)}><SupervisedUserCircleIcon /><Typography marginX={2}>Create Partner Account</Typography></MenuItem>

        </>)}
        {role === "User" &&
            <>
                <hr />
                <MenuItem onClick={() => route(`/User/profile`)}><AccountCircle /><Typography marginX={2}>My Profile</Typography></MenuItem>
            </>
        }
        {isMobile && role !== "Usher" && (<>
            <hr />
            <MenuItem onClick={() => route(`/about`)}><InfoIcon /><Typography marginX={2}>About Us</Typography></MenuItem>
            <MenuItem onClick={() => route(`/contact`)}><ContactsIcon /><Typography marginX={2}>Contact Us</Typography></MenuItem>
        </>)}
        <hr />
        {role ?<>
            {role === "Super" && <MenuItem onClick={() => route(`/Super/ChangePassword`)} ><EnhancedEncryptionIcon /><Typography marginX={2}>Change Password</Typography></MenuItem>}
            {role === "Partrner" && <MenuItem onClick={() => route(`/Partrner/ChangePassword`)} ><EnhancedEncryptionIcon /><Typography marginX={2}>Change Password</Typography></MenuItem>}
            <MenuItem onClick={handleLogout} ><LogoutIcon color="error" /><Typography color="error" marginX={2}>Logout</Typography></MenuItem></> :
            <MenuItem onClick={() => route("/login")} ><LoginIcon color="success" /><Typography color="success" marginX={2}>Login</Typography></MenuItem>}
    </>

    return (
        <Box sx={{ flexGrow: 1, color: "var(--primaryColor)", marginBottom: 9 }}>
            <LoginModal open={loginOpen} handleClose={() => setLoginOpen(false)} />
            {(auth.user || !(location.pathname.includes("login") || location.pathname.includes("signup") || location.pathname.includes("password") || location.pathname.includes("verify"))) && (<>
                <BrowserView>
                    <AppBar sx={{ marginBottom: 2 }} position="fixed" color='primary'>
                        <Toolbar>
                            <Box class="position-relative w-100">
                                <Box class="d-flex flex-row position-absolute top-50 start-0 translate-middle-y">
                                    {role &&
                                        < IconButton
                                            size="large"
                                            edge="start"
                                            color="inherit"
                                            aria-label="menu"
                                            sx={{ mr: 2 }}
                                            onClick={handleMenu}
                                        >
                                            <MenuIcon />
                                        </IconButton>
                                    }
                                    <Box sx={left_flex_box}>
                                        <Image preview={false} src={Logo} height="50px" width="133px" onClick={handleHome} style={{ cursor: "pointer" }} />
                                    </Box>

                                    <Menu
                                        id="menu-appbar"
                                        anchorEl={anchorEl}
                                        keepMounted
                                        open={Boolean(anchorEl)}
                                        onClose={handleClose}
                                    >
                                        {menuItems}
                                    </Menu >
                                </Box>

                                <Box class="position-absolute top-50 start-50 translate-middle">
                                    <IconButton color='inherit' onClick={() => route(role === "Partner" ? "/Partner/events" : "/events")}><EventIcon /><Typography marginX={1} fontWeight={"bold"}>Events</Typography></IconButton><div class="vr" />
                                    {(!role || role === "User") && <>
                                        {role === "User" && <><IconButton color='inherit' onClick={() => route(`/${role}/tickets`)}><LocalActivityIcon /><Typography marginX={1} fontWeight={"bold"}>Tickets</Typography></IconButton><div class="vr" /></>}
                                        <IconButton color='inherit' onClick={() => route(`/about`)}><InfoIcon /><Typography marginX={1} fontWeight={"bold"}>About Us</Typography></IconButton><div class="vr" />
                                        <IconButton color='inherit' onClick={() => route(`/contact`)}><ContactsIcon /><Typography marginX={1} fontWeight={"bold"}>Contact Us</Typography></IconButton>
                                    </>}
                                    {(role === "Admin" || role === "Super") && <>
                                        <IconButton color='inherit' onClick={() => route(`/Admins/TicketRequests`)}><DraftsIcon /><Typography marginX={1} fontWeight={"bold"}>Ticket Requests</Typography></IconButton><div class="vr" />
                                        <IconButton color='inherit' onClick={() => route(`/Super/Partner/search`)}><SupervisedUserCircleIcon /><Typography marginX={1} fontWeight={"bold"}>Partners</Typography></IconButton>
                                    </>}
                                    {(role === "Partner") && <>
                                    <IconButton color='inherit' onClick={() => route(`/events/request`)}><BoltIcon /><Typography marginX={1} fontWeight={"bold"}>Host With Us</Typography></IconButton><div class="vr" />
                                        <IconButton color='inherit' onClick={() => route(`/about`)}><InfoIcon /><Typography marginX={1} fontWeight={"bold"}>About Us</Typography></IconButton><div class="vr" />
                                        <IconButton color='inherit' onClick={() => route(`/contact`)}><ContactsIcon /><Typography marginX={1} fontWeight={"bold"}>Contact Us</Typography></IconButton>
                                    </>}
                                </Box>

                                <Box class="position-absolute top-50 end-0 translate-middle-y">
                                    {role === "User" &&
                                        (
                                            <>
                                                <ShoppingCartIcon marginLeft={2} onClick={() => route(`/${role}/cart`)} sx={{ cursor: 'pointer' }} />
                                                <div class="vr mx-2" />
                                                <IconButton color='inherit' onClick={handleProfile}>
                                                    <AccountCircle /><Typography marginLeft={2} >{auth.user.name}</Typography>
                                                </IconButton>
                                            </>)
                                    }
                                    {role === "Partner" &&
                                        (
                                            <>
                                                <IconButton color='inherit' onClick={handleProfile}>
                                                    <SupervisedUserCircleIcon /><Typography marginLeft={2} >{auth.user.name}</Typography>
                                                </IconButton>
                                            </>)
                                    }
                                    {(role === "Admin" || role === "Super") &&
                                        (
                                            <HomeIcon onClick={() => route(`/${role}`)} sx={{ cursor: 'pointer' }} />
                                        )
                                    }
                                    {!role && (
                                        <IconButton color="inherit" onClick={() => navigate("/login")}><AccountCircle /><Typography marginX={2} fontWeight="bold">Login</Typography></IconButton>
                                    )}
                                </Box>
                            </Box>
                        </Toolbar >
                    </AppBar >
                </BrowserView>
                <MobileView>
                    <AppBar sx={{ marginBottom: 2 }} position="fixed" color='primary'>
                        <Toolbar>
                            <Box class="position-relative w-100">
                                <Box class="d-flex flex-row position-absolute top-50 start-0 translate-middle-y">
                                    <IconButton
                                        size="large"
                                        edge="start"
                                        color="inherit"
                                        aria-label="menu"
                                        sx={{ mr: 2 }}
                                        onClick={() => navigate(-1)}
                                    >
                                        <ArrowBackIcon />
                                    </IconButton>

                                    <React.Fragment key={"top"}>
                                        <Drawer
                                            anchor="top"
                                            open={Boolean(anchorEl)}
                                            onClose={handleClose}
                                        >
                                            {menuItems}
                                        </Drawer>
                                    </React.Fragment>
                                    <Box sx={left_flex_box}>
                                        <Image preview={false} src={Logo} height="30px" width="80px" onClick={handleHome} style={{ cursor: "pointer" }} />
                                    </Box>
                                </Box>
                                <Box class="position-absolute top-50 end-0 translate-middle-y">
                                    <Box sx={right_flex_box}>
                                        <IconButton
                                            size="large"
                                            edge="start"
                                            color="inherit"
                                            aria-label="menu"
                                            sx={{ mr: 2 }}
                                            onClick={handleMenu}
                                        >
                                            <MenuIcon />
                                        </IconButton>
                                    </Box>
                                </Box>
                            </Box>
                        </Toolbar >
                    </AppBar >
                </MobileView>
            </>)}
        </Box>
    );
}

const mapStateToProps = (state) => ({
    auth: state?.auth
});

const mapDispatchToProps = { logout };

export default connect(mapStateToProps, mapDispatchToProps)(MenuAppBar);