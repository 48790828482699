import * as React from "react";
import { TableRow, TableCell, Button, Modal, Typography, Box, TableContainer, Table, TableBody } from "@mui/material";
import { connect } from "react-redux";
import { centered_flex_box, main_button } from "../../../app/components/Styles";
import {
  fulfillTransfer,
  getOrders
} from "../../../app/store/actions/adminActions";
import ActionDialog from "../../../app/components/ActionDialog";
import moment from "moment";
import { Image } from "antd";

export const RequestRow = ({
  order,
  action,
  setAction,
  fulfillTransfer
}) => {
  const [fulfillDialog, setFulfillDialog] = React.useState(false);
  const [rejectDialog, setRejectDialog] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [dataOpen, setDataOpen] = React.useState(false);

  function timeout(delay) {
    return new Promise((res) => setTimeout(res, delay));
  }

  const handleReject = async (request) => {
    fulfillTransfer({ orderId: order._id, eventId: order.event._id, status: "Declined" });
    await timeout(500);
    setAction(!action);
  };

  const handleFulfill = async (request) => {
    fulfillTransfer({ orderId: order._id, eventId: order.event._id, status: "Successful" });
    await timeout(500);
    setAction(!action);
  };

  return (
    <>
      <TableRow
        key={order._id}
        sx={{
          "&:last-child td, &:last-child th": { border: 0 },
        }}
      >
        {/* <TableCell component="th" scope="row">
          {request._id}
        </TableCell> */}
        <TableCell align="left">{order.user.name}</TableCell>
        <TableCell align="left">{order.event.title}</TableCell>
        <TableCell align="left">{order.items.length}</TableCell>
        <TableCell align="left">{order.status}</TableCell>
        <TableCell align="left">
          <Button
            onClick={() => setDataOpen(true)}
            sx={{ ...main_button, m: 1 }}
          >
            View Data
          </Button>
        </TableCell>
        <TableCell align="left">
          {order.status === "Pending" && (<>

            <Button
              onClick={() => setOpen(true)}
              sx={{ ...main_button, m: 1 }}
            >
              View Receipt
            </Button>
            <Button
              onClick={() => setFulfillDialog(true)}
              sx={{ ...main_button, m: 1 }}
            >
              Validate Order
            </Button>
            <Button
              onClick={() => setRejectDialog(true)}
              sx={{ ...main_button, m: 1 }}
            >
              Reject
            </Button>
          </>)}
        </TableCell>
      </TableRow >
      <ActionDialog
        open={rejectDialog}
        handleClose={() => setRejectDialog(false)}
        handleAccept={handleReject}
        title={`Reject ${order.user.name}'s request?`}
        content={"Rejecting their request will notify them and delete their tickets if already issued. This action is irreversible"}
      />
      <ActionDialog
        open={fulfillDialog}
        handleClose={() => setFulfillDialog(false)}
        handleAccept={handleFulfill}
        title={`Fulfill ${order.user.name}'s request?`}
        content={"Validating their request will validate their tickets and send them by email. This action is irreversible"}
      />
      <Modal
        open={open}
        onClose={() => setOpen(false)}
      >
        <Box className="modal-style" sx={centered_flex_box}>
          <Box sx={{ ...centered_flex_box, flexDirection: "column", padding: 3 }}>
            <Typography fontWeight="bold" fontSize={24}>Payment Transfer Details</Typography>
            <Image width={"60%"} src={order.payment.receipt} />
            <Typography fontSize={22}>Username: {order.payment.paymentUser}</Typography>
          </Box>
        </Box>
      </Modal>
      <Modal
        open={dataOpen}
        onClose={() => setDataOpen(false)}
      >
        <Box className="modal-style" sx={centered_flex_box}>
          <Box sx={{ ...centered_flex_box, flexDirection: "column", padding: 3, maxHeight: "80vh", overflowY: "auto" }}>
            <Typography fontWeight="bold" fontSize={24}>Tickets Information</Typography>
            {order.items.map((ticket, idx) => {
              return (
                <Box sx={{maxHeight: "100%"}}>
                  <hr />
                  <Typography fontSize={22}>Ticket {idx + 1}</Typography>
                  <TableContainer>
                    <Table sx={{ minWidth: "80%" }} aria-label="simple table">
                      <TableBody>
                        {Object.keys(ticket.userInfo).map((info) => {
                          return (
                            <TableRow>
                              <TableCell>
                                <Typography sx={{ color: "var(--appBg)" }} fontSize={18}>{info === "phoneNumber" ? "Mobile" : info.charAt(0).toUpperCase() + info.slice(1) }</Typography>
                              </TableCell>
                              <TableCell>
                                <Typography sx={{ color: "var(--appBg)" }} fontSize={18}>{ticket.userInfo[info]}</Typography>
                              </TableCell>
                            </TableRow>
                          )
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              )
            })}
          </Box>
        </Box>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = {
  fulfillTransfer
};

export default connect(mapStateToProps, mapDispatchToProps)(RequestRow);
