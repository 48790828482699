import { WAIT, WAIT_SUCCESS, WAIT_FAIL, FETCH_RECORDS, FETCH_RECORDS_SUCCESS, FETCH_RECORDS_FAIL } from "./types";
import { getRequest, postRequest, putRequest } from "../../../core/network";
import endpoints from "../../../constants/endPoints.json";
import { notification } from "antd";
import download from "downloadjs";

export const sendMessage = (data, navigate) => (dispatch, useState) => {
    dispatch({ type: WAIT });

    postRequest(data, undefined, undefined, undefined, endpoints.common.contactUs)
        .then((response) => {
            const { data } = response;
            notification.success({message: data.message})
            navigate && navigate(-1);
            return dispatch({
                type: WAIT_SUCCESS,
            });
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: {marginTop: "10vh"} })
            console.log(err);
            return dispatch({
                type: WAIT_FAIL,
            });
        });
};

export const getTerms = () => (dispatch, useState) => {
    dispatch({ type: FETCH_RECORDS });

    getRequest(undefined, undefined, undefined, endpoints.common.terms)
        .then((response) => {
            const { data } = response;
            return dispatch({
                type: FETCH_RECORDS_SUCCESS,
                payload: data
            });
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: {marginTop: "10vh"} })
            console.log(err);
            return dispatch({
                type: FETCH_RECORDS_FAIL,
            });
        });
};

export const getPolicy = () => (dispatch, useState) => {
    dispatch({ type: FETCH_RECORDS });

    getRequest(undefined, undefined, undefined, endpoints.common.policy)
        .then((response) => {
            const { data } = response;
            return dispatch({
                type: FETCH_RECORDS_SUCCESS,
                payload: data
            });
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: {marginTop: "10vh"} })
            console.log(err);
            return dispatch({
                type: FETCH_RECORDS_FAIL,
            });
        });
};

export const getRefundPolicy = () => (dispatch, useState) => {
    dispatch({ type: FETCH_RECORDS });

    getRequest(undefined, undefined, undefined, endpoints.common.refund)
        .then((response) => {
            const { data } = response;
            return dispatch({
                type: FETCH_RECORDS_SUCCESS,
                payload: data
            });
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: {marginTop: "10vh"} })
            console.log(err);
            return dispatch({
                type: FETCH_RECORDS_FAIL,
            });
        });
};

export const getAbout = () => (dispatch, useState) => {
    dispatch({ type: FETCH_RECORDS });

    getRequest(undefined, undefined, undefined, endpoints.common.about)
        .then((response) => {
            const { data } = response;
            return dispatch({
                type: FETCH_RECORDS_SUCCESS,
                payload: data
            });
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: {marginTop: "10vh"} })
            console.log(err);
            return dispatch({
                type: FETCH_RECORDS_FAIL,
            });
        });
};

export const getInvitation = (data) => (dispatch) => {
    dispatch({ type: FETCH_RECORDS });

    getRequest(data, undefined, undefined, endpoints.common.invitation.get)
        .then((response) => {
            const { data } = response;
            return dispatch({
                type: FETCH_RECORDS_SUCCESS,
                payload: data
            });
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: {marginTop: "10vh"} })
            console.log(err);
            return dispatch({
                type: FETCH_RECORDS_FAIL,
            });
        });
};

export const acceptInvitation = (data) => (dispatch, useState) => {
    dispatch({ type: FETCH_RECORDS });

    postRequest(data, undefined, undefined, undefined, endpoints.common.invitation.accept)
        .then((response) => {
            const { data } = response;
            notification.success({message: data.message, style: {marginTop: "10vh"} })
            return dispatch({
                type: FETCH_RECORDS_SUCCESS,
                payload: data
            });
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: {marginTop: "10vh"} })
            console.log(err);
            return dispatch({
                type: FETCH_RECORDS_FAIL,
            });
        });
};

export const rejectInvitation = (data) => (dispatch, useState) => {
    dispatch({ type: FETCH_RECORDS });

    postRequest(data, undefined, undefined, undefined, endpoints.common.invitation.reject)
        .then((response) => {
            const { data } = response;
            notification.success({message: data.message, style: {marginTop: "10vh"} })
            return dispatch({
                type: FETCH_RECORDS_SUCCESS,
                payload: data
            });
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: {marginTop: "10vh"} })
            console.log(err);
            return dispatch({
                type: FETCH_RECORDS_FAIL,
            });
        });
};

export const downloadInvitationTicket = (data) => (dispatch, useState) => {
    dispatch({ type: WAIT });
    const token = useState()?.auth?.token

    getRequest({...data, responseType: "arraybuffer" }, undefined, token, endpoints.common.invitation.download)
        .then((response) => {
            download(response.data, response.headers["filename"], "application/pdf")
            return dispatch({
                type: WAIT_SUCCESS
            });
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message })
            console.log(err);
            return dispatch({
                type: WAIT_FAIL,
            });
        });
};