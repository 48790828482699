import * as React from 'react';
import { Typography, Tabs, Box, Container, TextField, CssBaseline, Button, Avatar, Select, MenuItem, InputLabel, FormHelperText, FormControl, CircularProgress, Chip, Grid, Card, Menu, Tab } from '@mui/material';
import { connect } from "react-redux";
import { useNavigate, useParams } from 'react-router-dom';
import ConstructionIcon from '@mui/icons-material/Construction';
import { centered_flex_box } from '../../app/components/Styles';
import ChangePassword from '../LoginPages/ChangePassword';
import { MobileView, BrowserView, isMobile } from "react-device-detect";
import GavelIcon from '@mui/icons-material/Gavel';
import EnhancedEncryptionIcon from '@mui/icons-material/EnhancedEncryption';
import Terms from '../StaticPages/Terms';

export const Profile = ({ }) => {

    const navigate = useNavigate()
    const eventId = useParams().id
    const [tab, setTab] = React.useState(0);

    const handleChange = (event, newValue) => {
        setTab(newValue)
    }

    return (
        <Container component="main">
            <Box p={2} sx={centered_flex_box}>
                <Card sx={{ width: isMobile ? "90%" : "80%" }}>
                    <Grid container>
                        <Grid item xs={3} sx={{ bgcolor: "var(--secColor2)" }}>
                            <BrowserView>
                                <Tabs
                                    value={tab}
                                    onChange={handleChange}
                                    variant="scrollable"
                                    orientation="vertical"
                                    scrollButtons={true}
                                >
                                    <Tab id="tab1" icon={<EnhancedEncryptionIcon />} label="Change Password" />\
                                    <Tab id="tab2" icon={<GavelIcon />} label="Terms & Conditions" />
                                </Tabs>
                            </BrowserView>
                            <MobileView>
                                <Tabs
                                    value={tab}
                                    onChange={handleChange}
                                    variant="scrollable"
                                    orientation="vertical"
                                    scrollButtons={true}
                                >
                                    <Tab id="tab1" icon={<EnhancedEncryptionIcon />} />
                                    <Tab id="tab2" icon={<GavelIcon />} />
                                </Tabs>
                            </MobileView>
                        </Grid>
                        <Grid item xs={9} sx={{bgcolor: "var(--secColor)"}}>
                            <Box sx={{ ...centered_flex_box, bgcolor: "var(--secColor)" }}>
                                {
                                    tab === 0 && <ChangePassword />
                                }
                                {
                                    tab === 1 && <Terms />
                                }
                            </Box>
                        </Grid>
                    </Grid>
                </Card>
            </Box>
        </Container>
    );
}

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);