import * as React from 'react';
import { Typography, Paper, IconButton, InputBase, Box, Container, Pagination, CircularProgress, Accordion, AccordionSummary, AccordionDetails, Button, Grid, FormControl, InputLabel, OutlinedInput, InputAdornment, Card, CardActionArea } from '@mui/material';
import { connect } from "react-redux";
import InboxIcon from '@mui/icons-material/Inbox';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import SearchIcon from '@mui/icons-material/Search';
import { centered_flex_box, MainInput, main_button, sec_button } from '../../app/components/Styles';
import moment from 'moment'
import { EventCard } from "../../app/components"
import { searchEvents } from '../../app/store/actions/eventActions';
import { BrowserView, isMobile, MobileView } from 'react-device-detect';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AiFillDelete } from 'react-icons/ai';

export const PartnerEvents = ({ events, searchEvents, user }) => {

    const initialState = {
        validEvents: user.events,
        search: "",
        page: 1,
        limit: 10
    }

    React.useEffect(() => {
        searchEvents(initialState)
    }, [])

    const [formData, setFormData] = React.useState(initialState)

    const { search, page } = formData

    const handlePageChange = (event, value) => {
        setFormData({ ...formData, page: value })
        searchEvents({ ...formData, page: value })
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value })
    }

    const handleSearchFilter = (event) => {
        searchEvents(formData)
    }

    return (
        <Container component="main" maxWidth="xl">
            <BrowserView>
                <Grid container sx={{ justifyContent: "center" }}>
                    <Grid item>
                        <MainInput
                            id="search"
                            name="search"
                            fullWidth
                            value={search}
                            onChange={handleChange}
                            label="Search for events"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={handleSearchFilter}
                                            edge="end"
                                        >
                                            <SearchIcon sx={{ color: "var(--secPurple)" }} />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                </Grid>
            </BrowserView>
            <hr />
            <Box>
                {!events?.isLoading ? (
                    <Grid container sx={{ ...centered_flex_box, flexDirection: "row" }} spacing={isMobile ? 0 : 3}>
                        {events?.results?.docs?.map((event) => {
                            return (
                                <Grid item xs={isMobile ? 12 : 3} sx={centered_flex_box}>
                                    <EventCard event={event} />
                                </Grid>
                            )
                        })}
                        {events?.results?.docs?.length === 0 && (
                            <Grid item sx={{ ...centered_flex_box, flexDirection: "column", mt: 2 }}>
                                <InboxIcon fontSize="large" />
                                <Typography fontSize={40}>No results</Typography>
                            </Grid>
                        )}
                    </Grid>
                ) : (
                    <Box sx={centered_flex_box}>
                        <CircularProgress sx={{ color: "var(--secColor)" }} />
                    </Box>
                )}
                <Box sx={{ ...centered_flex_box, mx: 1, p: 1 }}>
                    <Pagination count={events?.results?.pages || 1} page={page} onChange={handlePageChange} />
                </Box>
            </Box>
        </Container>
    );
}

const mapStateToProps = (state) => ({
    user: state?.auth?.user,
    events: state?.events
});

const mapDispatchToProps = { searchEvents };

export default connect(mapStateToProps, mapDispatchToProps)(PartnerEvents);