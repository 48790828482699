import * as React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import DownloadIcon from '@mui/icons-material/Download';
import UploadIcon from '@mui/icons-material/Upload';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Typography, Box, Container, TextField, CssBaseline, Button, Avatar, Select, MenuItem, InputLabel, FormHelperText, FormControl, CircularProgress, Chip, Tooltip, Autocomplete } from '@mui/material';
import { connect } from "react-redux";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { uploadFile } from 'react-s3';
import { centered_flex_box, MainInput, MainInputLabel, MainTextArea, main_button, reject_button, sec_button, StyledInput } from '../../app/components/Styles';
import { useNavigate, useParams } from 'react-router-dom';
import ReactFileReader from 'react-file-reader';
import { createEvent, searchEvents } from '../../app/store/actions/eventActions';
import { editPartner, getPartners } from '../../app/store/actions/adminActions';
import { config } from "../../app/config";
import moment from "moment"
import { Image } from 'antd';

export const EditPartner = ({ editPartner, getPartners, searchEvents, events, isLoading, partner }) => {
    window.Buffer = window.Buffer || require("buffer").Buffer;

    const navigate = useNavigate()
    const { id } = useParams()

    const [image, setImage] = React.useState(null);
    const [accountEvents, setAccountEvents] = React.useState([])

    React.useEffect(() => {
        searchEvents({ page: 1, limit: 100, search: "" })
        getPartners({ partnerId: id })
    }, [])

    React.useEffect(() => {
        if(partner) setAccountEvents(partner?.events?.map((event) => { return { label: event.title, id: event._id, byRequest: event.byRequest } }))
    }, [partner])

    const handleAddImage = files => {
        let file = files[0]
        console.log(file)
        setImage(file)
    }

    const handleDeleteImage = () => {
        setImage(null);
    }

    const handleEvents = (a, eventsList, c) => {
        setAccountEvents(eventsList)
    }

    const generatePermissions = () => {
        var items = []

        for (let i = 0; i < accountEvents.length; i++) {
            if (accountEvents[i].byRequest) {
                items.push(
                    <Box>
                        <hr />
                        <Typography fontWeight="bold">{accountEvents[i].label}</Typography>
                        <FormControlLabel control={<Checkbox defaultChecked={partner?.permissions && partner?.permissions[accountEvents[i].id]?.acceptRequests} name={`acceptRequests-${accountEvents[i].id}`} id={`acceptRequests-${accountEvents[i].id}`} />} label="Can Accept Requests" />
                        <FormControlLabel control={<Checkbox defaultChecked={partner?.permissions && partner?.permissions[accountEvents[i].id]?.rejectRequests} name={`rejectRequests-${accountEvents[i].id}`} id={`rejectRequests-${accountEvents[i].id}`} />} label="Can Reject Requests" />
                        <FormControlLabel control={<Checkbox defaultChecked={partner?.permissions && partner?.permissions[accountEvents[i].id]?.fulfillRequests} name={`fulfillRequests-${accountEvents[i].id}`} id={`fulfillRequests-${accountEvents[i].id}`} />} label="Can Verify Requests" />
                    </Box>
                )
            }
        }
        return items
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        try {
            var imageData
            if (image) {
                isLoading = true;
                imageData = await uploadFile(image, { ...config, dirName: "partners" })
            }

            const eventsWithPermissions = accountEvents.filter((event) => event.byRequest).map((event) => { return event.id })

            var permissions = {}
            for (const eventId of eventsWithPermissions) {
                permissions[eventId] = {
                    acceptRequests: data.get(`acceptRequests-${eventId}`) === "on",
                    rejectRequests: data.get(`rejectRequests-${eventId}`) === "on",
                    fulfillRequests: data.get(`fulfillRequests-${eventId}`) === "on",
                }
            }

            const edits = {
                image: imageData?.location,
                name: data.get("name"),
                email: data.get("email"),
                description: data.get("description"),
                events: accountEvents.map((event) => { return event.id }),
                permissions
            }

            editPartner({edits, partnerId: id}, navigate)

        } catch (err) {
            console.log(err)
        }
    };

    if (isLoading) {
        return (
            <Box sx={{ ...centered_flex_box, minHeight: "100vh" }}>
                <CircularProgress sx={{ color: "var(--secColor)" }} />
            </Box>
        )
    }

    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    marginTop: -5,
                    paddingBottom: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }
                }
            >
                <Box
                    sx={{ ...centered_flex_box, mt: 2, flexDirection: "column", p: 5 }}
                >
                    <Typography
                        fontSize={30}
                        fontWeight="bold"
                        textAlign="center"
                        color={"var(--primaryPurple)"}
                    >
                        CREATE PARTNER ACCOUNT
                    </Typography>
                    <hr
                        style={{
                            width: "50%",
                            opacity: "100%",
                            "border-top": "solid var(--primaryPurple) 3px",
                        }}
                    />
                </Box>
                <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
                    <ReactFileReader multipleFiles={false} handleFiles={handleAddImage} fileTypes={[".jpeg", ".jpg", ".png", ".svg"]}>
                        <Button fullWidth sx={{ my: 1, ...main_button }}><UploadIcon /> Upload Account Image</Button>
                    </ReactFileReader>
                    {image && <Chip label={image.name} onDelete={handleDeleteImage} sx={{ color: "var(--appBg)", bgcolor: "var(--secColor)" }} />}
                    <MainInput
                        margin="normal"
                        required
                        fullWidth
                        id="name"
                        label="Partner Name"
                        defaultValue={partner?.name}
                        name="name"
                    />
                    <MainInput
                        margin="normal"
                        fullWidth
                        required
                        type='email'
                        id="email"
                        label="Partner Email"
                        defaultValue={partner?.email}
                        name="email"
                    />
                    <MainInput
                        margin="normal"
                        fullWidth
                        multiline
                        minRows={5}
                        name="description"
                        label="Partner Description"
                        defaultValue={partner?.description}
                        id="description"
                    />
                    <Autocomplete
                        disablePortal
                        id="events-combo"
                        options={events ? events.map((event) => { return { label: event.title, id: event._id, byRequest: event.byRequest } }) : []}
                        fullWidth
                        multiple
                        defaultValue={partner?.events?.map((event) => { return event.title })}
                        onChange={handleEvents}
                        renderInput={(params) => <MainInput {...params} label="Events Accessible" />}
                    />
                    {generatePermissions()}
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2, ...main_button }}
                    >
                        Edit
                    </Button>
                </Box>
            </Box>
        </Container >
    );
}


const mapStateToProps = (state) => ({
    user: state?.auth?.user,
    token: state?.auth?.token,
    isLoading: state?.events?.isLoading || state?.records?.isLoading || state?.wait?.isLoading,
    events: state?.events?.results?.docs,
    partner: state?.records?.results?.partner
});

const mapDispatchToProps = { searchEvents, editPartner, getPartners };

export default connect(mapStateToProps, mapDispatchToProps)(EditPartner);