import { NEW_EVENT_UPDATE, WEBSOCKET_CONNECT, WEBSOCKET_DISCONNECT, WEBSOCKET_SEND } from "../actions/types";

let socket = null;

// Middleware to handle WebSocket connections
export const websocketMiddleware = store => next => action => {
    switch (action.type) {
        case WEBSOCKET_CONNECT:
            if (socket !== null) {
                socket.close();
            }

            // Create a new WebSocket connection
            socket = new WebSocket(action.payload.url);

            // When the connection receives a message, dispatch a new action with the message payload
            socket.onmessage = event => {
                let message = event.data;
                if (message !== "Hello!") {
                    message = JSON.parse(message)
                    store.dispatch({ type: NEW_EVENT_UPDATE, payload: message });
                } else {
                    console.log("Hello!")
                }
            };

            // When the connection encounters an error, dispatch a new action with the error message
            socket.onerror = error => {
                store.dispatch({ type: 'WEBSOCKET_ERROR', payload: error.message });
            };

            break;
        case WEBSOCKET_DISCONNECT:
            if (socket !== null) {
                socket.close();
            }
            socket = null;
            break;
        case WEBSOCKET_SEND:
            if (socket !== null) {
                socket.send(JSON.stringify(action.payload.message));
            }
            break;
        default:
            break;
    }

    return next(action);
};