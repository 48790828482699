import * as React from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import InfoIcon from "@mui/icons-material/Info";
import {
  Typography,
  Box,
  CircularProgress,
} from "@mui/material";
import { connect } from "react-redux";
import {
  centered_flex_box,
} from "../../app/components/Styles";
import { useNavigate, useParams } from "react-router-dom";
import { downloadInvitationTicket } from "../../app/store/actions/commonActions";

export const DownloadTicket = ({ isLoading, isError, downloadInvitationTicket }) => {
  const navigate = useNavigate();
  const { id } = useParams();

  React.useEffect(() => {
    downloadInvitationTicket({
      ticketId: id
    })
  }, [])

  if (isLoading) {
    return (
      <Box sx={{ ...centered_flex_box, flexDirection: "column" }}>
        <CircularProgress sx={{ color: "var(--secColor)" }} />
        <Typography>Fetching Ticket...</Typography>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        marginTop: -5,
        paddingBottom: 8,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {
        isError ? <>
          <Box sx={{ ...centered_flex_box, mt: 2, flexDirection: "column", p: 5 }}>
            <Typography fontSize={30} fontWeight="bold" textAlign="center" color={"var(--primaryPurple)"}>Something Went Wrong</Typography>
            <hr style={{ width: "50%", opacity: "100%", "border-top": "solid var(--primaryPurple) 3px" }} />
          </Box>
        </> : <>
          <Box sx={{ ...centered_flex_box, mt: 2, flexDirection: "column", p: 5 }}>
            <Typography fontSize={30} fontWeight="bold" textAlign="center" color={"var(--primaryPurple)"}>Ticket Downloaded!</Typography>
            <hr style={{ width: "50%", opacity: "100%", "border-top": "solid var(--primaryPurple) 3px" }} />
          </Box>

          <Typography fontSize={25} textAlign="center" color={"var(--primaryPurple)"}>See you there</Typography>
        </>
      }

    </Box>
  );
};

const mapStateToProps = (state) => ({
  isLoading: state?.wait?.isLoading,
  isError: state?.wait?.isError
});

const mapDispatchToProps = { downloadInvitationTicket };

export default connect(mapStateToProps, mapDispatchToProps)(DownloadTicket);
