import * as React from "react";
import {
  Typography,
  Paper,
  IconButton,
  InputBase,
  Box,
  Container,
  Pagination,
  CircularProgress,
  Accordion,
  MenuItem,
  AccordionSummary,
  AccordionDetails,
  Button,
  Grid,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  Select,
  FormHelperText,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { connect } from "react-redux";
import InboxIcon from "@mui/icons-material/Inbox";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import SearchIcon from "@mui/icons-material/Search";
import {
  centered_flex_box,
  MainInput,
  MainInputLabel,
  main_button,
  sec_button,
  StyledInput,
} from "../../app/components/Styles";
import moment from "moment";
import { EventCard, RequestCard, TicketCard } from "../../app/components";
import { BrowserView, isMobile, MobileView } from "react-device-detect";
import {
  acceptTicketRequest,
  fulfillTicketRequest,
  generateRequestDetails,
  getTicketRequestsAdmin,
  rejectTicketRequest,
} from "../../app/store/actions/adminActions";
import { uploadFile } from "react-s3";
import { config } from "../../app/config";
import { payTicketRequest } from "../../app/store/actions/userActions";
import { useParams } from "react-router-dom";
import ActionDialog from "../../app/components/ActionDialog";
import RequestRow from "./components/RequestRow";

export const TicketRequests = ({
  token,
  requests,
  isLoading,
  getTicketRequestsAdmin,
  generateRequestDetails,
  acceptTicketRequest,
  fulfillTicketRequest,
  rejectTicketRequest,
}) => {
  const eventId = useParams().id;
  const role = token.split(" ")[0];

  const initialState = {
    status: "Unseen",
    search: "",
    eventId,
    page: 1,
    limit: role === "Admin" || role === "Super" ? 50 : 10,
  };

  const [action, setAction] = React.useState(false);

  React.useEffect(() => {
    getTicketRequestsAdmin(formData);
  }, [action]);

  const [formData, setFormData] = React.useState(initialState);

  const handleClearFilter = (event) => {
    setFormData(initialState);
    getTicketRequestsAdmin(initialState);
  };

  const { status, search, page } = formData;

  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [dialogs, setDialogs] = React.useState({});

  const handlePageChange = (event, value) => {
    setFormData({ ...formData, page: value });
    getTicketRequestsAdmin({ ...formData, page: value });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSearchFilter = (event) => {
    getTicketRequestsAdmin(formData);
  };

  return (
    <Container component="main" maxWidth="xl">
      <BrowserView>
        <Grid container sx={{ justifyContent: "space-between" }}>
          <Grid item>
            <FormControl sx={{ minWidth: "100%", mt: 1 }}>
              <MainInputLabel id="type-label" title="Status" />
              <Select
                margin="normal"
                fullWidth
                autoFocus
                labelId="type-label"
                id="status"
                label="Status"
                name="status"
                defaultValue="Unseen"
                onChange={handleChange}
              >
                <MenuItem value={"Accepted"}>Accepted</MenuItem>
                <MenuItem value={"Rejected"}>Rejected</MenuItem>
                <MenuItem value={"Unseen"}>Pending</MenuItem>
                <MenuItem value={"Purchased"}>Purchased</MenuItem>
                <MenuItem value={"Any"}>Any</MenuItem>
              </Select>
            </FormControl>

            <Button
              sx={{ ...main_button, margin: 1 }}
              onClick={handleSearchFilter}
            >
              Apply Filters
            </Button>
            <Button
              sx={{ ...main_button, margin: 1 }}
              onClick={handleClearFilter}
            >
              Clear Filters
            </Button>
          </Grid>
          <Grid item>
            <FormControl variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">
                Search for Event Requests
              </InputLabel>
              <OutlinedInput
                id="search"
                name="search"
                autoComplete="search"
                value={search}
                onChange={handleChange}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton onClick={handleSearchFilter} edge="end">
                      <SearchIcon sx={{ color: "var(--secPurple)" }} />
                    </IconButton>
                  </InputAdornment>
                }
                label="Search for Event Requests"
              />
            </FormControl>
          </Grid>
        </Grid>
      </BrowserView>
      <MobileView>
        <Box sx={centered_flex_box}>
          <Paper
            sx={{
              p: "2px 4px",
              display: "flex",
              alignItems: "center",
              width: "80%",
            }}
          >
            <IconButton
              sx={{ p: "10px" }}
              aria-label="menu"
              type="button"
              onClick={() => setDrawerOpen(!drawerOpen)}
            >
              <FilterAltIcon color="white" />
            </IconButton>
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search For Event Requests"
              name="search"
              id="search"
              className="mobile-search"
              value={search}
              onChange={handleChange}
            />
            <IconButton
              sx={{ p: "10px" }}
              aria-label="search"
              onClick={handleSearchFilter}
            >
              <SearchIcon color="white" />
            </IconButton>
          </Paper>
        </Box>
        {drawerOpen && (
          <Grid
            container
            direction="row"
            display="flex"
            justifyContent="space-evenly"
            alignItems="center"
          >
            <Grid item width="80%" display="flex-row" alignItems="center">
              <Accordion expanded={drawerOpen}>
                <AccordionSummary
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography fontWeight="bold">Filters</Typography>
                </AccordionSummary>

                <AccordionDetails>
                  <FormControl sx={{ minWidth: "100%", mt: 1 }}>
                    <MainInputLabel id="type-label" title="Status" />
                    <Select
                      margin="normal"
                      fullWidth
                      autoFocus
                      labelId="type-label"
                      id="status"
                      label="Status"
                      name="status"
                      defaultValue="Unseen"
                      onChange={handleChange}
                    >
                      <MenuItem value={"Accepted"}>Accepted</MenuItem>
                      <MenuItem value={"Rejected"}>Rejected</MenuItem>
                      <MenuItem value={"Unseen"}>Pending</MenuItem>
                      <MenuItem value={"Purchased"}>Purchased</MenuItem>
                      <MenuItem value={"Any"}>Any</MenuItem>
                    </Select>
                  </FormControl>

                  <Button
                    sx={{ ...sec_button, margin: 1 }}
                    onClick={handleSearchFilter}
                  >
                    Apply Filters
                  </Button>
                  <Button
                    sx={{ ...sec_button, margin: 1 }}
                    onClick={handleClearFilter}
                  >
                    Clear Filters
                  </Button>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
        )}
      </MobileView>
      <hr />
      <Box>
        {eventId && (
          <>
            <Box
              sx={{
                ...centered_flex_box,
                mt: 2,
                flexDirection: "column",
              }}
            >
              <Typography fontSize={30} fontWeight="bold" textAlign="center">
                Event Requests
              </Typography>
              <hr
                style={{
                  width: "5%",
                  opacity: "100%",
                  "border-top": "solid var(--primaryPurple) 3px",
                }}
              />
            </Box>
            <Box sx={{ ...centered_flex_box, mb: 2 }}>
              {requests?.docs?.length > 0 && (
                <Button
                  sx={main_button}
                  onClick={() => generateRequestDetails({ status, eventId })}
                >
                  Download ({requests?.total}) {status} Requests Info
                </Button>
              )}
            </Box>
          </>
        )}
        {!isLoading ? (
          role === "User" ? (
            <Grid
              container
              sx={{ ...centered_flex_box, flexDirection: "row" }}
              spacing={isMobile ? 2 : 3}
            >
              {requests?.docs?.map((request) => {
                return (
                  <Grid item xs={isMobile ? 12 : 6} sx={centered_flex_box}>
                    <RequestCard
                      request={request}
                      action={action}
                      setAction={setAction}
                    />
                  </Grid>
                );
              })}
              {requests?.docs?.length === 0 && (
                <Grid
                  item
                  sx={{ ...centered_flex_box, flexDirection: "column", mt: 2 }}
                >
                  <InboxIcon fontSize="large" />
                  <Typography fontSize={40}>No results</Typography>
                </Grid>
              )}
            </Grid>
          ) : (
            <TableContainer>
              <Table sx={{ minWidth: "80%" }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {/* <TableCell>Request Id</TableCell> */}
                    <TableCell align="left">Name</TableCell>
                    <TableCell align="left">Email</TableCell>
                    <TableCell align="left">Mobile</TableCell>
                    <TableCell align="left">Deadline</TableCell>
                    <TableCell align="left">Category</TableCell>
                    <TableCell align="left">Status</TableCell>
                    <TableCell align="left">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {requests?.docs?.map((request) => <RequestRow action={action} setAction={setAction} request={request}/>)}
                </TableBody>
              </Table>
            </TableContainer>
          )
        ) : (
          <Box sx={centered_flex_box}>
            <CircularProgress sx={{ color: "var(--secColor)" }} />
          </Box>
        )}
        <Box sx={{ ...centered_flex_box, mx: 1, p: 1 }}>
          <Pagination
            count={requests?.pages || 1}
            page={page}
            onChange={handlePageChange}
          />
        </Box>
      </Box>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  token: state?.auth?.token,
  requests: state?.records?.results?.requests,
  isLoading: state?.records?.isLoading,
});

const mapDispatchToProps = {
  getTicketRequestsAdmin,
  generateRequestDetails,
  acceptTicketRequest,
  fulfillTicketRequest,
  rejectTicketRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(TicketRequests);
