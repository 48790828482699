import * as React from "react";
import { TableRow, TableCell, Button, Box } from "@mui/material";
import { connect } from "react-redux";
import { centered_flex_box, main_button } from "../../../app/components/Styles";
import {
  resendTicket,
  deleteTicket,
} from "../../../app/store/actions/adminActions";
import ActionDialog from "../../../app/components/ActionDialog";
import moment from "moment";
import QRCode from "react-qr-code";
import DeleteIcon from "@mui/icons-material/Delete";
import SendIcon from '@mui/icons-material/Send';

export const RequestRow = ({
  ticket,
  action,
  setAction,
  deleteTicket,
  resendTicket,
}) => {
  const [deleteTicketDialog, setDeleteTicketDialog] = React.useState(false);
  const [showQrCode, setShowQrCode] = React.useState(false);

  function timeout(delay) {
    return new Promise((res) => setTimeout(res, delay));
  }

  const handleResend = async () => {
    resendTicket({ ticketId: ticket._id });
  };

  const handleDelete = async () => {
    deleteTicket({ ticketId: ticket._id });
    await timeout(500);
    setAction(!action);
  };

  return (
    <>
      <TableRow
        key={ticket._id}
        sx={{
          "&:last-child td, &:last-child th": { border: 0 },
        }}
      >
        {/* <TableCell component="th" scope="row">
          {request._id}
        </TableCell> */}
        <TableCell align="left">{ticket.userInfo.name}</TableCell>
        <TableCell align="left">{ticket.userInfo.email}</TableCell>
        <TableCell align="left">{ticket.category}</TableCell>
        <TableCell align="left">{ticket.validated ? "Entered" : (ticket.fulfilled ? "Valid" : "Invalid")}</TableCell>
        <TableCell align="left">
          <Button
            startIcon={<SendIcon />}
            onClick={handleResend}
            sx={{ ...main_button, m: 1 }}
          >
            Resend
          </Button>
          <Button
            startIcon={<DeleteIcon />}
            onClick={() => setDeleteTicketDialog(true)}
            sx={{ ...main_button, m: 1 }}
          >
            Delete
          </Button>
        </TableCell>
        <TableCell align="center">
          {showQrCode ? (
            <Box sx={{ ...centered_flex_box, flexDirection: "column" }}>
              <QRCode
                style={{ height: "auto", maxWidth: "50%", width: "50%" }}
                fgColor="var(--appBg)"
                bgColor="var(--primaryPurple)"
                value={ticket._id}
              />
              <Button
                onClick={() => setShowQrCode(false)}
                sx={{ ...main_button, m: 1 }}
              >
                Hide QR Code
              </Button>
            </Box>
          ) : (
            <Button
              onClick={() => setShowQrCode(true)}
              sx={{ ...main_button, m: 1 }}
            >
              Show QR Code
            </Button>
          )}
        </TableCell>
      </TableRow>
      <ActionDialog
        open={deleteTicketDialog}
        handleClose={() => setDeleteTicketDialog(false)}
        handleAccept={() => handleDelete(ticket)}
        title={`Delete ${ticket.userInfo.name}'s ticket?`}
        content={
          "Deleting their ticket will notify them. This action is irreversible"
        }
      />
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  resendTicket,
  deleteTicket,
};

export default connect(mapStateToProps, mapDispatchToProps)(RequestRow);
