import * as React from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import BoltIcon from "@mui/icons-material/Bolt";
import {
  Typography,
  Box,
  Container,
  TextField,
  CssBaseline,
  Button,
  Avatar,
  Select,
  MenuItem,
  InputLabel,
  FormHelperText,
  FormControl,
  CircularProgress,
  Chip,
  Tooltip,
} from "@mui/material";
import { connect } from "react-redux";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {
  centered_flex_box,
  MainInput,
  MainInputLabel,
  MainTextArea,
  main_button,
  reject_button,
  sec_button,
  StyledInput,
} from "../../app/components/Styles";
import { useNavigate, useParams } from "react-router-dom";
import { submitEventRequest } from "../../app/store/actions/userActions";

export const RequestEvent = ({ submitEventRequest, isLoading }) => {
  window.Buffer = window.Buffer || require("buffer").Buffer;

  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    try {
      const creation = {
        title: data.get("title"),
        description: data.get("description"),
        averagePrice: data.get("averagePrice"),
        date: data.get("date"),
        location: data.get("location"),
        capacity: data.get("capacity"),
        public: data.get("public") === "on",
        needsVerification: data.get("needsVerification") === "on",
        byRequest: data.get("byRequest") === "on",
        gatesOpenAt: data.get("gatesOpenAt"),
        locationURL: data.get("locationURL"),
        oraganizer: data.get("oraganizer"),
      };

      submitEventRequest({ creation: creation }, navigate);
    } catch (err) {
      console.log(err);
    }
  };

  if (isLoading) {
    return (
      <Box sx={{ ...centered_flex_box, minHeight: "100vh" }}>
        <CircularProgress sx={{ color: "var(--secColor)" }} />
      </Box>
    );
  }

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: -5,
          paddingBottom: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          sx={{ ...centered_flex_box, mt: 2, flexDirection: "column", p: 5 }}
        >
          <Typography
            fontSize={30}
            fontWeight="bold"
            textAlign="center"
            color={"var(--primaryPurple)"}
          >
            GET POWERED BY US
          </Typography>
          <hr
            style={{
              width: "50%",
              opacity: "100%",
              "border-top": "solid var(--primaryPurple) 3px",
            }}
          />
        </Box>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
          <MainInput
            margin="normal"
            required
            fullWidth
            id="title"
            label="Event Title"
            name="title"
          />
          <MainInput
            margin="normal"
            required
            fullWidth
            multiline
            minRows={5}
            name="description"
            label="Event Description"
            id="description"
          />
          <MainInput
            margin="normal"
            required
            type="number"
            id="capacity"
            label="Event Capacity"
            name="capacity"
            fullWidth
          />
          <MainInput
            margin="normal"
            type="number"
            id="averagePrice"
            label="Average Ticket Price"
            name="averagePrice"
            fullWidth
          />
          <MainInput
            margin="normal"
            fullWidth
            id="organizer"
            label="Event Organizer"
            name="organizer"
          />
          <MainInput
            margin="normal"
            type="datetime-local"
            required
            fullWidth
            id="date"
            label="Event Date"
            name="date"
            focused
          />
          <MainInput
            margin="normal"
            type="datetime-local"
            required
            fullWidth
            id="gatesOpenAt"
            label="Gates Open At"
            name="gatesOpenAt"
            focused
          />
          <MainInput
            margin="normal"
            required
            fullWidth
            id="location"
            label="Event Location"
            name="location"
          />
          <MainInput
            margin="normal"
            fullWidth
            type="url"
            id="locationURL"
            label="Google Maps URL"
            name="locationURL"
          />
          <hr />
          <FormControlLabel
            control={<Checkbox name="public" id="public" />}
            label="Public Event"
          />
          <br />
          <Tooltip title="Users cannot buy tickets directly and have to be accepted first">
            <FormControlLabel
              control={<Checkbox name="byRequest" id="byRequest" />}
              label="Requires acceptance from admin"
            />
          </Tooltip>
          <br />
          <hr />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2, ...main_button }}
          >
            Submit Request
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  user: state?.auth?.user,
  token: state?.auth?.token,
  isLoading: state?.events?.isLoading,
});

const mapDispatchToProps = { submitEventRequest };

export default connect(mapStateToProps, mapDispatchToProps)(RequestEvent);
