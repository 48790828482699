//Login
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGIN = "LOGIN";

//Wallet
export const WALLET_SUCCESS = "WALLET_SUCCESS";
export const WALLET_FAIL = "WALLET_FAIL";
export const WALLET = "WALLET";

//Cart 
export const CART_SUCCESS = "CART_SUCCESS";
export const CART_FAIL = "CART_FAIL";
export const CART = "CART";

//Logout
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT = "LOGOUT";

//Change user data
export const CHANGE_SUCCESS = "CHANGE_SUCCESS";
export const CHANGE_FAIL = "CHANGE_FAIL";
export const CHANGE = "CHANGE";

//Tickets
export const GRAB_TICKET_SUCCESS = "GRAB_TICKET_SUCCESS";
export const GRAB_TICKET_FAIL = "GRAB_TICKET_FAIL";
export const GRAB_TICKET = "GRAB_TICKET";

//Event
export const EVENT_SUCCESS = "EVENT_SUCCESS";
export const EVENT_FAIL = "EVENT_FAIL";
export const EVENT = "EVENT";

//General Purpose
export const WAIT_SUCCESS = "WAIT_SUCCESS";
export const WAIT_FAIL = "WAIT_FAIL";
export const WAIT = "WAIT";

export const FETCH_RECORDS_SUCCESS = "FETCH_RECORDS_SUCCESS";
export const FETCH_RECORDS_FAIL = "FETCH_RECORDS_FAIL";
export const FETCH_RECORDS = "FETCH_RECORDS";

//Websockets
export const NEW_EVENT_UPDATE = "NEW_EVENT_UPDATE";
export const WEBSOCKET_CONNECT = "WEBSOCKET_CONNECT";
export const WEBSOCKET_DISCONNECT = "WEBSOCKET_DISCONNECT";
export const WEBSOCKET_SEND = "WEBSOCKET_SEND";