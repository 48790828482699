

import * as React from 'react';
import { Modal, Typography, Box, Container, TextField, CssBaseline, Button, Avatar, Select, MenuItem, InputLabel, FormHelperText, FormControl, CircularProgress, Chip } from '@mui/material';
import { connect } from "react-redux";
import { useNavigate, useParams } from 'react-router-dom';
import { notification } from 'antd';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { MainInputLabel, main_button, StyledInput, centered_flex_box, sec_button, confirm_button, reject_button, ter_button, MainInput, SecInput } from '../../../app/components/Styles';
import { createUsher, getUshers } from '../../../app/store/actions/adminActions';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';

export const AddUsherModal = ({ open, handleClose, event, wait, createUsher, getUshers, action, setAction }) => {

    function timeout(delay) {
        return new Promise( res => setTimeout(res, delay) );
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        var formData = new FormData(e.currentTarget)
        var info = {
            username: formData.get("username"),
            password: formData.get("password"),
            eventId: event._id,
            gate: formData.get("gate")
        }
        createUsher(info, handleClose)
        await timeout(500)
        setAction(!action)
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
        >
            <Box className="modal-style" sx={centered_flex_box}>
                {wait.isLoading && (
                    <Box sx={{ ...centered_flex_box, minHeight: "50vh" }}>
                        <CircularProgress sx={{ color: "var(--appBg)" }} />
                    </Box>
                )}
                {!wait.isLoading && (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Avatar sx={{ m: 1, bgcolor: 'var(--secColor)' }}>
                            <MeetingRoomIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Add Usher Gate
                        </Typography>
                        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
                            <SecInput
                                margin="normal"
                                required
                                fullWidth
                                id="username"
                                label="Username"
                                name="username"

                            />
                            <SecInput
                                margin="normal"
                                required
                                fullWidth
                                id="gate"
                                label="Assigned Gate"
                                name="gate"

                            />
                            <SecInput
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                id="password"
                            />
                            <Button
                                type="submit"
                                variant="contained"
                                sx={{ mt: 3, mr: 2, ...confirm_button }}
                            >
                                Add
                            </Button>
                            <Button
                                onClick={handleClose}
                                variant="contained"
                                sx={{ mt: 3, ml: 2, ...reject_button }}
                            >
                                Cancel
                            </Button>
                        </Box>

                    </Box>
                )}
            </Box>
        </Modal>
    );
}

const mapStateToProps = (state) => ({
    wait: state?.wait,
});

const mapDispatchToProps = { createUsher, getUshers };

export default connect(mapStateToProps, mapDispatchToProps)(AddUsherModal);