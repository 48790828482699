import * as React from "react";
import {
  Modal,
  Typography,
  Box,
  Container,
  TextField,
  CssBaseline,
  Button,
  Avatar,
  Select,
  MenuItem,
  InputLabel,
  FormHelperText,
  FormControl,
  CircularProgress,
  Chip,
} from "@mui/material";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { notification } from "antd";
import AccountCircle from "@mui/icons-material/AccountCircle";
import {
  MainInputLabel,
  main_button,
  centered_flex_box,
  sec_button,
  confirm_button,
  reject_button,
  ter_button,
  TerInput,
} from "../../../app/components/Styles";
import {
  generateTickets,
  generateTicketsQRs,
} from "../../../app/store/actions/adminActions";
import ReactFileReader from "react-file-reader";
import Upload from "@mui/icons-material/Upload";
import Download from "@mui/icons-material/Download";
import LocalActivityIcon from "@mui/icons-material/LocalActivity";
import download from "downloadjs";
import Papa, { parse } from "papaparse";
import PopperButton from "../../../app/components/PopperButton";

export const GenerateTicketsModal = ({
  open,
  handleClose,
  event,
  wait,
  generateTickets,
  generateTicketsQRs,
  action,
  setAction,
}) => {
  const [file, setFile] = React.useState(null);
  const [entrantNumber, setEntrantNumber] = React.useState(0);
  const [category, setCategory] = React.useState("");
  const id = useParams().id;

  const handleDeleteFile = () => {
    setFile(null);
  };

  function timeout(delay) {
    return new Promise((res) => setTimeout(res, delay));
  }

  const handleSubmit = (event) => {
    const { selected, index } = event;
    var reader = new FileReader();
    if (file) {
      reader.readAsText(file);
      reader.onload = async () => {
        try {
          const parsed = Papa.parse(reader.result, { header: true });
          const info = {
            eventId: id,
            data: parsed.data.filter(
              (info) => info.name !== ""
            ),
            category,
          };
          switch (index) {
            case 0:
              generateTickets(info);
              break;
            case 1:
              generateTickets({ ...info, sendMails: "true" });
              break;
            case 2:
              generateTicketsQRs(info);
              break;
            case 3:
              generateTicketsQRs({ ...info, sendMails: "true" });
              break;
            default:
              break;
          }
          await timeout(500);
          setEntrantNumber(parsed.data.length);
        } catch (err) {
          console.log(err);
        }
      };
    } else {
      notification.error({ message: "Please Upload a valid .csv file" });
    }
  };

  const handleFiles = (files) => {
    setFile(files[0]);
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box className="modal-style" sx={centered_flex_box}>
        {wait.isLoading && (
          <Box
            sx={{
              ...centered_flex_box,
              minHeight: "50vh",
              flexDirection: "column",
            }}
          >
            <CircularProgress sx={{ color: "var(--appBg)" }} />
            <Typography fontWeight="bold">
              Processing your request...
            </Typography>
          </Box>
        )}
        {!wait.isLoading && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "var(--secColor)" }}>
              <LocalActivityIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Generate tickets using data in .csv format
            </Typography>
            <Button
              fullWidth
              sx={{ my: 1, ...sec_button }}
              onClick={() =>
                download(
                  "https://tickit-media-bucket.s3.eu-west-3.amazonaws.com/files/tickets-sample.csv"
                )
              }
            >
              <Download /> CSV Sample
            </Button>
            <hr />
            <Box
              minWidth="100%"
              component="form"
              onSubmit={handleSubmit}
              sx={{ mt: 1 }}
            >
              <TerInput
                margin="normal"
                autoFocus
                fullWidth
                select
                labelId="num-label"
                id="category"
                label="Category"
                name="category"
                value={category}
                onChange={({ target }) => setCategory(target.value)}
                sx={{
                  color: "var(--appBg) !important",
                }}
              >
                {event?.categories?.map((cat) => (
                  <MenuItem value={cat.category}>
                    {cat.category} - Category
                  </MenuItem>
                ))}
              </TerInput>
              <Box minWidth="100%">
                <ReactFileReader handleFiles={handleFiles} fileTypes={".csv"}>
                  <Button fullWidth sx={{ my: 1, ...sec_button }}>
                    <Upload /> Upload .csv{" "}
                  </Button>
                </ReactFileReader>
                {file && <Chip label={file.name} onDelete={handleDeleteFile} />}
              </Box>
              <Box sx={{ mt: 3 }}>
                <PopperButton
                  sx={{ mt: 3, ml: 2, ...confirm_button }}
                  options={[
                    "Generate Full Tickets Only",
                    "Generate Full Tickets & Send Emails",
                    "Generate QR Codes Only",
                    "Generate QR Codes & Send Emails",
                  ]}
                  onClick={handleSubmit}
                />
                <Button
                  onClick={handleClose}
                  variant="contained"
                  sx={{ ml: 2, ...reject_button }}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  wait: state?.wait,
});

const mapDispatchToProps = { generateTickets, generateTicketsQRs };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GenerateTicketsModal);
