

import * as React from 'react';
import { Modal, Grid, Box, Container, TextField, CssBaseline, Button, Avatar, Select, MenuItem, InputLabel, FormHelperText, FormControl, CircularProgress, Chip } from '@mui/material';
import { connect } from "react-redux";
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { centered_flex_box, MainInput, MainPassword, SecInput, SecPassword } from './Styles';
import { loginUser } from '../store/actions/authActions';

export const LoginModal = ({ open, handleClose, event,  loginUser, user, token, isLoading  }) => {

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        var details = {
            email: data.get('email'),
            password: data.get('password')
        }
        loginUser(details)
        handleClose()
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
        >
            <Box className="modal-style" sx={centered_flex_box}>
                {isLoading && (
                    <Box sx={{ ...centered_flex_box, minHeight: "50vh" }}>
                        <CircularProgress sx={{ color: "var(--secColor)" }} />
                    </Box>
                )}
                {!isLoading && (
                    <Box
                        sx={{
                            paddingTop: 8,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <Avatar sx={{ m: 1, bgcolor: 'var(--secColor)' }}>
                           
                        </Avatar>
                        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
                            <SecInput
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Email"
                                name="email"
                                type="email"
                                autoComplete="email"
                                autoFocus
                            />
                            <SecPassword
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                            >
                                Log In
                            </Button>
                            <Grid container>
                                <Grid item xs={12}>
                                    <NavLink className="a2" to="/register">
                                        Create account
                                    </NavLink>
                                </Grid>
                                <Grid item xs={12}>
                                    <NavLink className="a2" to="/forgot-password">
                                        Forgot password?
                                    </NavLink>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                )}
            </Box>
        </Modal>
    );
}

const mapStateToProps = (state) => ({
    user: state?.auth?.user,
    isLoading: state?.auth?.isLoading,
    token: state?.auth?.token
});

const mapDispatchToProps = { loginUser };

export default connect(mapStateToProps, mapDispatchToProps)(LoginModal);