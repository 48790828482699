import { LOGIN, LOGIN_SUCCESS, LOGIN_FAIL, LOGOUT_SUCCESS, CHANGE, CHANGE_SUCCESS, CHANGE_FAIL, CART, CART_SUCCESS, CART_FAIL, WALLET, WALLET_SUCCESS, WALLET_FAIL, WAIT, WAIT_SUCCESS, WAIT_FAIL } from "./types";
import { getRequest, postRequest, putRequest } from "../../../core/network";
import endpoints from "../../../constants/endPoints.json";
import { notification } from "antd";

export const loadUser = () => async (dispatch, getState) => {
  dispatch({
    type: LOGIN,
  });
  let user = localStorage.getItem("TICKitUser");

  if (user) {
    user = JSON.parse(user);
    console.log("user", user);
    return dispatch({
      type: LOGIN_SUCCESS,
      payload: user,
      token: localStorage.getItem("TICKitToken")
    });
  } else {
    return dispatch({
      type: LOGIN_FAIL,
    });
  }
};

export const logout = () => async (dispatch, getState) => {
  return dispatch({
    type: LOGOUT_SUCCESS,
  });
};

export const signUp = (data, navigate) => (dispatch) => {
  dispatch({ type: WAIT });

  postRequest(data, undefined, undefined, undefined, endpoints.auth.signUp)
    .then((response) => {
      const { data } = response
      navigate(`/verify/${data.token}`)
    })
    .catch((err) => {
      notification.error({ message: err?.response?.data?.message, style: { marginTop: "10vh" } })
      console.log(err);
      return dispatch({
        type: WAIT_FAIL,
      });
    });
};

export const verifyAccount = (data) => (dispatch) => {
  dispatch({ type: LOGIN });

  postRequest(data, undefined, undefined, undefined, endpoints.auth.verifyAccount)
    .then((response) => {
      if (response.data.message === "Creation Success") {
        notification.success({ message: "Welcome!", style: { marginTop: "10vh" } })
      } else {
        notification.error({ message: response.data.message, style: { marginTop: "10vh" } })
      }
      const { data } = response;
      return dispatch({
        type: LOGIN_SUCCESS,
        payload: data.payload,
        token: data.token
      });
    })
    .catch((err) => {
      notification.error({ message: err?.response?.data?.message, style: { marginTop: "10vh" } })
      console.log(err);
      return dispatch({
        type: LOGIN_FAIL,
      });
    });
};

export const loginUser = (data) => (dispatch) => {
  dispatch({ type: LOGIN });

  const info = {
    email: data.email,
    password: data.password
  }

  postRequest(info, undefined, undefined, undefined, endpoints.auth.loginUser)
    .then((response) => {
      if (response.data.message === "Success") {
        notification.success({ message: "Welcome Back", style: { marginTop: "10vh" } })
      } else {
        notification.error({ message: response.data.message, style: { marginTop: "10vh" } })
      }
      const { data } = response;
      return dispatch({
        type: LOGIN_SUCCESS,
        payload: data.payload,
        token: data.token
      });
    })
    .catch((err) => {
      notification.error({ message: err?.response?.data?.message, style: { marginTop: "10vh" } })
      console.log(err);
      return dispatch({
        type: LOGIN_FAIL,
      });
    });
};

export const loginSuper = (data) => (dispatch) => {
  dispatch({ type: LOGIN });

  const info = {
    email: data.email,
    password: data.password
  }

  postRequest(info, undefined, undefined, undefined, endpoints.auth.loginSuper)
    .then((response) => {
      if (response.data.message === "Success") {
        notification.success({ message: "Welcome Back", style: { marginTop: "10vh" } })
      } else {
        notification.error({ message: response.data.message, style: { marginTop: "10vh" } })
      }
      const { data } = response;
      return dispatch({
        type: LOGIN_SUCCESS,
        payload: data.payload,
        token: data.token
      });
    })
    .catch((err) => {
      notification.error({ message: err?.response?.data?.message, style: { marginTop: "10vh" } })
      console.log(err);
      return dispatch({
        type: LOGIN_FAIL,
      });
    });
};

export const loginUsher = (data) => (dispatch) => {
  dispatch({ type: LOGIN });

  const info = {
    username: data.username,
    password: data.password
  }

  postRequest(info, undefined, undefined, undefined, endpoints.auth.loginUsher)
    .then((response) => {
      if (response.data.message === "Success") {
        notification.success({ message: "Welcome Back", style: { marginTop: "10vh" } })
      } else {
        notification.error({ message: response.data.message })
      }
      const { data } = response;
      return dispatch({
        type: LOGIN_SUCCESS,
        payload: data.payload,
        token: data.token
      });
    })
    .catch((err) => {
      notification.error({ message: err?.response?.data?.message, style: { marginTop: "10vh" } })
      console.log(err);
      return dispatch({
        type: LOGIN_FAIL,
      });
    });
};

export const loginPartner = (data) => (dispatch) => {
  dispatch({ type: LOGIN });

  const info = {
    email: data.email,
    password: data.password
  }

  postRequest(info, undefined, undefined, undefined, endpoints.auth.loginPartner)
    .then((response) => {
      if (response.data.message === "Success") {
        notification.success({ message: "Welcome Back", style: { marginTop: "10vh" } })
      } else {
        notification.error({ message: response.data.message, style: { marginTop: "10vh" } })
      }
      const { data } = response;
      return dispatch({
        type: LOGIN_SUCCESS,
        payload: data.payload,
        token: data.token
      });
    })
    .catch((err) => {
      notification.error({ message: err?.response?.data?.message, style: { marginTop: "10vh" } })
      console.log(err);
      return dispatch({
        type: LOGIN_FAIL,
      });
    });
};

export const changePassword = (data, navigate) => (dispatch, useState) => {
  dispatch({ type: CHANGE });
  const token = useState().auth?.token
  const role = token.split(" ")[0]
  var end;

  switch (role) {
    case "Admin": end = endpoints.admin.changePassword; break;
    case "Super": end = endpoints.super.changePassword; break;
    case "User": end = endpoints.user.changePassword; break;
    case "Partner": end = endpoints.partner.changePassword; break;
    default: break;
  }

  putRequest(data, undefined, undefined, token, end)
    .then((response) => {
      const { data } = response;
      notification.success({ message: "Password Changed Successfully", style: { marginTop: "10vh" } })
      return dispatch({
        type: CHANGE_SUCCESS,
        payload: data.payload,
        token: data.token
      });
    })
    .catch((err) => {
      notification.error({ message: err?.response?.data?.message, style: { marginTop: "10vh" } })
      console.log(err);
      return dispatch({
        type: CHANGE_FAIL,
      });
    });
};

export const editProfile = (data, navigate) => (dispatch, useState) => {
  dispatch({ type: CHANGE });
  const token = useState().auth?.token

  putRequest(data, undefined, undefined, token, endpoints.auth.editProfile)
    .then((response) => {
      const { data } = response;
      notification.success({ message: "Profile Edited Successfully", style: { marginTop: "10vh" } })
      return dispatch({
        type: CHANGE_SUCCESS,
        payload: data.payload,
        token: data.token
      });
    })
    .catch((err) => {
      notification.error({ message: err?.response?.data?.message, style: { marginTop: "10vh" } })
      console.log(err);
      return dispatch({
        type: CHANGE_FAIL,
      });
    });
};

export const getWallet = () => (dispatch, useState) => {
  dispatch({ type: WALLET });
  const token = useState().auth?.token

  getRequest(undefined, undefined, token, endpoints.user.wallet)
    .then((response) => {
      const { data } = response;
      return dispatch({
        type: WALLET_SUCCESS,
        payload: data,
      });
    })
    .catch((err) => {
      notification.error({ message: err?.response?.data?.message, style: { marginTop: "10vh" } })
      console.log(err);
      return dispatch({
        type: WALLET_FAIL,
      });
    });
};

export const getCart = () => (dispatch, useState) => {
  dispatch({ type: CART });
  const token = useState().auth?.token

  getRequest(undefined, undefined, token, endpoints.user.cart.get)
    .then((response) => {
      const { data } = response;
      return dispatch({
        type: CART_SUCCESS,
        payload: data,
      });
    })
    .catch((err) => {
      notification.error({ message: err?.response?.data?.message, style: { marginTop: "10vh" } })
      console.log(err);
      return dispatch({
        type: CART_FAIL,
      });
    });
};

export const addToCart = (data) => (dispatch, useState) => {
  dispatch({ type: CART });
  const token = useState().auth?.token

  putRequest(data, undefined, undefined, token, endpoints.user.cart.add)
    .then((response) => {
      const { data } = response;
      return dispatch({
        type: CART_SUCCESS,
        payload: data,
      });
    })
    .catch((err) => {
      notification.error({ message: err?.response?.data?.message, style: { marginTop: "10vh" } })
      console.log(err);
      return dispatch({
        type: CART_FAIL,
      });
    });
};

export const removeFromCart = (data) => (dispatch, useState) => {
  dispatch({ type: CART });
  const token = useState().auth?.token

  putRequest(data, undefined, undefined, token, endpoints.user.cart.remove)
    .then((response) => {
      const { data } = response;
      return dispatch({
        type: CART_SUCCESS,
        payload: data,
      });
    })
    .catch((err) => {
      notification.error({ message: err?.response?.data?.message, style: { marginTop: "10vh" } })
      console.log(err);
      return dispatch({
        type: CART_FAIL,
      });
    });
};

export const clearCart = () => (dispatch, useState) => {
  dispatch({ type: CART });
  const token = useState().auth?.token

  putRequest(undefined, undefined, undefined, token, endpoints.user.cart.clear)
    .then((response) => {
      const { data } = response;
      return dispatch({
        type: CART_SUCCESS,
        payload: data,
      });
    })
    .catch((err) => {
      notification.error({ message: err?.response?.data?.message, style: { marginTop: "10vh" } })
      console.log(err);
      return dispatch({
        type: CART_FAIL,
      });
    });
};

export const forgotPassword = (data, navigate) => (dispatch) => {
  dispatch({
    type: WAIT,
  })
  postRequest(data, undefined, undefined, undefined, endpoints.auth.forgotPassword)
    .then((response) => {
      const { data } = response;
      navigate && navigate("/login");
      return dispatch({
        type: WAIT_SUCCESS,
        payload: data,
      });
    })
    .catch((err) => {
      notification.error({ message: err?.response?.data?.message, style: { marginTop: "10vh" } })
      console.log(err);
      return dispatch({
        type: WAIT_FAIL,
      });
    });
}

export const resetPassword = (data, navigate) => (dispatch) => {
  dispatch({
    type: WAIT,
  })
  postRequest(data, undefined, undefined, undefined, endpoints.auth.resetPassword)
    .then((response) => {
      const { data } = response;
      navigate && navigate("/login");
      return dispatch({
        type: WAIT_SUCCESS,
        payload: data,
      });
    })
    .catch((err) => {
      notification.error({ message: err?.response?.data?.message, style: { marginTop: "10vh" } })
      console.log(err);
      return dispatch({
        type: WAIT_FAIL,
      });
    });
}