import { LOGIN, LOGIN_FAIL, LOGIN_SUCCESS, LOGOUT_SUCCESS, CHANGE, CHANGE_SUCCESS, CHANGE_FAIL, CART, CART_SUCCESS, CART_FAIL, WALLET, WALLET_SUCCESS, WALLET_FAIL } from "../actions/types";

const initialState = {
  user: null,
  token: null,
  wallet: null,
  cart: null,
  isLoading: false,
  isError: false,
};

export default function store(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CHANGE:
    case LOGIN:
      return {
        ...state,
        isLoading: true,
      };
    case CHANGE_SUCCESS:
    case LOGIN_SUCCESS:
      localStorage.setItem("TICKitUser", JSON.stringify(action.payload));
      localStorage.setItem("TICKitToken", action.token);
      return {
        ...state,
        user: payload,
        token: action.token,
        isLoading: false,
        isError: false,
      };
    case LOGIN_FAIL:
      localStorage.removeItem("TICKitUser");
      localStorage.removeItem("TICKitToken");
      localStorage.removeItem("TICKitCart");
      return {
        ...state,
        user: null,
        token: null,
        wallet: null,
        cart: null,
        isLoading: false,
        isError: true,
      };
    case CHANGE_FAIL:
      return {
        ...state,
        isLoading: false,
        isError: true,
      };
    case WALLET:
      return {
        ...state,
        wallet: { isLoading: true, isError: false }
      };
    case WALLET_SUCCESS:
      return {
        ...state,
        wallet: action.payload
      };
    case WALLET_FAIL:
      return {
        ...state,
        wallet: { isLoading: false, isError: true }
      };
    case CART:
      return {
        ...state,
        cart: { isLoading: true, isError: true }
      };
    case CART_SUCCESS:
      return {
        ...state,
        cart: action.payload
      };
    case CART_FAIL:
      return {
        ...state,
        cart: { isLoading: false, isError: true }
      };
    case LOGOUT_SUCCESS:
      localStorage.removeItem("TICKitUser");
      localStorage.removeItem("TICKitToken");
      return {
        ...state,
        user: null,
        token: null,
        wallet: null,
        cart: null,
        isLoading: false,
        isError: false,
      }
    default:
      return state;
  }
}