import * as React from 'react';
import { Typography, Button, Grid, IconButton, CardContent } from '@mui/material';
import { connect } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { Card } from '@mui/material';
import { centered_flex_box, left_flex_box, main_button } from './Styles';
import {AiFillPlusCircle} from "react-icons/ai";
import {AiFillMinusCircle} from "react-icons/ai"
import { isMobile } from 'react-device-detect';

const { v4: uuidv4 } = require('uuid');

function FAQCard({ question, answer, buttonData }) {
    const [expanded, setExpanded] = React.useState(false)
    const navigate = useNavigate()

    return (
        <Card sx={{ my: 1, borderRadius: 5, backgroundColor: "#F7F4FF !important", width:"80%" }}>
            <Grid container sx={{ p: 2, backgroundColor: "var(--appBg) !important", color: "var(--primaryPurple) !important", border: "solid 1px var(--primaryPurple) !important", borderLeft: "solid 20px var(--primaryPurple) !important", borderRadius: 5 }}>
                <Grid item xs={10} sx={left_flex_box}>
                    <Typography fontWeight="bold" fontSize={isMobile ? "15px":"25px"}>{question}</Typography>
                </Grid>
                <Grid item xs={2} sx={centered_flex_box}>
                    <IconButton onClick={() => setExpanded(!expanded)}>{expanded ? <AiFillMinusCircle size={30} color='#5F35B8' /> : <AiFillPlusCircle size={30} color='#5F35B8' />}</IconButton>
                </Grid>
            </Grid>
            {expanded &&
                <CardContent sx={{backgroundColor: "#F7F4FF", color: "var(--primaryPurple) !important", p:2 }}>
                    <Typography fontSize="16px" textAlign="justify">
                        {answer}
                    </Typography>
                    {buttonData && <Button onClick={() => navigate(buttonData.navigate)} sx={{ mt: 1, ...main_button }}>{buttonData.text}</Button>}
                </CardContent>
            }
        </Card>
    )
}

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(FAQCard);