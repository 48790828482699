import { EVENT, EVENT_SUCCESS, EVENT_FAIL, NEW_EVENT_UPDATE } from "../actions/types";

const initialState = {
    results: [],
    isLoading: false,
    isError: false,
};

export default function store(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case EVENT:
            return {
                ...state,
                isLoading: true,
            };
        case EVENT_SUCCESS:
            return {
                ...state,
                results: payload,
                isLoading: false,
                isError: false,
            };
        case EVENT_FAIL:
            return {
                ...state,
                results: null,
                verify: null,
                isLoading: false,
                isError: true,
            };
        case NEW_EVENT_UPDATE:
            var newGates = state.results.gates?.map((gate) => {
                if (gate._id === payload.usher._id) return payload.usher
                else return gate
            })
            return {
                ...state,
                results: {
                    ...state.results,
                    event: payload.event,
                    gates: newGates
                },
                verify: null,
                isLoading: false,
                isError: true,
            };
        default:
            return state;
    }
}