import * as React from "react";
import {
  Typography,
  Box,
  Container,
  TextField,
  CssBaseline,
  Button,
  Avatar,
  Select,
  MenuItem,
  InputLabel,
  FormHelperText,
  FormControl,
  CircularProgress,
  Chip,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import InsightsIcon from "@mui/icons-material/Insights";
import { centered_flex_box } from "../../app/components/Styles";
import { connectWebSocket } from "../../app/store/actions/websocketActions";
import { getLiveEvent } from "../../app/store/actions/eventActions";
import moment from "moment";

export const LiveEvent = ({
  connectWebSocket,
  getLiveEvent,
  event,
  gates,
  isLoading,
}) => {
  const navigate = useNavigate();
  const eventId = useParams().id;
  const [total, setTotal] = React.useState(0);

  function subtotal(gates) {
    return gates
      ?.map(({ ticketsProcessed }) => ticketsProcessed)
      .reduce((sum, i) => sum + i, 0);
  }

  React.useEffect(() => {
    connectWebSocket();
    getLiveEvent({ eventId });
  }, []);

  React.useEffect(() => {
    setTotal(subtotal(gates));
  }, [gates]);

  if (isLoading) {
    return (
      <Box sx={{ ...centered_flex_box, minHeight: "100vh" }}>
        <CircularProgress sx={{ color: "var(--secColor)" }} />
      </Box>
    );
  }

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: -5,
          paddingBottom: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          sx={{ ...centered_flex_box, mt: 2, flexDirection: "column", p: 5 }}
        >
          <Typography fontSize={30} fontWeight="bold" textAlign="center">
            {event?.title} - Live Statistics
          </Typography>
          <hr
            style={{
              width: "50%",
              opacity: "100%",
              "border-top": "solid var(--primaryPurple) 3px",
            }}
          />
        </Box>
        <Box
          sx={{
            ...centered_flex_box,
            flexDirection: "column",
            width: "100vw",
            my: 2,
          }}
        >
          <Typography component="h1" variant="h5" fontWeight="bold">
            Gates Opened {moment(event?.gatesOpenAt).fromNow()}
          </Typography>
          <Typography component="h1" variant="h5" fontWeight="bold">
            {moment().isBefore(event?.date) ? "Event starts" : "Event Started"}{" "}
            {moment(event?.date).fromNow()}
          </Typography>
        </Box>
        <Box sx={{ mt: 1 }}>
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: "bold" }}>Username</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }} align="right">
                    Assigned Gate
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold" }} align="right">
                    Tickets Processed
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {gates?.map((row) => (
                  <TableRow
                    key={row._id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.username}
                    </TableCell>
                    <TableCell align="right">{row.gate}</TableCell>
                    <TableCell align="right">{row.ticketsProcessed}</TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell
                    sx={{ fontWeight: "bold" }}
                    align="right"
                    colSpan={2}
                  >
                    Total
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold" }} align="right">
                    {total}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  isLoading: state?.events?.isLoading,
  event: state?.events?.results?.event,
  gates: state?.events?.results?.gates,
});

const mapDispatchToProps = { connectWebSocket, getLiveEvent };

export default connect(mapStateToProps, mapDispatchToProps)(LiveEvent);
