import * as React from "react";
import {
  Typography,
  Paper,
  IconButton,
  InputBase,
  Box,
  Container,
  Pagination,
  CircularProgress,
  Accordion,
  MenuItem,
  AccordionSummary,
  AccordionDetails,
  Button,
  Grid,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  Select,
  FormHelperText,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { connect } from "react-redux";
import InboxIcon from "@mui/icons-material/Inbox";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import SearchIcon from "@mui/icons-material/Search";
import {
  centered_flex_box,
  MainInput,
  MainInputLabel,
  main_button,
  sec_button,
  StyledInput,
} from "../../app/components/Styles";
import moment from "moment";
import { EventCard, TicketCard } from "../../app/components";
import { BrowserView, isMobile, MobileView } from "react-device-detect";
import { getEventTickets } from "../../app/store/actions/adminActions";
import { useParams } from "react-router-dom";
import TicketRow from "./components/TicketRow";

export const Tickets = ({ tickets, getEventTickets }) => {
  const { id } = useParams();
  const [action, setAction] = React.useState(false)

  const initialState = {
    validated: "any",
    fulfilled: "any",
    refund: "any",
    generated: "any",
    eventId: id,
    page: 1,
    limit: 50,
  };

  React.useEffect(() => {
    getEventTickets(initialState);
  }, []);

  const [formData, setFormData] = React.useState(initialState);
  const { validated, generated, fulfilled, refund } = formData;

  const handleClearFilter = (event) => {
    setFormData(initialState);
    getEventTickets(initialState);
  };

  const { page } = formData;

  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const handlePageChange = (event, value) => {
    setFormData({ ...formData, page: value });
    getEventTickets({ ...formData, page: value });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSearchFilter = (event) => {
    getEventTickets(formData);
  };

  React.useEffect(() => {
    getEventTickets(formData);
  }, [action]);

  return (
    <Container component="main" maxWidth="xl">
      <BrowserView>
        <Grid container sx={{ justifyContent: "space-between" }}>
          <Grid item>
            <MainInput
              margin="normal"
              autoFocus
              fullWidth
              select
              labelId="num-label"
              id={`validated`}
              label="Validated"
              defaultValue={validated}
              name={`validated`}
              onChange={handleChange}
            >
              <MenuItem value={true}>Validated</MenuItem>
              <MenuItem value={false}>Unvalidated</MenuItem>
              <MenuItem value={"any"}>Any</MenuItem>
            </MainInput>

            <MainInput
              margin="normal"
              autoFocus
              fullWidth
              select
              labelId="num-label"
              id={`fulfilled`}
              label="Payment Complete"
              defaultValue={fulfilled}
              name={`fulfilled`}
              onChange={handleChange}
            >
              <MenuItem value={true}>Yes</MenuItem>
              <MenuItem value={false}>No</MenuItem>
              <MenuItem value={"any"}>Any</MenuItem>
            </MainInput>

            <MainInput
              margin="normal"
              autoFocus
              fullWidth
              select
              labelId="num-label"
              id={`refund`}
              label="Refunding"
              defaultValue={refund}
              name={`refund`}
              onChange={handleChange}
            >
              <MenuItem value={true}>Yes</MenuItem>
              <MenuItem value={false}>No</MenuItem>
              <MenuItem value={"any"}>Any</MenuItem>
            </MainInput>

            <MainInput
              margin="normal"
              autoFocus
              fullWidth
              select
              labelId="num-label"
              id={`generated`}
              label="Generated"
              defaultValue={generated}
              name={`generated`}
              onChange={handleChange}
            >
              <MenuItem value={true}>Yes</MenuItem>
              <MenuItem value={false}>No</MenuItem>
              <MenuItem value={"any"}>Any</MenuItem>
            </MainInput>

            <Button
              sx={{ ...main_button, margin: 1 }}
              onClick={handleSearchFilter}
            >
              Apply Filters
            </Button>
            <Button
              sx={{ ...main_button, margin: 1 }}
              onClick={handleClearFilter}
            >
              Clear Filters
            </Button>
          </Grid>
        </Grid>
      </BrowserView>
      <MobileView>
        <Box sx={centered_flex_box}>
          <Paper
            sx={{
              p: "2px 4px",
              display: "flex",
              alignItems: "center",
              width: "80%",
            }}
          >
            <IconButton
              sx={{ p: "10px" }}
              aria-label="menu"
              type="button"
              onClick={() => setDrawerOpen(!drawerOpen)}
            >
              <FilterAltIcon color="white" />
            </IconButton>
          </Paper>
        </Box>
        {drawerOpen && (
          <Grid
            container
            direction="row"
            display="flex"
            justifyContent="space-evenly"
            alignItems="center"
          >
            <Grid item width="80%" display="flex-row" alignItems="center">
              <Accordion expanded={drawerOpen}>
                <AccordionSummary
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography fontWeight="bold">Filters</Typography>
                </AccordionSummary>

                <AccordionDetails>
                  <MainInput
                    margin="normal"
                    autoFocus
                    fullWidth
                    select
                    labelId="num-label"
                    id={`validated`}
                    label="Validated"
                    defaultValue={validated}
                    name={`validated`}
                    onChange={handleChange}
                  >
                    <MenuItem value={true}>Validated</MenuItem>
                    <MenuItem value={false}>Unvalidated</MenuItem>
                    <MenuItem value={"any"}>Any</MenuItem>
                  </MainInput>

                  <MainInput
                    margin="normal"
                    autoFocus
                    fullWidth
                    select
                    labelId="num-label"
                    id={`fulfilled`}
                    label="Payment Complete"
                    defaultValue={fulfilled}
                    name={`fulfilled`}
                    onChange={handleChange}
                  >
                    <MenuItem value={true}>Yes</MenuItem>
                    <MenuItem value={false}>No</MenuItem>
                    <MenuItem value={"any"}>Any</MenuItem>
                  </MainInput>

                  <MainInput
                    margin="normal"
                    autoFocus
                    fullWidth
                    select
                    labelId="num-label"
                    id={`refund`}
                    label="Refunding"
                    defaultValue={refund}
                    name={`refund`}
                    onChange={handleChange}
                  >
                    <MenuItem value={true}>Yes</MenuItem>
                    <MenuItem value={false}>No</MenuItem>
                    <MenuItem value={"any"}>Any</MenuItem>
                  </MainInput>

                  <MainInput
                    margin="normal"
                    autoFocus
                    fullWidth
                    select
                    labelId="num-label"
                    id={`generated`}
                    label="Generated"
                    defaultValue={generated}
                    name={`generated`}
                    onChange={handleChange}
                  >
                    <MenuItem value={true}>Yes</MenuItem>
                    <MenuItem value={false}>No</MenuItem>
                    <MenuItem value={"any"}>Any</MenuItem>
                  </MainInput>

                  <Button
                    sx={{ ...sec_button, margin: 1 }}
                    onClick={handleSearchFilter}
                  >
                    Apply Filters
                  </Button>
                  <Button
                    sx={{ ...sec_button, margin: 1 }}
                    onClick={handleClearFilter}
                  >
                    Clear Filters
                  </Button>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
        )}
      </MobileView>
      <hr />
      <Box>
        {!tickets?.isLoading ? (
          <Grid
            container
            sx={{ ...centered_flex_box, flexDirection: "row" }}
            spacing={isMobile ? 2 : 3}
          >
            <TableContainer>
              <Table sx={{ minWidth: "80%" }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {/* <TableCell>Request Id</TableCell> */}
                    <TableCell align="left">Name</TableCell>
                    <TableCell align="left">Email</TableCell>
                    <TableCell align="left">Category</TableCell>
                    <TableCell align="left">Status</TableCell>
                    <TableCell align="left">Actions</TableCell>
                    <TableCell align="center">QR Code</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tickets?.results?.docs?.map((ticket) => (
                    <TicketRow
                      ticket={ticket}
                      action={action}
                      setAction={setAction}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            {tickets?.results?.docs?.length === 0 && (
              <Grid
                item
                sx={{ ...centered_flex_box, flexDirection: "column", mt: 2 }}
              >
                <InboxIcon fontSize="large" />
                <Typography fontSize={40}>No results</Typography>
              </Grid>
            )}
          </Grid>
        ) : (
          <Box sx={centered_flex_box}>
            <CircularProgress sx={{ color: "var(--secColor)" }} />
          </Box>
        )}
        <Box sx={{ ...centered_flex_box, mx: 1, p: 1 }}>
          <Pagination
            count={tickets?.results?.pages || 1}
            page={page}
            onChange={handlePageChange}
          />
        </Box>
      </Box>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  tickets: state?.records,
});

const mapDispatchToProps = { getEventTickets };

export default connect(mapStateToProps, mapDispatchToProps)(Tickets);
