import * as React from 'react';
import { Typography, Paper, IconButton, InputBase, Box, Container, Pagination, CircularProgress, Accordion, AccordionSummary, AccordionDetails, Button, Grid, FormControl, InputLabel, OutlinedInput, InputAdornment } from '@mui/material';
import { connect } from "react-redux";
import InboxIcon from '@mui/icons-material/Inbox';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import SearchIcon from '@mui/icons-material/Search';
import { centered_flex_box, MainInput, main_button, sec_button } from '../../app/components/Styles';
import moment from 'moment'
import { EventCard } from "../../app/components"
import { searchEvents } from '../../app/store/actions/eventActions';
import { BrowserView, isMobile, MobileView } from 'react-device-detect';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

export const SearchEvents = ({ events, searchEvents }) => {

    const initialState = {
        minDate: moment().format("yyyy-MM-DD"),
        maxDate: moment().add(1, 'year').format("yyyy-MM-DD"),
        search: "",
        page: 1,
        limit: 10
    }

    React.useEffect(() => {
        searchEvents(initialState)
    }, [])

    const [formData, setFormData] = React.useState(initialState)

    const handleClearFilter = (event) => {
        setFormData(initialState)
        searchEvents(initialState)
    }

    const { minDate, maxDate, search, page } = formData

    const [drawerOpen, setDrawerOpen] = React.useState(false)

    const handlePageChange = (event, value) => {
        setFormData({ ...formData, page: value })
        searchEvents({ ...formData, page: value })
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value })
    }

    const handleMinChange = (event) => {
        const value = moment(event.$d).format('yyyy/MM/DD')
        setFormData({ ...formData, minDate: value })
    }

    const handleMaxChange = (event) => {
        const value = moment(event.$d).format('yyyy/MM/DD')
        setFormData({ ...formData, maxDate: value })
    }

    const handleSearchFilter = (event) => {
        searchEvents(formData)
    }

    return (
        <Container component="main" maxWidth="xl">
            <BrowserView>
                <Grid container sx={{ justifyContent: "space-between" }}>
                    <Grid item>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer
                                components={[
                                    'DatePicker',
                                ]}
                            >
                                <DemoItem label="Earliest Date" component="DatePicker">
                                    <DatePicker
                                        name="minDate"
                                        id="minDate"
                                        onChange={handleMinChange}
                                        value={dayjs(minDate)}
                                        slotProps={{
                                            openPickerIcon: {
                                                sx: { color: "var(--secPurple)" }
                                            }
                                        }}
                                    />
                                </DemoItem>

                                <DemoItem label="Latest Date" component="DatePicker">
                                    <DatePicker
                                        name="maxDate"
                                        id="maxDate"
                                        onChange={handleMaxChange}
                                        value={dayjs(maxDate)}
                                        slotProps={{
                                            openPickerIcon: {
                                                sx: { color: "var(--secPurple)" }
                                            }
                                        }}

                                    />
                                </DemoItem>
                            </DemoContainer>
                        </LocalizationProvider>

                        <Button sx={{ ...main_button, margin: 1 }} onClick={handleSearchFilter}>Apply Filters</Button>
                        <Button sx={{ ...main_button, margin: 1 }} onClick={handleClearFilter}>Clear Filters</Button>
                    </Grid>
                    <Grid item>
                        <FormControl variant="outlined">
                            <InputLabel>Search for event</InputLabel>
                            <OutlinedInput
                                id="search"
                                name="search"
                                autoComplete="search"
                                value={search}
                                onChange={handleChange}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={handleSearchFilter}
                                            edge="end"
                                        >
                                            <SearchIcon sx={{ color: "var(--secPurple)" }} />
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label="Search for event"
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </BrowserView>
            <MobileView>
                <Box sx={centered_flex_box}>
                    <Paper
                        sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: "80%" }}
                    >
                        <IconButton sx={{ p: '10px' }} aria-label="menu" type="button" onClick={() => setDrawerOpen(!drawerOpen)}>
                            <FilterAltIcon color='white' />
                        </IconButton>
                        <InputBase
                            sx={{ ml: 1, flex: 1 }}
                            placeholder="Search For Events"
                            name="search"
                            id="search"
                            className='mobile-search'
                            value={search}
                            onChange={handleChange}
                        />
                        <IconButton sx={{ p: '10px' }} aria-label="search" onClick={handleSearchFilter}>
                            <SearchIcon color='white' />
                        </IconButton>
                    </Paper>
                </Box>
                {drawerOpen && (
                    <Grid container direction="row" display="flex" justifyContent="space-evenly" alignItems="center">
                        <Grid item width="80%" display="flex-row" alignItems="center">
                            <Accordion expanded={drawerOpen}>
                                <AccordionSummary
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography fontWeight="bold">Filters</Typography>
                                </AccordionSummary>

                                <AccordionDetails>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer
                                            components={[
                                                'DatePicker',
                                            ]}
                                        >
                                            <DemoItem label="Earliest Date" component="DatePicker">
                                                <DatePicker
                                                    name="minDate"
                                                    id="minDate"
                                                    onChange={handleMinChange}
                                                    value={dayjs(minDate)}
                                                    slotProps={{
                                                        openPickerIcon: {
                                                            sx: { color: "var(--appBg)" }
                                                        },
                                                        textField: {
                                                            sx: { color: "var(--appBg) !important" }
                                                        }
                                                    }}
                                                />
                                            </DemoItem>
                                            <br />
                                            <DemoItem label="Latest Date" component="DatePicker">
                                                <DatePicker
                                                    name="maxDate"
                                                    id="maxDate"
                                                    onChange={handleMaxChange}
                                                    value={dayjs(maxDate)}
                                                    slotProps={{
                                                        openPickerIcon: {
                                                            sx: { color: "var(--appBg) !important" }
                                                        },
                                                        textField: {
                                                            sx: { color: "var(--appBg) !important" }
                                                        }
                                                    }}
                                                />
                                            </DemoItem>
                                        </DemoContainer>
                                    </LocalizationProvider>
                                    <Button sx={{ ...sec_button, margin: 1 }} onClick={handleSearchFilter}>Apply Filters</Button>
                                    <Button sx={{ ...sec_button, margin: 1 }} onClick={handleClearFilter}>Clear Filters</Button>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                    </Grid>
                )}
            </MobileView>
            <hr />
            <Box>
                {!events?.isLoading ? (
                    <Grid container sx={{ ...centered_flex_box, flexDirection: "row" }} spacing={isMobile ? 0 : 3}>
                        {events?.results?.docs?.map((event) => {
                            return (
                                <Grid item xs={isMobile ? 12 : 3} sx={centered_flex_box}>
                                    <EventCard event={event} />
                                </Grid>
                            )
                        })}
                        {events?.results?.docs?.length === 0 && (
                            <Grid item sx={{ ...centered_flex_box, flexDirection: "column", mt: 2 }}>
                                <InboxIcon fontSize="large" />
                                <Typography fontSize={40}>No results</Typography>
                            </Grid>
                        )}
                    </Grid>
                ) : (
                    <Box sx={centered_flex_box}>
                        <CircularProgress sx={{ color: "var(--secColor)" }} />
                    </Box>
                )}
                <Box sx={{ ...centered_flex_box, mx: 1, p: 1 }}>
                    <Pagination count={events?.results?.pages || 1} page={page} onChange={handlePageChange} />
                </Box>
            </Box>
        </Container>
    );
}

const mapStateToProps = (state) => ({
    events: state?.events
});

const mapDispatchToProps = { searchEvents };

export default connect(mapStateToProps, mapDispatchToProps)(SearchEvents);