import * as React from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import LiveHelpIcon from "@mui/icons-material/LiveHelp";
import {
  Typography,
  Box,
  Container,
  TextField,
  CssBaseline,
  Button,
  Avatar,
  Select,
  MenuItem,
  InputLabel,
  FormHelperText,
  FormControl,
  CircularProgress,
  Chip,
  Tooltip,
  Card,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CardHeader,
  IconButton,
  CardContent,
} from "@mui/material";
import { connect } from "react-redux";
import {
  centered_flex_box,
  MainInput,
  MainInputLabel,
  MainTextArea,
  main_button,
  reject_button,
  sec_button,
  StyledInput,
  left_flex_box,
} from "../../app/components/Styles";
import { useNavigate, useParams } from "react-router-dom";
import PolicyIcon from "@mui/icons-material/Policy";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import { getPolicy } from "../../app/store/actions/commonActions";

export const Policy = ({ getPolicy, policy, isLoading }) => {
  const navigate = useNavigate();

  React.useEffect(() => {
    getPolicy();
  }, []);

  if (isLoading) {
    return (
      <Box sx={{ ...centered_flex_box }}>
        <CircularProgress sx={{ color: "var(--secColor)" }} />
      </Box>
    );
  }

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: -5,
          paddingBottom: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          sx={{ ...centered_flex_box, mt: 2, flexDirection: "column", p: 5 }}
        >
          <Typography
            fontSize={30}
            fontWeight="bold"
            textAlign="center"
            color={"var(--primaryPurple)"}
          >
            PRIVACY POLICY
          </Typography>
          <hr
            style={{
              width: "50%",
              opacity: "100%",
              "border-top": "solid var(--primaryPurple) 3px",
            }}
          />
        </Box>
        <Box
          sx={{ flexDirection: "column", width: "80vw", textAlign: "justify" }}
        >
          <ReactMarkdown>{policy}</ReactMarkdown>
          <Box>
            <Button onClick={() => navigate("/contact")} sx={main_button}>
              Contact Us
            </Button>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  policy: state?.records?.results,
  isLoading: state?.records?.isLoading,
});

const mapDispatchToProps = { getPolicy };

export default connect(mapStateToProps, mapDispatchToProps)(Policy);
