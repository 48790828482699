import * as React from 'react';
import Logo from "../../app/components/tickit-purple-transparent.png"
import { Typography, Box, Container, TextField, CssBaseline, Button, Avatar, Select, MenuItem, FormHelperText, InputLabel, CircularProgress, FormControl, InputAdornment, IconButton } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { connect } from "react-redux";
import { loginPartner } from '../../app/store/actions/authActions';
import { Navigate, NavLink, Route, Routes, useNavigate, useParams } from "react-router-dom";
import { centered_flex_box, MainInput, MainInputLabel, MainPassword, main_button, StyledInput, SecInput } from '../../app/components/Styles';
import { Image } from 'antd';

export const PartnerLogin = ({ loginPartner, user, token, isLoading }) => {

    const navigate = useNavigate()

    if (user) {
        var role = token.split(" ")[0]
        return (<Navigate to={`/${role}`} replace />)
    }
    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        var details = {
            email: data.get('email'),
            password: data.get('password')
        }
        loginPartner(details)
    };

    if (isLoading) {
        return (
            <Box sx={{ ...centered_flex_box }}>
                <CircularProgress sx={{ color: "var(--secColor)" }} />
            </Box>
        )
    }

    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    minHeight: "100%",
                    paddingTop: 3
                }}
            >
                <Image preview={false} src={Logo} height="60px" width="160px" />
                <Box sx={{ ...centered_flex_box, mt:1, flexDirection: "column"}}>
                    <Typography fontSize={30} fontWeight="bold" textAlign="center" color={"var(--primaryPurple)"}>PARTNER LOGIN</Typography>
                    <hr style={{ width: "50%", opacity: "100%", "border-top": "solid var(--primaryPurple) 3px" }} />
                </Box>
                <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
                    <MainInput
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        autoFocus
                    />
                    <MainPassword
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2, ...main_button }}
                    >
                        Log In
                    </Button>
                </Box>
            </Box>
        </Container>
    );
}

const mapStateToProps = (state) => ({
    user: state?.auth?.user,
    token: state?.auth?.token,
    isLoading: state?.auth?.isLoading,
});

const mapDispatchToProps = { loginPartner };

export default connect(mapStateToProps, mapDispatchToProps)(PartnerLogin);