import * as React from 'react';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Typography, Box, Container, TextField, CssBaseline, Button, Avatar, Select, MenuItem, InputLabel, FormHelperText, FormControl, CircularProgress, Chip, Tooltip, Checkbox } from '@mui/material';
import { connect } from "react-redux";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { uploadFile } from 'react-s3';
import { centered_flex_box, MainInput, MainInputLabel, MainPassword, MainTextArea, main_button, reject_button, sec_button, StyledInput } from '../../app/components/Styles';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { signUp } from '../../app/store/actions/authActions';

export const SignUp = ({ signUp, isLoading, user, token }) => {

    const navigate = useNavigate();
    const [error, setError] = React.useState(null);
    const [terms, setTerms] = React.useState(false)

    if (user) {
        navigate(`/${token.split(" ")[0]}`)
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        if (data.get("password") !== data.get("password-confirm")) {
            setError("Passwords Do Not Match")
        } else {
            const creation = {
                name: data.get("name"),
                email: data.get("email"),
                phoneNumber: data.get("phoneNumber"),
                password: data.get("password"),
            }

            signUp(creation);
        }
    };

    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    paddingTop: 8,
                    paddingBottom: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }
                }
            >
                <Avatar sx={{ m: 1, bgcolor: 'var(--secColor)' }}>
                    <AccountCircleIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Sign Up
                </Typography>
                <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
                    <MainInput
                        margin="normal"
                        required
                        fullWidth
                        id="name"
                        label="Full Name"
                        name="name"
                    />
                    <MainInput
                        margin="normal"
                        type="email"
                        required
                        fullWidth
                        id="email"
                        label="Email"
                        name="email"
                    />
                    <MainInput
                        margin="normal"
                        fullWidth
                        required
                        id="phoneNumber"
                        label="Phone Number"
                        name="phoneNumber"
                    />
                    <MainPassword
                        margin="normal"
                        sx={{ my: 1 }}
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                    />
                    <MainPassword
                        margin="normal"
                        sx={{ my: 1 }}
                        required
                        fullWidth
                        name="password-confirm"
                        label="Confirm Password"
                        type="password"
                        id="password-confirm"
                        error={error}
                    />
                    <Checkbox value={terms} onChange={() => setTerms(!terms)}/><NavLink to="/terms" target='_blank'>Read and Accept Terms & Conditions</NavLink>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        disabled={!terms}
                        sx={{ mt: 3, mb: 2, ...main_button }}
                    >
                        Sign Up
                    </Button>
                </Box>
            </Box>
        </Container >
    );
}


const mapStateToProps = (state) => ({
    user: state?.auth?.user,
    isLoading: state?.auth?.isLoading,
    token: state?.auth?.token
});

const mapDispatchToProps = { signUp };

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);