import * as React from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import InfoIcon from "@mui/icons-material/Info";
import {
  Typography,
  Box,
  CircularProgress,
  Button,
} from "@mui/material";
import { connect } from "react-redux";
import {
  centered_flex_box, confirm_button, reject_button,
} from "../../app/components/Styles";
import { useNavigate, useParams } from "react-router-dom";
import { acceptInvitation, getInvitation, rejectInvitation } from "../../app/store/actions/commonActions";
import ReactGA from "react-ga4";
import { Image } from "antd";

export const AcceptInvitation = ({ invitation, getInvitation, isLoading, isError, acceptInvitation, rejectInvitation }) => {
  const navigate = useNavigate();
  const { id } = useParams();

  React.useEffect(() => {
    getInvitation({
      invitationId: id
    })
  }, [])

  const handleAccept = () => {
    acceptInvitation({
      invitationId: id
    })
  }

  const handleReject = () => {
    navigate(`/invitation/reject/${id}`)
  }

  if (isLoading) {
    return (
      <Box sx={{ ...centered_flex_box, minHeight: "100vh", flexDirection: "column" }}>
        <CircularProgress sx={{ color: "var(--secColor)" }} />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        marginTop: -5,
        paddingBottom: 8,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {
        isError ? <>
          <Box sx={{ ...centered_flex_box, mt: 2, flexDirection: "column", p: 5 }}>
            <Typography fontSize={30} fontWeight="bold" textAlign="center" color={"var(--primaryPurple)"}>Something Went Wrong</Typography>
            <hr style={{ width: "50%", opacity: "100%", "border-top": "solid var(--primaryPurple) 3px" }} />
          </Box>
        </> : (invitation?.status === "Accepted" ? <>
          <Box sx={{ ...centered_flex_box, mt: 2, flexDirection: "column", p: 5 }}>
            <Typography fontSize={30} fontWeight="bold" textAlign="center" color={"var(--primaryPurple)"}>Invitation Accepted!</Typography>
            <hr style={{ width: "50%", opacity: "100%", "border-top": "solid var(--primaryPurple) 3px" }} />
          </Box>

          <Typography fontSize={25} textAlign="center" color={"var(--primaryPurple)"}>See you there</Typography>
        </> :
          <>
            <Box sx={{ ...centered_flex_box, flexDirection: "column", pt: 5 }}>
              <Image src={invitation?.event?.image} />
            </Box>
            <Box sx={{ ...centered_flex_box, flexDirection: "column" }}>
              <Typography fontSize={30} fontWeight="bold" textAlign="center" color={"var(--primaryPurple)"}>Event Terms & Conditions</Typography>
              <hr style={{ width: "50%", opacity: "100%", "border-top": "solid var(--primaryPurple) 3px" }} />
            </Box>
            <Box sx={{ ...centered_flex_box, flexDirection: "column", p: 5 }}>
              <Typography >{invitation?.event.terms}</Typography>
            </Box>

            {invitation?.event?.sponsorsImage && <Box sx={{ ...centered_flex_box, flexDirection: "column", pt: 5 }}>
              <Typography fontSize={20} fontWeight="bold" textAlign="center" color={"var(--primaryPurple)"}>Sponsored By</Typography>
              <Image src={invitation?.event?.sponsorsImage} />
            </Box>}
            <Box sx={{ mt: 3 }}>
              <Button
                onClick={handleAccept}
                variant="contained"
                sx={{ ml: 2, ...confirm_button }}
              >
                Accept
              </Button>
              <Button
                onClick={handleReject}
                variant="contained"
                sx={{ ml: 2, ...reject_button }}
              >
                Reject
              </Button>
            </Box>
          </>)
      }

    </Box>
  );
};

const mapStateToProps = (state) => ({
  isLoading: state?.wait?.isLoading || state?.records?.isLoading,
  isError: state?.wait?.isError || state?.records?.isError,
  invitation: state?.records?.results?.invitation
});

const mapDispatchToProps = { acceptInvitation, getInvitation, rejectInvitation };

export default connect(mapStateToProps, mapDispatchToProps)(AcceptInvitation);
