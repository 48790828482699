import * as React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import DownloadIcon from '@mui/icons-material/Download';
import UploadIcon from '@mui/icons-material/Upload';
import EditIcon from '@mui/icons-material/Edit';
import { Typography, Box, Container, TextField, CssBaseline, Button, Avatar, Select, MenuItem, InputLabel, FormHelperText, FormControl, CircularProgress, Chip, Tooltip } from '@mui/material';
import { connect } from "react-redux";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { uploadFile } from 'react-s3';
import { centered_flex_box, MainInput, MainInputLabel, MainTextArea, main_button, reject_button, sec_button, StyledInput } from '../../app/components/Styles';
import { useNavigate, useParams } from 'react-router-dom';
import ReactFileReader from 'react-file-reader';
import { editEvent, getEvent } from '../../app/store/actions/eventActions';
import { config } from "../../app/config";
import moment from 'moment';

export const EditEvent = ({ editEvent, getEvent, isLoading, isGetLoading, event }) => {
    window.Buffer = window.Buffer || require("buffer").Buffer;

    const id = useParams().id

    React.useEffect(() => {
        getEvent({ eventId: id })
    }, [])

    const navigate = useNavigate()
    const [file, setFile] = React.useState(null);


    const [extraInfo, setExtraInfo] = React.useState(event?.moreInfo?.length);
    const [categories, setCategories] = React.useState(event?.categories?.length);
    const [image, setImage] = React.useState(null);
    const [coverImage, setCoverImage] = React.useState(null);
    const [sponsorsImage, setSponsorsImage] = React.useState(null);
    const [chart, setChart] = React.useState(null);
    const [paymentMethod, setPaymentMethod] = React.useState(event?.paymentMethod);
    const [message, setMessage] = React.useState("");

    const handlePaymentMethod = (event) => {
        setPaymentMethod(event.target.value)
    }

    const handleAdd = (event) => {
        setExtraInfo(extraInfo + 1)
    }

    const handleRemove = (event) => {
        if (extraInfo > 0) {
            setExtraInfo(extraInfo - 1)
        }
    }

    const handleAddCat = (event) => {
        setCategories(categories + 1)
    }

    const handleRemoveCat = (event) => {
        if (categories > 1) {
            setCategories(categories - 1)
        }
    }

    const handleAddImage = files => {
        let file = files[0]
        console.log(file)
        setImage(file)
    }

    const handleDeleteImage = () => {
        setImage(null);
    }

    const handleAddCoverImage = files => {
        let file = files[0]
        console.log(file)
        setCoverImage(file)
    }

    const handleDeleteCoverImage = () => {
        setCoverImage(null);
    }

    const handleAddSponsorsImage = files => {
        let file = files[0]
        console.log(file)
        setSponsorsImage(file)
    }

    const handleDeleteSponsorsImage = () => {
        setSponsorsImage(null);
    }

    const handleAddChart = files => {
        let file = files[0]
        console.log(file)
        setChart(file)
    }

    const handleDeleteChart = () => {
        setChart(null);
    }

    const handleSubmit = async (ev) => {
        ev.preventDefault();
        const data = new FormData(ev.currentTarget);
        try {
            var imageData
            var coverImageData
            var sponsorsImageData
            var chartData
            var categoriesList = []
            var moreInfo = []
            if (image) {
                isLoading = true;
                setMessage("Uploading Event Image...");
                imageData = await uploadFile(image, { ...config, dirName: "images" })
                isLoading = false;
                setMessage("");
            }
            if (coverImage) {
                isLoading = true;
                setMessage("Uploading Cover Image...");
                coverImageData = await uploadFile(image, { ...config, dirName: "images" })
                isLoading = false;
                setMessage("");
            }
            if (sponsorsImage) {
                isLoading = true;
                setMessage("Uploading Sponsors Image...");
                sponsorsImageData = await uploadFile(image, { ...config, dirName: "images" })
                isLoading = false;
                setMessage("");
            }
            if (chart) {
                isLoading = true;
                setMessage("Uploading Seating Chart Image...");
                chartData = await uploadFile(chart, { ...config, dirName: "charts" })
                isLoading = false;
                setMessage("");
            }
            for (let i = 0; i < extraInfo; i++) {
                moreInfo.push(data.get(`req${i}`))
            }
            for (let i = 0; i < categories; i++) {
                categoriesList.push({ category: data.get(`cat${i}`), price: parseInt(data.get(`cat${i}Price`)), amount: parseInt(data.get(`cat${i}Tickets`)), sold: event?.categories[i] ? event.categories[i].sold : 0, available: data.get(`cat${i}Available`), sent: event?.categories[i] ? event.categories[i].sent : 0 })
            }

            const updates = {
                image: imageData?.location,
                coverImage: coverImageData?.location,
                sponsorsImage: sponsorsImageData?.location,
                title: data.get("title"),
                description: data.get("description"),
                terms: data.get("terms"),
                artist: data.get("artist"),
                date: data.get("date") === "" ? undefined : moment(data.get("date")),
                location: data.get("location"),
                seatChart: chartData?.location,
                categories: categoriesList,
                deadline: data.get("deadline") === "" ? undefined : moment(data.get("deadline")),
                public: data.get("public") === "on",
                moreInfo: moreInfo,
                needsVerification: data.get("needsVerification") === "on",
                byRequest: data.get("byRequest") === "on",
                gatesOpenAt: data.get("gatesOpenAt") === "" ? undefined : moment(data.get("gatesOpenAt")),
                locationURL: data.get("locationURL"),
                paymentMethod,
                paymentAddress: paymentMethod === "Cash" ? data.get("paymentAddress").split(",") : data.get("paymentAddress")
            }

            editEvent({ updates: updates, eventId: id }, navigate);
        } catch (err) {
            console.log(err)
        }
    };

    const generateRequirements = () => {
        var items = []
        for (let i = 0; i < extraInfo; i++) {
            items.push(
                <Box>
                    <MainInput
                        margin="normal"
                        required
                        fullWidth
                        id={`req${i}`}
                        label="Requirement"
                        name={`req${i}`}
                        defaultValue={event.moreInfo[i]}
                    />
                </Box>
            )
        }
        if (extraInfo > 0) {
            items.push(<Button onClick={handleRemove}>Remove</Button>)
        }
        return items
    }

    const generateCategories = () => {
        var items = []
        for (let i = 0; i < categories; i++) {
            items.push(
                <Grid container>
                    <Grid item xs={3}>
                        <MainInput
                            margin="normal"
                            required
                            id={`cat${i}`}
                            label="Category"
                            name={`cat${i}`}
                            defaultValue={event.categories[i]?.category}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <MainInput
                            margin="normal"
                            required
                            type="number"
                            id={`cat${i}Price`}
                            label="Price"
                            name={`cat${i}Price`}
                            defaultValue={event.categories[i]?.price}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <MainInput
                            margin="normal"
                            required
                            type="number"
                            id={`cat${i}Tickets`}
                            label="Number of Tickets"
                            name={`cat${i}Tickets`}
                            defaultValue={event.categories[i]?.amount}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <MainInput
                            margin="normal"
                            autoFocus
                            select
                            fullWidth
                            labelId='num-label'
                            id={`cat${i}Available`}
                            label="Available for Purchase?"
                            defaultValue={event.categories[i]?.available || true}
                            name={`cat${i}Available`}
                        >
                            <MenuItem value={true}>True</MenuItem>
                            <MenuItem value={false}>False</MenuItem>
                        </MainInput>
                    </Grid>
                </Grid>
            )
        }
        if (categories > 1) {
            items.push(<Button sx={{ ...reject_button, mr: 2 }} onClick={handleRemoveCat}>Remove</Button>)
        }
        return items
    }

    if (isGetLoading) {
        return (
            <Box sx={{ ...centered_flex_box, minHeight: "100vh" }}>
                <CircularProgress sx={{ color: "var(--secColor)" }} />
            </Box>
        )
    }

    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    marginTop: -5,
                    paddingBottom: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }
                }
            >
                <Box sx={{ ...centered_flex_box, mt: 2, flexDirection: "column", p: 5 }}>
                    <Typography fontSize={30} fontWeight="bold" textAlign="center">Edit Event</Typography>
                    <hr style={{ width: "50%", opacity: "100%", "border-top": "solid var(--primaryPurple) 3px" }} />
                </Box>
                <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
                    <ReactFileReader multipleFiles={false} handleFiles={handleAddImage} fileTypes={[".jpeg", ".jpg", ".png", ".svg"]}>
                        <Button fullWidth sx={{ my: 1, ...main_button }}><UploadIcon /> Upload Event Image</Button>
                    </ReactFileReader>
                    {image?.name && <Chip label={image.name} onDelete={handleDeleteImage} sx={{ color: "var(--terColor)", bgcolor: "var(--secColor)" }} />}
                    <ReactFileReader multipleFiles={false} handleFiles={handleAddCoverImage} fileTypes={[".jpeg", ".jpg", ".png", ".svg"]}>
                        <Button fullWidth sx={{ my: 1, ...main_button }}><UploadIcon /> Upload Cover Image</Button>
                    </ReactFileReader>
                    {coverImage?.name && <Chip label={coverImage.name} onDelete={handleDeleteCoverImage} sx={{ color: "var(--terColor)", bgcolor: "var(--secColor)" }} />}
                    <ReactFileReader multipleFiles={false} handleFiles={handleAddSponsorsImage} fileTypes={[".jpeg", ".jpg", ".png", ".svg"]}>
                        <Button fullWidth sx={{ my: 1, ...main_button }}><UploadIcon /> Upload Sponsors Image</Button>
                    </ReactFileReader>
                    {sponsorsImage?.name && <Chip label={sponsorsImage.name} onDelete={handleDeleteSponsorsImage} sx={{ color: "var(--terColor)", bgcolor: "var(--secColor)" }} />}
                    <MainInput
                        margin="normal"
                        required
                        fullWidth
                        id="title"
                        label="Event Title"
                        name="title"
                        defaultValue={event?.title}
                    />
                    <MainInput
                        margin="normal"
                        required
                        multiline
                        minRows={5}
                        fullWidth
                        name="description"
                        label="Event Description"
                        id="description"
                        defaultValue={event?.description}
                    />
                    <MainInput
                        margin="normal"
                        fullWidth
                        id="artist"
                        label="Event Artist"
                        name="artist"
                        defaultValue={event?.artist}
                    />
                    <MainInput
                        margin="normal"
                        type="datetime-local"
                        fullWidth
                        id="date"
                        label="Event Date"
                        name="date"
                        focused
                        defaultValue={moment(event?.date)}
                    />
                    <MainInput
                        margin="normal"
                        type="datetime-local"
                        fullWidth
                        id="deadline"
                        label="Purchase Deadline"
                        name="deadline"
                        focused
                        defaultValue={moment(event?.deadline)}
                    />
                    <MainInput
                        margin="normal"
                        type="datetime-local"
                        fullWidth
                        id="gatesOpenAt"
                        label="Gates Open At"
                        name="gatesOpenAt"
                        focused
                        defaultValue={moment(event?.gatesOpenAt)}
                    />
                    <MainInput
                        margin="normal"
                        required
                        fullWidth
                        id="location"
                        label="Event Location"
                        name="location"
                        defaultValue={event?.location}
                    />
                    <MainInput
                        margin="normal"
                        fullWidth
                        type="url"
                        id="locationURL"
                        label="Google Maps URL"
                        name="locationURL"
                        defaultValue={event?.locationURL}
                    />
                    <hr />
                    {generateRequirements()}
                    <Tooltip title={`Additional data entry requirements when purchasing tickets; \n ex: Instagram Link`}><Button sx={{ my: 1, ...main_button }} onClick={handleAdd}>Add Data Requirement</Button></Tooltip>
                    <hr />
                    <ReactFileReader multipleFiles={false} handleFiles={handleAddChart} fileTypes={[".jpeg", ".jpg", ".png", ".svg"]}>
                        <Button fullWidth sx={{ my: 1, ...main_button }}><UploadIcon /> Upload Seating Chart</Button>
                    </ReactFileReader>
                    {chart?.name && <Chip label={chart.name} onDelete={handleDeleteChart} />}
                    {generateCategories()}
                    <Button sx={{ my: 1, ...main_button }} onClick={handleAddCat}>Add Category</Button>
                    <hr />
                    <MainInput
                        margin="normal"
                        required
                        fullWidth
                        multiline
                        minRows={5}
                        name="terms"
                        label="Event Terms and Conditions"
                        id="terms"
                        defaultValue={event?.terms}
                    />
                    <MainInput
                        margin="normal"
                        autoFocus
                        fullWidth
                        select
                        labelId='num-label'
                        id={`paymentMethod`}
                        label="Payment Method"
                        defaultValue={event?.paymentMethod}
                        name={`paymentMethod`}
                        onChange={handlePaymentMethod}
                    >
                        <MenuItem value="Cash">Cash</MenuItem>
                        <MenuItem value="InstaPay">InstaPay</MenuItem>
                        <MenuItem value="FawryPay">FawryPay</MenuItem>
                        <MenuItem value="Paymob">Paymob</MenuItem>
                    </MainInput>
                    {(paymentMethod === "InstaPay" || paymentMethod === "Cash") &&
                        <MainInput
                            margin="normal"
                            required
                            fullWidth
                            type="text"
                            id="paymentAddress"
                            label="Payment Details (Instapay Address / Phone Numbers)"
                            name="paymentAddress"
                            defaultValue={event?.paymentAddress}
                        />}
                    <FormControlLabel control={<Checkbox defaultChecked={event?.public} name="public" id="public" />} label="Public Event" /><br />
                    <Tooltip title="Users cannot buy tickets directly and have to be accepted first"><FormControlLabel control={<Checkbox defaultChecked={event?.byRequest} name="byRequest" id="byRequest" />} label="Requires acceptance from admin" /></Tooltip><br />
                    <Tooltip title="Check if this event needs ushers to verify ticket holders name or id before entry"><FormControlLabel control={<Checkbox defaultChecked={event?.needsVerification} name="needsVerification" id="needsVerification" />} label="Needs verification on gate" /></Tooltip><br />
                    <hr />

                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2, ...main_button }}
                    >
                        Edit
                    </Button>
                </Box>
            </Box>
        </Container >
    );
}


const mapStateToProps = (state) => ({
    user: state?.auth?.user,
    token: state?.auth?.token,
    isLoading: state?.wait?.isLoading,
    isGetLoading: state?.events?.isLoading,
    event: state?.events?.results
});

const mapDispatchToProps = {
    editEvent,
    getEvent
};

export default connect(mapStateToProps, mapDispatchToProps)(EditEvent);