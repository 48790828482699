import * as React from "react";
import { TableRow, TableCell, Button, Card, CardContent, Box, Avatar, Typography, CardActionArea, IconButton } from "@mui/material";
import { connect } from "react-redux";
import { left_flex_box, main_button } from "../../../app/components/Styles";
import {
    deletePartner
} from "../../../app/store/actions/adminActions";
import ActionDialog from "../../../app/components/ActionDialog";
import moment from "moment";
import { AiFillDelete, AiFillEdit } from "react-icons/ai";

export const PartnerCard = ({
    partner,
    action,
    setAction,
    navigate,
    deletePartner
}) => {
    const [deleteDialog, setDeleteDialog] = React.useState(false);

    function timeout(delay) {
        return new Promise((res) => setTimeout(res, delay));
    }

    const handleDeletePartner = async (request) => {
        deletePartner(request);
        await timeout(500);
        setAction(!action);
    };

    return (
        <>
            <Card sx={{ p: 2, m: 2 }}>
                <CardContent>
                    <Box sx={left_flex_box}>
                        <Avatar src={partner.image} sx={{ marginRight: 2 }} />
                        <Typography fontWeight={"bold"} fontSize={20}>
                            {partner.name}
                        </Typography>
                    </Box>
                </CardContent>
                <CardContent>
                    <Typography textAlign="justify">
                        {partner.description}
                    </Typography>
                </CardContent>
                <CardActionArea>
                    <IconButton onClick={() => setDeleteDialog(true)}><AiFillDelete htmlColor='#ff0000' /></IconButton>
                    <IconButton onClick={() => navigate(`/Super/Partner/edit/${partner._id}`)}><AiFillEdit htmlColor='#ff3333' /></IconButton>
                </CardActionArea>
            </Card>
            <ActionDialog
                open={deleteDialog}
                handleClose={() => setDeleteDialog(false)}
                handleAccept={() => handleDeletePartner({ partnerId: partner._id })}
                title={`Delete ${partner.name}?`}
                content={"Deleting this partner is irreversible"}
            />
        </>
    );
};

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = {
    deletePartner
};

export default connect(mapStateToProps, mapDispatchToProps)(PartnerCard);
