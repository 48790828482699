import * as React from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import DownloadIcon from "@mui/icons-material/Download";
import UploadIcon from "@mui/icons-material/Upload";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import {
  Typography,
  Box,
  Container,
  TextField,
  CssBaseline,
  Button,
  Avatar,
  Select,
  MenuItem,
  InputLabel,
  FormHelperText,
  FormControl,
  CircularProgress,
  Chip,
  Tooltip,
} from "@mui/material";
import { connect } from "react-redux";
import OutboxIcon from "@mui/icons-material/Outbox";
import { uploadFile } from "react-s3";
import {
  centered_flex_box,
  MainInput,
  MainInputLabel,
  MainTextArea,
  main_button,
  reject_button,
  sec_button,
  StyledInput,
} from "../../app/components/Styles";
import { useNavigate, useParams } from "react-router-dom";
import ReactFileReader from "react-file-reader";
import { sendMessage } from "../../app/store/actions/commonActions";

export const Contact = ({ sendMessage, isLoading, user }) => {
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const info = {
      name: data.get("name") || "Anonymous",
      content: data.get("content"),
      email: data.get("email"),
    };

    sendMessage(info, navigate);
  };

  if (isLoading) {
    return (
      <Box sx={{ ...centered_flex_box, minHeight: "100vh" }}>
        <CircularProgress sx={{ color: "var(--secColor)" }} />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        marginTop: -5,
        paddingBottom: 8,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box sx={{ ...centered_flex_box, mt: 2, flexDirection: "column", p: 5 }}>
        <Typography
          fontSize={30}
          fontWeight="bold"
          textAlign="center"
          color={"var(--primaryPurple)"}
        >
          CONTACT US
        </Typography>
        <hr
          style={{
            width: "50%",
            opacity: "100%",
            "border-top": "solid var(--primaryPurple) 3px",
          }}
        />
      </Box>
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{ ...centered_flex_box, flexDirection: "column", minWidth: "50%" }}
      >
        <Box
          sx={{ ...centered_flex_box, flexDirection: "row", minWidth: "100%" }}
        >
          <MainInput
            margin="normal"
            fullWidth
            id="name"
            label="Name"
            name="name"
            sx={{ mr: 1 }}
          />
          <MainInput
            margin="normal"
            required
            fullWidth
            type="email"
            id="email"
            label="Email"
            name="email"
            value={user?.email}
            focused={user?.email}
            sx={{ ml: 1 }}
          />
        </Box>
        <MainInput
          margin="normal"
          required
          multiline
          fullWidth
          minRows={10}
          name="content"
          label="Message"
          id="content"
        />
        <Button
          type="submit"
          variant="contained"
          sx={{ mt: 3, mb: 2, ...main_button, width: "50%" }}
        >
          Submit
        </Button>
      </Box>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  user: state?.auth?.user,
  token: state?.auth?.token,
  isLoading: state?.wait?.isLoading,
});

const mapDispatchToProps = { sendMessage };

export default connect(mapStateToProps, mapDispatchToProps)(Contact);
