import * as React from "react";
import {
  Typography,
  Box,
  Container,
  TextField,
  CssBaseline,
  Button,
  Avatar,
  Select,
  MenuItem,
  InputLabel,
  FormHelperText,
  FormControl,
  CircularProgress,
  Chip,
} from "@mui/material";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import ConstructionIcon from "@mui/icons-material/Construction";
import { centered_flex_box } from "../../app/components/Styles";
import { getTerms } from "../../app/store/actions/commonActions";
import ReactMarkdown from "react-markdown";
export const Terms = ({ getTerms, isLoading, terms }) => {
  const navigate = useNavigate();

  React.useEffect(() => {
    getTerms();
  }, []);

  if (isLoading) {
    return (
      <Box sx={{ ...centered_flex_box }}>
        <CircularProgress sx={{ color: "var(--secColor)" }} />
      </Box>
    );
  }

  return (
    <Container component="main">
      <Box sx={{ ...centered_flex_box, mt: 2, flexDirection: "column", p: 5 }}>
        <Typography fontSize={30} fontWeight="bold" textAlign="center">
          TERMS & CONDITIONS
        </Typography>
        <hr
          style={{
            width: "20%",
            opacity: "100%",
            "border-top": "solid var(--primaryPurple) 3px",
          }}
        />
      </Box>
      <Box sx={{ flexDirection: "column", textAlign: "justify" }}>
        <ReactMarkdown>{terms}</ReactMarkdown>
      </Box>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  terms: state?.records?.results,
  isLoading: state?.records?.isLoading,
});

const mapDispatchToProps = { getTerms };

export default connect(mapStateToProps, mapDispatchToProps)(Terms);
