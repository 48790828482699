import * as React from 'react';
import { Typography, Paper, IconButton, InputBase, Box, Container, Pagination, CircularProgress, Accordion, AccordionSummary, AccordionDetails, Button, Grid, FormControl, InputLabel, OutlinedInput, InputAdornment, Card, CardActionArea, CardHeader, CardContent, Avatar } from '@mui/material';
import { connect } from "react-redux";
import InboxIcon from '@mui/icons-material/Inbox';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import SearchIcon from '@mui/icons-material/Search';
import { centered_flex_box, MainInput, main_button, sec_button, left_flex_box } from '../../app/components/Styles';
import moment from 'moment'
import { EventCard } from "../../app/components"
import { searchEvents } from '../../app/store/actions/eventActions';
import { getPartners } from '../../app/store/actions/adminActions';
import { BrowserView, isMobile, MobileView } from 'react-device-detect';
import { AiFillDelete, AiFillEdit } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import ActionDialog from '../../app/components/ActionDialog';
import PartnerCard from './components/PartnerCard';

export const SearchPartners = ({ partners, getPartners }) => {

    const navigate = useNavigate();

    const initialState = {
        search: "",
        page: 1,
        limit: 10
    }

    React.useEffect(() => {
        getPartners(initialState)
    }, [])

    const [formData, setFormData] = React.useState(initialState)
    const [actionDialog, setActionDialog] = React.useState(false);
    const [action, setAction] = React.useState(false);

    React.useEffect(() => {
        getPartners(formData);
    }, [action]);

    const { search, page } = formData

    const handlePageChange = (event, value) => {
        setFormData({ ...formData, page: value })
        getPartners({ ...formData, page: value })
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value })
    }

    const handleSearchFilter = (event) => {
        getPartners(formData)
    }

    return (
        <Container component="main" maxWidth="xl">
            <BrowserView>
                <Grid container sx={{ justifyContent: "center" }}>
                    <Grid item>
                        <MainInput
                            id="search"
                            name="search"
                            fullWidth
                            value={search}
                            onChange={handleChange}
                            label="Search for partner"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={handleSearchFilter}
                                            edge="end"
                                        >
                                            <SearchIcon sx={{ color: "var(--secPurple)" }} />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                </Grid>
            </BrowserView>
            <hr />
            <Box>
                {!partners?.isLoading ? (
                    <Grid container sx={{ ...centered_flex_box, flexDirection: "row" }} spacing={0}>
                        {partners?.results?.partners?.docs?.map((partner) => {
                            return (
                                <Grid item xs={isMobile ? 12 : 4}>
                                    <PartnerCard action={action} setAction={setAction} partner={partner} navigate={navigate} />
                                </Grid>)
                        })}
                        {(!partners || partners?.results?.partners?.docs?.length === 0) && (
                            <Grid item sx={{ ...centered_flex_box, flexDirection: "column", mt: 2 }}>
                                <InboxIcon fontSize="large" />
                                <Typography fontSize={40}>No results</Typography>
                            </Grid>
                        )}
                    </Grid>
                ) : (
                    <Box sx={centered_flex_box}>
                        <CircularProgress sx={{ color: "var(--secColor)" }} />
                    </Box>
                )}
                <Box sx={{ ...centered_flex_box, mx: 1, p: 1 }}>
                    <Pagination count={partners?.results?.partners?.pages || 1} page={page} onChange={handlePageChange} />
                </Box>
            </Box>
        </Container>
    );
}

const mapStateToProps = (state) => ({
    partners: state?.records
});

const mapDispatchToProps = { getPartners };

export default connect(mapStateToProps, mapDispatchToProps)(SearchPartners);