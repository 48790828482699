import * as React from 'react';
import { Typography, Box, Container, TextField, CssBaseline, Button, Avatar, Select, MenuItem, InputLabel, FormHelperText, FormControl, CircularProgress, Chip, Grid, Card, Stack, Pagination, CardMedia, CardContent } from '@mui/material';
import { connect } from "react-redux";
import { isMobile } from 'react-device-detect';
import { centered_flex_box } from '../../app/components/Styles';
import { useLocation, useParams } from 'react-router-dom';
import { checkTransaction } from '../../app/store/actions/userActions';
import { CreditCardOff, CreditScore, ErrorOutline } from '@mui/icons-material';

export const CheckTransaction = ({ checkTransaction, isSuccessful, isLoading }) => {

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const params = {};
    for (const [key, value] of searchParams.entries()) {
        params[key] = value;
    }

    React.useEffect(() => {
        checkTransaction({ transaction_id: params.id })
    }, [])

    if (isLoading) {
        return (
            <Box sx={{ ...centered_flex_box, height: "100vh" }}>
                <CircularProgress sx={{ color: "var(--secColor)" }} />
            </Box>
        )
    }

    return (
        <Box sx={{ paddingX: isMobile ? 2 : 10 }}>
            {isSuccessful ? <>
                <Box sx={{ ...centered_flex_box, flexDirection: "column", mt: 2 }}>
                    <CreditScore fontSize="large" />
                    <Typography textAlign="center" fontSize={40}>Transaction Successful</Typography>
                </Box>
            </> : <>
            <Box sx={{ ...centered_flex_box, flexDirection: "column", mt: 2 }}>
                    <CreditCardOff fontSize="large" />
                    <Typography textAlign="center" fontSize={40}>Transaction Failed</Typography>
                </Box>
            </>}
        </Box>
    );
}

const mapStateToProps = (state) => ({
    isSuccessful: state?.records?.results?.success,
    isLoading: state?.records?.isLoading,
    user: state?.auth?.user
});

const mapDispatchToProps = { checkTransaction };

export default connect(mapStateToProps, mapDispatchToProps)(CheckTransaction);