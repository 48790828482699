import { GRAB_TICKET, GRAB_TICKET_SUCCESS, GRAB_TICKET_FAIL } from "../actions/types";

const initialState = {
    ticket: null,
    verify: null,
    isLoading: false,
    isError: false,
};

export default function store(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GRAB_TICKET:
            return {
                ...state,
                isLoading: true,
            };
        case GRAB_TICKET_SUCCESS:
            return {
                ...state,
                ticket: payload,
                verify: action.needsVerification,
                isLoading: false,
                isError: false,
            };
        case GRAB_TICKET_FAIL:
            return {
                ...state,
                ticket: null,
                verify: null,
                isLoading: false,
                isError: true,
            };
        default:
            return state;
    }
}